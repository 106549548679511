.dropdown.dropdown-1 {
	display: inline;

	>.dropdown-toggle {
		color: $gsk-teal;
		font-family: arial, sans-serif; font-weight: 700;
		text-decoration: underline;
		background: none;
		border: none;
		box-shadow: none;
		padding: 0;
		&::after {
			display: inline-block;
			margin-left: 0.25em;
			vertical-align: 0;
			content: url("/assets/img/icons/chevron_down.svg");
			border: 0;
		}

		&[aria-expanded="true"]::after {
			content: url("/assets/img/icons/chevron_up.svg");
		}
	}

	.dropdown-menu.dropdown-primary {
		min-width: 15rem;
		background-color: $gsk-primary;
		padding: 0;

		a.dropdown-item {
			color: $white;
			padding: 0.125rem 0.5rem;
			font-size: 19px;

			&.active,
			&:focus,
			&:hover {
				background-color: rgba(255,255,255,0.2);
			}
		}
	}

	// .dropdown-submenu {
	// 	position: relative;
	//
	// 	.dropdown-menu {
	// 		top: 0;
	// 		left: -15rem;
	// 		margin-top: -6px;
	// 	}
	// }
}

.dropdown-2-wrapper {

	p {
		font-size: 20px;
	}


.dropdown.dropdown-2 {
	display: inline-block;
	width: 100%;

	>.dropdown-toggle {
		color: $gsk-primary;
		text-decoration: none;
		background: none;
		border: none;
		box-shadow: none;
		font-size: 20px;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		text-align: left;
		@include media-breakpoint-up(sm) {
			margin-top: 2px;
			width: auto;
		}

		&::after {
			content: url("/assets/img/icons/chevron_down.svg");
			border: 0;
			vertical-align: 0;
			position: absolute;
			right: 0;
		@include media-breakpoint-up(sm) {
			position: relative;
			display: inline-block;
			margin-left: 0.25em;
		}
	}

	&[aria-expanded="true"]::after {
		content: url("/assets/img/icons/chevron_up.svg");
	}


	}

	.dropdown-submenu .dropdown-toggle {
		width: 40px;

		&::after {
			content: url("/assets/img/icons/chevron_down_white.svg");
			border: 0;
			vertical-align: 0;
			position: absolute;
			right: 0;
		@include media-breakpoint-up(sm) {
			position: relative;
			display: inline-block;
		}
	}

	&.active::after {
		content: url("/assets/img/icons/chevron_up_white.svg");
	}

	}

	.dropdown-menu.dropdown-primary {
		min-width: 15rem;
		background-color: $gsk-primary;
		padding: 0;

		h6.dropdown-header {
			color: $white;
			padding: 0.125rem 0.5rem;
			margin: 0;
			font-size: 20px;
			background-color: rgba(255,255,255,0.2);
		}

		a.dropdown-item {
			color: $white;
			padding: 0.125rem 0.5rem;
			font-size: 20px;

			&.active,
			&:focus,
			&:hover {
				background-color: rgba(255,255,255,0.2);
			}
		}
	}

	.dropdown-submenu {
		position: relative;

		.dropdown-menu {
			// top: 0;
			// left: -15rem;
			top: 36px;
			left: 0.5rem;
			// margin-top: -6px;
		}
	}
}

}
