a.link {
	text-decoration: underline;
	&:hover {
		text-decoration: underline;
		color: $gsk-primary;
	}

	&.external-link::after {
		  content: "";
			position: relative;
			margin-left: 0.5rem;
			width: 1rem;
			height: 1rem;
			background-image: url('/assets/img/icons/icon_new_window.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			display: inline-block;
	}
}

.btn {
	min-height: 42px;
	border-radius: 25px;
	border-width: 2px;
	width: 215px;
	display: inline-flex;
  justify-content: center;
  align-items: center;
	@include media-breakpoint-up(md) {
		width: 295px;
	}
	@include media-breakpoint-up(lg) {
		width: 232px;
	}

	&.btn-width-auto {
		width: 100%;
	}
}

.input-group .btn {
	width: auto;
}

.btn.link {
	background: none;
	color: $gsk-teal;
	// min-height: 42px;
	// border-radius: 25px;
	// border-width: 2px;
	// width: 215px;
	// display: inline-flex;
  // justify-content: center;
  // align-items: center;
	// @include media-breakpoint-up(md) {
	// 	width: 295px;
	// }
	// @include media-breakpoint-up(lg) {
	// 	width: 232px;
	// }
}

.btn-sm {
	min-height: 33.6px;
	border-radius: 24px;
	border-width: 1px;
	width: 120px;
	font-size: 10px;
	text-transform: uppercase;
}

.small-link {
	font-family: arial, sans-serif; font-weight: 700;
	font-size: 16px;
	line-height: 125%;
}


.btn-group.toggle-charts {
	background-color: $surface-medium;
	border-radius: 24px;
  .btn-sm {
		border-radius: 24px;
		&.active {
			background-color: $gsk-teal;
			color: $white;
			border-radius: 24px;
			box-shadow: none;
		}
		&:focus {
			box-shadow: none;
		}
	}
}


.btn span.download-icon {
	display: inline-block;
	background-image: url('/assets/img/icons/icon_download_button.svg');
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 1rem;
	margin-left: 1rem;
}

.btn:hover span.download-icon {
	background-image: url('/assets/img/icons/icon_download_button_white.svg');
}
