h1, .h1 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 32px;
	line-height: 130%;
	margin: 0 0 40px 0;
	@include media-breakpoint-up(md) {
		font-size: 40px;
		line-height: 100%;
		margin: 0 0 48px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 42px;
		line-height: 100%;
		margin: 0 0 48px 0;
	}
}

h2, .h2 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 26px;
	line-height: 135%;
	margin: 24px 0 24px 0;
	@include media-breakpoint-up(md) {
		font-size: 32px;
		line-height: 110%;
		margin: 24px 0 32px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 36px;
		line-height: 110%;
		margin: 24px 0 40px 0;
	}
}

h3, .h3 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 22px;
	line-height: 130%;
	margin: 24px 0 32px 0;
	@include media-breakpoint-up(md) {
		font-size: 26px;
		line-height: 130%;
		margin: 24px 0 32px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 28px;
		line-height: 130%;
		margin: 26px 0 36px 0;
	}
}

h4, .h4 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 19px;
	line-height: 100%;
	margin: 16px 0 20px 0;
	@include media-breakpoint-up(md) {
		font-size: 22px;
		line-height: 130%;
		margin: 16px 0 20px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 22px;
		line-height: 130%;
		margin: 18px 0 24px 0;
	}
}

h5, .h5 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 13px;
	line-height: 100%;
	margin: 12px 0 16px 0;
	@include media-breakpoint-up(md) {
		font-size: 22px;
		line-height: 140%;
		margin: 12px 0 16px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 20px;
		line-height: 140%;
		margin: 14px 0 18px 0;
	}
}

h6, .h6 {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	margin: 12px 0 16px 0;
	@include media-breakpoint-up(md) {
		font-size: 16px;
		line-height: 138%;
		margin: 12px 0 16px 0;
	}
	@include media-breakpoint-up(lg) {
		font-size: 16px;
		line-height: 140%;
		margin: 14px 0 18px 0;
	}
}

p {
	font-family: arial, sans-serif;
	font-size: 16px;
	line-height: 140%;
	margin: 20px 0 24px 0;
	// @include media-breakpoint-up(md) {
	// 	font-size: 17px;
	// 	line-height: 140%;
	// 	margin: 20px 0 24px 0;
	// }
	// @include media-breakpoint-up(lg) {
	// 	font-size: 18px;
	// 	line-height: 140%;
	// 	margin: 21px 0 28px 0;
	// }

	&.intro {
		font-size: 24px;
		line-height: 120%;
		margin: 24px 0 32px 0;
		@include media-breakpoint-up(md) {
			font-size: 27px;
			line-height: 118%;
			margin: 24px 0 32px 0;
		}
		@include media-breakpoint-up(lg) {
			font-size: 28px;
			line-height: 118%;
			margin: 28px 0 36px 0;
		}
	}

	&.medium {
		font-size: 18px;
		line-height: 140%;
		margin: 16px 0 20px 0;
		@include media-breakpoint-up(md) {
			font-size: 20px;
			line-height: 140%;
			margin: 16px 0 21px 0;
		}
		@include media-breakpoint-up(lg) {
			font-size: 20px;
			line-height: 140%;
			margin: 18px 0 24px 0;
		}
	}

	&.small {
		font-size: 13px;
		line-height: 170%;
		margin: 12px 0 16px 0;
		@include media-breakpoint-up(md) {
			font-size: 13px;
			line-height: 170%;
			margin: 12px 0 16px 0;
		}
		@include media-breakpoint-up(lg) {
			font-size: 13px;
			line-height: 170%;
			margin: 12px 0 16px 0;
		}
	}

	&.x-small {
		font-size: 11px;
	}
}


.weight-medium {
		font-weight: 700;
}


div > p:first-child {
	margin-top: 0;
}


li {
	font-family: arial, sans-serif;
	font-size: 16px;
	line-height: 140%;
	// @include media-breakpoint-up(md) {
	// 	font-size: 17px;
	// 	line-height: 140%;
	// }
	// @include media-breakpoint-up(lg) {
	// 	font-size: 18px;
	// 	line-height: 140%;
	// }

	&.intro {
		font-size: 24px;
		line-height: 120%;
		@include media-breakpoint-up(md) {
			font-size: 27px;
			line-height: 118%;
		}
		@include media-breakpoint-up(lg) {
			font-size: 28px;
			line-height: 118%;
		}
	}

	&.medium {
		font-size: 18px;
		line-height: 140%;
		@include media-breakpoint-up(md) {
			font-size: 20px;
			line-height: 140%;
		}
		@include media-breakpoint-up(lg) {
			font-size: 20px;
			line-height: 140%;
		}
	}

	&.small {
		font-size: 13px;
		line-height: 170%;
		margin: 12px 0 16px 0;
		@include media-breakpoint-up(md) {
			font-size: 13px;
			line-height: 170%;
		}
		@include media-breakpoint-up(lg) {
			font-size: 13px;
			line-height: 170%;
		}
	}


}



// .small-link {
// 	font-family: arial, sans-serif; font-weight: 700;
// 	font-size: 16px;
// 	line-height: 125%;
// }

.top-header {
	font-family: arial, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 125%;
	color: $gsk-teal;
	&::after {
		content: "";
		width: 80px;
		height: 2px;
		display: block;
		background-color: $gsk-primary;
		margin-top: 16px;
	}
	@include media-breakpoint-up(lg) {
		font-size: 18px;
		line-height: 20px;
		&::after {
			width: 86px;
			margin-top: 21px;
		}
	}
}

.top-header + h1 {
	margin-top: 48px;
}

.title-header {
	font-family: arial, sans-serif; font-weight: 700;
	font-size: 13px;
	line-height: 123%;
	color: $gsk-teal;
	&::after {
		content: "";
		width: 80px;
		height: 4px;
		display: block;
		background-color: $gsk-primary;
		margin-top: 12px;
	}
	@include media-breakpoint-up(md) {

		&::after {
			width: 96px;
			margin-top: 12px;
		}
	}
	@include media-breakpoint-up(lg) {

		&::after {
			width: 128px;
			margin-top: 12px;
		}
	}
}

.title-header + h2 {
	margin-top: 20px;
		@include media-breakpoint-up(md) {
			margin-top: 24px;
			max-width: 80%;
		}
}


.top-nav {
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 140%;
	@include media-breakpoint-up(md) {
		font-size: 13px;
		// font-size: 12px;
		line-height: 100%;
	}
}

.medium-bold {
	font-size: 20px;
}


.line-height-100 {
	line-height: 100% !important;
}

.after-line {
	&::after {
		content: "";
		width: 80px;
		height: 4px;
		display: block;
		background-color: $gsk-primary;
		margin-top: 12px;
	}
}

.before-line {
	&::before {
		content: "";
		width: 80px;
		height: 2px;
		display: block;
		background-color: $gsk-primary;
		margin-bottom: 12px;
	}
}
