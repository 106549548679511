/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/* line 1, ../node_modules/bootstrap/scss/_root.scss */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #F36633;
  --secondary: #544F40;
  --success: #008A00;
  --info: #15717D;
  --warning: #E49B13;
  --danger: #ED003C;
  --light: #f8f9fa;
  --dark: #343a40;
  --gsk-primary: #F36633;
  --gsk-dark-grey: #544F40;
  --gsk-medium: #D5D1CE;
  --gsk-purple: #BC1077;
  --gsk-red: #ED003C;
  --gsk-indigo-blue: #40488D;
  --gsk-green: #008A00;
  --gsk-teal: #15717D;
  --gsk-yellow: #E49B13;
  --white: #FFFFFF;
  --white-85: rgba(255, 255, 255, 0.85);
  --white-90: rgba(255, 255, 255, 0.9);
  --white-95: rgba(255, 255, 255, 0.95);
  --grey: #BEBEBD;
  --black: #000000;
  --gsk-purple-60: rgba(188, 16, 119, 0.6);
  --gsk-teal-60: rgba(21, 113, 125, 0.6);
  --gsk-teal-30: rgba(21, 113, 125, 0.3);
  --gsk-green-60: #66B966;
  --link-blue: #0D76D1;
  --text-black: #2E2E3C;
  --text-secondary: #777369;
  --text-medium: #878379;
  --text-light: #BBB9B3;
  --surface-dark: #BEBEBD;
  --surface-medium: #EFEFED;
  --surface-light: #FAFAF8;
  --gsk-lama: #E9A93D;
  --gsk-laba: #F4668A;
  --gsk-ics: #0D76D1;
  --gsk-dpi: #C5D34E;
  --gsk-fmi: #60B0C9;
  --gsk-mdi: #747EB6;
  --gsk-lama-laba: #F5DB4A;
  --gsk-ics-laba: #66B966;
  --gsk-isc-lama-laba: #8A347F;
  --aah-red: #DF410F;
  --landing-1: #914718;
  --landing-2: #FF6605;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/* line 19, ../node_modules/bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 25, ../node_modules/bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* line 35, ../node_modules/bootstrap/scss/_reboot.scss */
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 46, ../node_modules/bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

/* line 66, ../node_modules/bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

/* line 76, ../node_modules/bootstrap/scss/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 92, ../node_modules/bootstrap/scss/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 4px; }

/* line 101, ../node_modules/bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 114, ../node_modules/bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

/* line 123, ../node_modules/bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 129, ../node_modules/bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 136, ../node_modules/bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 143, ../node_modules/bootstrap/scss/_reboot.scss */
dt {
  font-weight: 700; }

/* line 147, ../node_modules/bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 152, ../node_modules/bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 156, ../node_modules/bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: bolder; }

/* line 161, ../node_modules/bootstrap/scss/_reboot.scss */
small {
  font-size: 80%; }

/* line 170, ../node_modules/bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 178, ../node_modules/bootstrap/scss/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 179, ../node_modules/bootstrap/scss/_reboot.scss */
sup {
  top: -.5em; }

/* line 186, ../node_modules/bootstrap/scss/_reboot.scss */
a {
  color: #F36633;
  text-decoration: none;
  background-color: transparent; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a:hover {
    color: #cd3f0c;
    text-decoration: underline; }

/* line 202, ../node_modules/bootstrap/scss/_reboot.scss */
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

/* line 217, ../node_modules/bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

/* line 225, ../node_modules/bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

/* line 242, ../node_modules/bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 252, ../node_modules/bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 257, ../node_modules/bootstrap/scss/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle; }

/* line 269, ../node_modules/bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 273, ../node_modules/bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 285, ../node_modules/bootstrap/scss/_reboot.scss */
th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

/* line 296, ../node_modules/bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem; }

/* line 305, ../node_modules/bootstrap/scss/_reboot.scss */
button {
  border-radius: 0; }

/* line 315, ../node_modules/bootstrap/scss/_reboot.scss */
button:focus:not(:focus-visible) {
  outline: 0; }

/* line 319, ../node_modules/bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 330, ../node_modules/bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 335, ../node_modules/bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 343, ../node_modules/bootstrap/scss/_reboot.scss */
[role="button"] {
  cursor: pointer; }

/* line 350, ../node_modules/bootstrap/scss/_reboot.scss */
select {
  word-wrap: normal; }

/* line 358, ../node_modules/bootstrap/scss/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 371, ../node_modules/bootstrap/scss/_reboot.scss */
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

/* line 378, ../node_modules/bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 386, ../node_modules/bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 393, ../node_modules/bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 399, ../node_modules/bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 414, ../node_modules/bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

/* line 426, ../node_modules/bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 431, ../node_modules/bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 436, ../node_modules/bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 449, ../node_modules/bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 458, ../node_modules/bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 467, ../node_modules/bootstrap/scss/_reboot.scss */
output {
  display: inline-block; }

/* line 471, ../node_modules/bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 476, ../node_modules/bootstrap/scss/_reboot.scss */
template {
  display: none; }

/* line 482, ../node_modules/bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important; }

/* line 7, ../node_modules/bootstrap/scss/_type.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 1.2; }

/* line 16, ../node_modules/bootstrap/scss/_type.scss */
h1, .h1 {
  font-size: 2.5rem; }

/* line 17, ../node_modules/bootstrap/scss/_type.scss */
h2, .h2 {
  font-size: 2rem; }

/* line 18, ../node_modules/bootstrap/scss/_type.scss */
h3, .h3 {
  font-size: 1.75rem; }

/* line 19, ../node_modules/bootstrap/scss/_type.scss */
h4, .h4 {
  font-size: 1.5rem; }

/* line 20, ../node_modules/bootstrap/scss/_type.scss */
h5, .h5 {
  font-size: 1.25rem; }

/* line 21, ../node_modules/bootstrap/scss/_type.scss */
h6, .h6 {
  font-size: 1rem; }

/* line 23, ../node_modules/bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300; }

/* line 29, ../node_modules/bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 34, ../node_modules/bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 39, ../node_modules/bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 44, ../node_modules/bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 55, ../node_modules/bootstrap/scss/_type.scss */
hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* line 67, ../node_modules/bootstrap/scss/_type.scss */
small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

/* line 73, ../node_modules/bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

/* line 84, ../node_modules/bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none; }

/* line 89, ../node_modules/bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none; }

/* line 92, ../node_modules/bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block; }
  /* line 95, ../node_modules/bootstrap/scss/_type.scss */
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

/* line 106, ../node_modules/bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase; }

/* line 112, ../node_modules/bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 8px;
  font-size: 1.25rem; }

/* line 117, ../node_modules/bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  /* line 122, ../node_modules/bootstrap/scss/_type.scss */
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/* line 8, ../node_modules/bootstrap/scss/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto; }

/* line 14, ../node_modules/bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

/* line 29, ../node_modules/bootstrap/scss/_images.scss */
.figure {
  display: inline-block; }

/* line 34, ../node_modules/bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 4px;
  line-height: 1; }

/* line 39, ../node_modules/bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* line 2, ../node_modules/bootstrap/scss/_code.scss */
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  /* line 8, ../node_modules/bootstrap/scss/_code.scss */
  a > code {
    color: inherit; }

/* line 14, ../node_modules/bootstrap/scss/_code.scss */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  /* line 22, ../node_modules/bootstrap/scss/_code.scss */
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

/* line 31, ../node_modules/bootstrap/scss/_code.scss */
pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  /* line 37, ../node_modules/bootstrap/scss/_code.scss */
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

/* line 45, ../node_modules/bootstrap/scss/_code.scss */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/* line 7, ../node_modules/bootstrap/scss/_grid.scss */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  /* line 20, ../node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm {
    max-width: 100%; } }

@media (min-width: 768px) {
  /* line 20, ../node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md {
    max-width: 100%; } }

@media (min-width: 992px) {
  /* line 20, ../node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg {
    max-width: 100%; } }

@media (min-width: 1200px) {
  /* line 20, ../node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1176px; } }

/* line 49, ../node_modules/bootstrap/scss/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px; }

/* line 55, ../node_modules/bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 59, ../node_modules/bootstrap/scss/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px; }

/* line 34, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 48, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

/* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 60, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 62, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%; }

/* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  /* line 34, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 62, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  /* line 34, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 62, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  /* line 34, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 62, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  /* line 34, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 62, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 65, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%; }
  /* line 72, ../node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* line 5, ../node_modules/bootstrap/scss/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 8px;
  color: #212529; }
  /* line 11, ../node_modules/bootstrap/scss/_tables.scss */
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  /* line 18, ../node_modules/bootstrap/scss/_tables.scss */
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  /* line 23, ../node_modules/bootstrap/scss/_tables.scss */
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

/* line 34, ../node_modules/bootstrap/scss/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.3rem; }

/* line 45, ../node_modules/bootstrap/scss/_tables.scss */
.table-bordered {
  border: 1px solid #dee2e6; }
  /* line 48, ../node_modules/bootstrap/scss/_tables.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  /* line 54, ../node_modules/bootstrap/scss/_tables.scss */
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

/* line 62, ../node_modules/bootstrap/scss/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

/* line 75, ../node_modules/bootstrap/scss/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcd4c6; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f9af95; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-primary:hover {
  background-color: #fbc2ae; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fbc2ae; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cfceca; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a6a39c; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-secondary:hover {
  background-color: #c3c2bd; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c3c2bd; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8deb8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ac27a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-success:hover {
  background-color: #a7d6a7; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a7d6a7; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bdd7db; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #85b5bb; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-info:hover {
  background-color: #adcdd2; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #adcdd2; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7e3bd; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f1cb84; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-warning:hover {
  background-color: #f4d9a6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f4d9a6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fab8c8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f67a9a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-danger:hover {
  background-color: #f8a0b6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f8a0b6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-light:hover {
  background-color: #ececf6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-primary,
.table-gsk-primary > th,
.table-gsk-primary > td {
  background-color: #fcd4c6; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-primary th,
.table-gsk-primary td,
.table-gsk-primary thead th,
.table-gsk-primary tbody + tbody {
  border-color: #f9af95; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-primary:hover {
  background-color: #fbc2ae; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-primary:hover > td,
  .table-hover .table-gsk-primary:hover > th {
    background-color: #fbc2ae; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-dark-grey,
.table-gsk-dark-grey > th,
.table-gsk-dark-grey > td {
  background-color: #cfceca; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-dark-grey th,
.table-gsk-dark-grey td,
.table-gsk-dark-grey thead th,
.table-gsk-dark-grey tbody + tbody {
  border-color: #a6a39c; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-dark-grey:hover {
  background-color: #c3c2bd; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-dark-grey:hover > td,
  .table-hover .table-gsk-dark-grey:hover > th {
    background-color: #c3c2bd; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-medium,
.table-gsk-medium > th,
.table-gsk-medium > td {
  background-color: #f3f2f1; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-medium th,
.table-gsk-medium td,
.table-gsk-medium thead th,
.table-gsk-medium tbody + tbody {
  border-color: #e9e7e6; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-medium:hover {
  background-color: #e7e5e3; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-medium:hover > td,
  .table-hover .table-gsk-medium:hover > th {
    background-color: #e7e5e3; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-purple,
.table-gsk-purple > th,
.table-gsk-purple > td {
  background-color: #ecbcd9; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-purple th,
.table-gsk-purple td,
.table-gsk-purple thead th,
.table-gsk-purple tbody + tbody {
  border-color: #dc83b8; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-purple:hover {
  background-color: #e6a8ce; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-purple:hover > td,
  .table-hover .table-gsk-purple:hover > th {
    background-color: #e6a8ce; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-red,
.table-gsk-red > th,
.table-gsk-red > td {
  background-color: #fab8c8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-red th,
.table-gsk-red td,
.table-gsk-red thead th,
.table-gsk-red tbody + tbody {
  border-color: #f67a9a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-red:hover {
  background-color: #f8a0b6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-red:hover > td,
  .table-hover .table-gsk-red:hover > th {
    background-color: #f8a0b6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-indigo-blue,
.table-gsk-indigo-blue > th,
.table-gsk-indigo-blue > td {
  background-color: #caccdf; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-indigo-blue th,
.table-gsk-indigo-blue td,
.table-gsk-indigo-blue thead th,
.table-gsk-indigo-blue tbody + tbody {
  border-color: #9ca0c4; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-indigo-blue:hover {
  background-color: #babdd5; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-indigo-blue:hover > td,
  .table-hover .table-gsk-indigo-blue:hover > th {
    background-color: #babdd5; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-green,
.table-gsk-green > th,
.table-gsk-green > td {
  background-color: #b8deb8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-green th,
.table-gsk-green td,
.table-gsk-green thead th,
.table-gsk-green tbody + tbody {
  border-color: #7ac27a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-green:hover {
  background-color: #a7d6a7; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-green:hover > td,
  .table-hover .table-gsk-green:hover > th {
    background-color: #a7d6a7; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal,
.table-gsk-teal > th,
.table-gsk-teal > td {
  background-color: #bdd7db; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal th,
.table-gsk-teal td,
.table-gsk-teal thead th,
.table-gsk-teal tbody + tbody {
  border-color: #85b5bb; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-teal:hover {
  background-color: #adcdd2; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-teal:hover > td,
  .table-hover .table-gsk-teal:hover > th {
    background-color: #adcdd2; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-yellow,
.table-gsk-yellow > th,
.table-gsk-yellow > td {
  background-color: #f7e3bd; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-yellow th,
.table-gsk-yellow td,
.table-gsk-yellow thead th,
.table-gsk-yellow tbody + tbody {
  border-color: #f1cb84; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-yellow:hover {
  background-color: #f4d9a6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-yellow:hover > td,
  .table-hover .table-gsk-yellow:hover > th {
    background-color: #f4d9a6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-85,
.table-white-85 > th,
.table-white-85 > td {
  background-color: rgba(255, 255, 255, 0.958); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-85 th,
.table-white-85 td,
.table-white-85 thead th,
.table-white-85 tbody + tbody {
  border-color: rgba(255, 255, 255, 0.922); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-white-85:hover {
  background-color: rgba(242, 242, 242, 0.958); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-white-85:hover > td,
  .table-hover .table-white-85:hover > th {
    background-color: rgba(242, 242, 242, 0.958); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-90,
.table-white-90 > th,
.table-white-90 > td {
  background-color: rgba(255, 255, 255, 0.972); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-90 th,
.table-white-90 td,
.table-white-90 thead th,
.table-white-90 tbody + tbody {
  border-color: rgba(255, 255, 255, 0.948); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-white-90:hover {
  background-color: rgba(242, 242, 242, 0.972); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-white-90:hover > td,
  .table-hover .table-white-90:hover > th {
    background-color: rgba(242, 242, 242, 0.972); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-95,
.table-white-95 > th,
.table-white-95 > td {
  background-color: rgba(255, 255, 255, 0.986); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-white-95 th,
.table-white-95 td,
.table-white-95 thead th,
.table-white-95 tbody + tbody {
  border-color: rgba(255, 255, 255, 0.974); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-white-95:hover {
  background-color: rgba(242, 242, 242, 0.986); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-white-95:hover > td,
  .table-hover .table-white-95:hover > th {
    background-color: rgba(242, 242, 242, 0.986); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #ededed; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-grey th,
.table-grey td,
.table-grey thead th,
.table-grey tbody + tbody {
  border-color: #dddddd; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-grey:hover {
  background-color: #e0e0e0; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-grey:hover > td,
  .table-hover .table-grey:hover > th {
    background-color: #e0e0e0; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-black:hover {
  background-color: #ababab; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-purple-60,
.table-gsk-purple-60 > th,
.table-gsk-purple-60 > td {
  background-color: rgba(245, 221, 236, 0.888); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-purple-60 th,
.table-gsk-purple-60 td,
.table-gsk-purple-60 thead th,
.table-gsk-purple-60 tbody + tbody {
  border-color: rgba(234, 179, 212, 0.792); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-purple-60:hover {
  background-color: rgba(239, 201, 225, 0.888); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-purple-60:hover > td,
  .table-hover .table-gsk-purple-60:hover > th {
    background-color: rgba(239, 201, 225, 0.888); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal-60,
.table-gsk-teal-60 > th,
.table-gsk-teal-60 > td {
  background-color: rgba(222, 235, 236, 0.888); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal-60 th,
.table-gsk-teal-60 td,
.table-gsk-teal-60 thead th,
.table-gsk-teal-60 tbody + tbody {
  border-color: rgba(181, 210, 214, 0.792); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-teal-60:hover {
  background-color: rgba(206, 225, 227, 0.888); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-teal-60:hover > td,
  .table-hover .table-gsk-teal-60:hover > th {
    background-color: rgba(206, 225, 227, 0.888); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal-30,
.table-gsk-teal-30 > th,
.table-gsk-teal-30 > td {
  background-color: rgba(240, 246, 247, 0.804); }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-teal-30 th,
.table-gsk-teal-30 td,
.table-gsk-teal-30 thead th,
.table-gsk-teal-30 tbody + tbody {
  border-color: rgba(217, 232, 234, 0.636); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-teal-30:hover {
  background-color: rgba(223, 236, 238, 0.804); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-teal-30:hover > td,
  .table-hover .table-gsk-teal-30:hover > th {
    background-color: rgba(223, 236, 238, 0.804); }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-green-60,
.table-gsk-green-60 > th,
.table-gsk-green-60 > td {
  background-color: #d4ebd4; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-green-60 th,
.table-gsk-green-60 td,
.table-gsk-green-60 thead th,
.table-gsk-green-60 tbody + tbody {
  border-color: #afdbaf; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-green-60:hover {
  background-color: #c3e3c3; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-green-60:hover > td,
  .table-hover .table-gsk-green-60:hover > th {
    background-color: #c3e3c3; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-link-blue,
.table-link-blue > th,
.table-link-blue > td {
  background-color: #bbd9f2; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-link-blue th,
.table-link-blue td,
.table-link-blue thead th,
.table-link-blue tbody + tbody {
  border-color: #81b8e7; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-link-blue:hover {
  background-color: #a6cdee; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-link-blue:hover > td,
  .table-hover .table-link-blue:hover > th {
    background-color: #a6cdee; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-black,
.table-text-black > th,
.table-text-black > td {
  background-color: #c4c4c8; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-black th,
.table-text-black td,
.table-text-black thead th,
.table-text-black tbody + tbody {
  border-color: #92929a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-text-black:hover {
  background-color: #b7b7bc; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-text-black:hover > td,
  .table-hover .table-text-black:hover > th {
    background-color: #b7b7bc; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-secondary,
.table-text-secondary > th,
.table-text-secondary > td {
  background-color: #d9d8d5; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-secondary th,
.table-text-secondary td,
.table-text-secondary thead th,
.table-text-secondary tbody + tbody {
  border-color: #b8b6b1; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-text-secondary:hover {
  background-color: #cdccc8; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-text-secondary:hover > td,
  .table-hover .table-text-secondary:hover > th {
    background-color: #cdccc8; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-medium,
.table-text-medium > th,
.table-text-medium > td {
  background-color: #dddcd9; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-medium th,
.table-text-medium td,
.table-text-medium thead th,
.table-text-medium tbody + tbody {
  border-color: #c1bfb9; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-text-medium:hover {
  background-color: #d1d0cc; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-text-medium:hover > td,
  .table-hover .table-text-medium:hover > th {
    background-color: #d1d0cc; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-light,
.table-text-light > th,
.table-text-light > td {
  background-color: #ecebea; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-text-light th,
.table-text-light td,
.table-text-light thead th,
.table-text-light tbody + tbody {
  border-color: #dcdbd7; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-text-light:hover {
  background-color: #e0dedd; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-text-light:hover > td,
  .table-hover .table-text-light:hover > th {
    background-color: #e0dedd; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-dark,
.table-surface-dark > th,
.table-surface-dark > td {
  background-color: #ededed; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-dark th,
.table-surface-dark td,
.table-surface-dark thead th,
.table-surface-dark tbody + tbody {
  border-color: #dddddd; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-surface-dark:hover {
  background-color: #e0e0e0; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-surface-dark:hover > td,
  .table-hover .table-surface-dark:hover > th {
    background-color: #e0e0e0; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-medium,
.table-surface-medium > th,
.table-surface-medium > td {
  background-color: #fbfbfa; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-medium th,
.table-surface-medium td,
.table-surface-medium thead th,
.table-surface-medium tbody + tbody {
  border-color: #f7f7f6; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-surface-medium:hover {
  background-color: #f0f0ec; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-surface-medium:hover > td,
  .table-hover .table-surface-medium:hover > th {
    background-color: #f0f0ec; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-light,
.table-surface-light > th,
.table-surface-light > td {
  background-color: #fefefd; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-surface-light th,
.table-surface-light td,
.table-surface-light thead th,
.table-surface-light tbody + tbody {
  border-color: #fcfcfb; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-surface-light:hover {
  background-color: #f6f6ec; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-surface-light:hover > td,
  .table-hover .table-surface-light:hover > th {
    background-color: #f6f6ec; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-lama,
.table-gsk-lama > th,
.table-gsk-lama > td {
  background-color: #f9e7c9; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-lama th,
.table-gsk-lama td,
.table-gsk-lama thead th,
.table-gsk-lama tbody + tbody {
  border-color: #f4d29a; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-lama:hover {
  background-color: #f6ddb2; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-lama:hover > td,
  .table-hover .table-gsk-lama:hover > th {
    background-color: #f6ddb2; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-laba,
.table-gsk-laba > th,
.table-gsk-laba > td {
  background-color: #fcd4de; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-laba th,
.table-gsk-laba td,
.table-gsk-laba thead th,
.table-gsk-laba tbody + tbody {
  border-color: #f9afc2; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-laba:hover {
  background-color: #fabccc; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-laba:hover > td,
  .table-hover .table-gsk-laba:hover > th {
    background-color: #fabccc; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-ics,
.table-gsk-ics > th,
.table-gsk-ics > td {
  background-color: #bbd9f2; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-ics th,
.table-gsk-ics td,
.table-gsk-ics thead th,
.table-gsk-ics tbody + tbody {
  border-color: #81b8e7; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-ics:hover {
  background-color: #a6cdee; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-ics:hover > td,
  .table-hover .table-gsk-ics:hover > th {
    background-color: #a6cdee; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-dpi,
.table-gsk-dpi > th,
.table-gsk-dpi > td {
  background-color: #eff3cd; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-dpi th,
.table-gsk-dpi td,
.table-gsk-dpi thead th,
.table-gsk-dpi tbody + tbody {
  border-color: #e1e8a3; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-dpi:hover {
  background-color: #e8eeb8; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-dpi:hover > td,
  .table-hover .table-gsk-dpi:hover > th {
    background-color: #e8eeb8; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-fmi,
.table-gsk-fmi > th,
.table-gsk-fmi > td {
  background-color: #d2e9f0; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-fmi th,
.table-gsk-fmi td,
.table-gsk-fmi thead th,
.table-gsk-fmi tbody + tbody {
  border-color: #acd6e3; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-fmi:hover {
  background-color: #bfe0ea; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-fmi:hover > td,
  .table-hover .table-gsk-fmi:hover > th {
    background-color: #bfe0ea; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-mdi,
.table-gsk-mdi > th,
.table-gsk-mdi > td {
  background-color: #d8dbeb; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-mdi th,
.table-gsk-mdi td,
.table-gsk-mdi thead th,
.table-gsk-mdi tbody + tbody {
  border-color: #b7bcd9; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-mdi:hover {
  background-color: #c7cbe2; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-mdi:hover > td,
  .table-hover .table-gsk-mdi:hover > th {
    background-color: #c7cbe2; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-lama-laba,
.table-gsk-lama-laba > th,
.table-gsk-lama-laba > td {
  background-color: #fcf5cc; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-lama-laba th,
.table-gsk-lama-laba td,
.table-gsk-lama-laba thead th,
.table-gsk-lama-laba tbody + tbody {
  border-color: #faeca1; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-lama-laba:hover {
  background-color: #fbf0b4; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-lama-laba:hover > td,
  .table-hover .table-gsk-lama-laba:hover > th {
    background-color: #fbf0b4; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-ics-laba,
.table-gsk-ics-laba > th,
.table-gsk-ics-laba > td {
  background-color: #d4ebd4; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-ics-laba th,
.table-gsk-ics-laba td,
.table-gsk-ics-laba thead th,
.table-gsk-ics-laba tbody + tbody {
  border-color: #afdbaf; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-ics-laba:hover {
  background-color: #c3e3c3; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-ics-laba:hover > td,
  .table-hover .table-gsk-ics-laba:hover > th {
    background-color: #c3e3c3; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-isc-lama-laba,
.table-gsk-isc-lama-laba > th,
.table-gsk-isc-lama-laba > td {
  background-color: #dec6db; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-gsk-isc-lama-laba th,
.table-gsk-isc-lama-laba td,
.table-gsk-isc-lama-laba thead th,
.table-gsk-isc-lama-laba tbody + tbody {
  border-color: #c295bc; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-gsk-isc-lama-laba:hover {
  background-color: #d5b6d1; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-gsk-isc-lama-laba:hover > td,
  .table-hover .table-gsk-isc-lama-laba:hover > th {
    background-color: #d5b6d1; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-aah-red,
.table-aah-red > th,
.table-aah-red > td {
  background-color: #f6cabc; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-aah-red th,
.table-aah-red td,
.table-aah-red thead th,
.table-aah-red tbody + tbody {
  border-color: #ee9c82; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-aah-red:hover {
  background-color: #f3b8a6; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-aah-red:hover > td,
  .table-hover .table-aah-red:hover > th {
    background-color: #f3b8a6; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-landing-1,
.table-landing-1 > th,
.table-landing-1 > td {
  background-color: #e0cbbe; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-landing-1 th,
.table-landing-1 td,
.table-landing-1 thead th,
.table-landing-1 tbody + tbody {
  border-color: #c69f87; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-landing-1:hover {
  background-color: #d8bdad; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-landing-1:hover > td,
  .table-hover .table-landing-1:hover > th {
    background-color: #d8bdad; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-landing-2,
.table-landing-2 > th,
.table-landing-2 > td {
  background-color: #ffd4b9; }

/* line 14, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-landing-2 th,
.table-landing-2 td,
.table-landing-2 thead th,
.table-landing-2 tbody + tbody {
  border-color: #ffaf7d; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-landing-2:hover {
  background-color: #ffc4a0; }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-landing-2:hover > td,
  .table-hover .table-landing-2:hover > th {
    background-color: #ffc4a0; }

/* line 7, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

/* line 114, ../node_modules/bootstrap/scss/_tables.scss */
.table .thead-dark th {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #454d55; }

/* line 122, ../node_modules/bootstrap/scss/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

/* line 130, ../node_modules/bootstrap/scss/_tables.scss */
.table-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  /* line 134, ../node_modules/bootstrap/scss/_tables.scss */
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  /* line 140, ../node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-bordered {
    border: 0; }
  /* line 145, ../node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  /* line 171, ../node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, ../node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  /* line 171, ../node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, ../node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  /* line 171, ../node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, ../node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  /* line 171, ../node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, ../node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-xl > .table-bordered {
      border: 0; } }

/* line 171, ../node_modules/bootstrap/scss/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  /* line 179, ../node_modules/bootstrap/scss/_tables.scss */
  .table-responsive > .table-bordered {
    border: 0; }

/* line 7, ../node_modules/bootstrap/scss/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, ../node_modules/bootstrap/scss/_forms.scss */
    .form-control {
      transition: none; } }
  /* line 28, ../node_modules/bootstrap/scss/_forms.scss */
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #fac0ab;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
  /* line 37, ../node_modules/bootstrap/scss/_forms.scss */
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  /* line 48, ../node_modules/bootstrap/scss/_forms.scss */
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

/* line 60, ../node_modules/bootstrap/scss/_forms.scss */
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

/* line 67, ../node_modules/bootstrap/scss/_forms.scss */
select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

/* line 72, ../node_modules/bootstrap/scss/_forms.scss */
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

/* line 84, ../node_modules/bootstrap/scss/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* line 97, ../node_modules/bootstrap/scss/_forms.scss */
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

/* line 105, ../node_modules/bootstrap/scss/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 112, ../node_modules/bootstrap/scss/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 125, ../node_modules/bootstrap/scss/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  /* line 137, ../node_modules/bootstrap/scss/_forms.scss */
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

/* line 152, ../node_modules/bootstrap/scss/_forms.scss */
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 160, ../node_modules/bootstrap/scss/_forms.scss */
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 170, ../node_modules/bootstrap/scss/_forms.scss */
select.form-control[size], select.form-control[multiple] {
  height: auto; }

/* line 176, ../node_modules/bootstrap/scss/_forms.scss */
textarea.form-control {
  height: auto; }

/* line 185, ../node_modules/bootstrap/scss/_forms.scss */
.form-group {
  margin-bottom: 1rem; }

/* line 189, ../node_modules/bootstrap/scss/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem; }

/* line 199, ../node_modules/bootstrap/scss/_forms.scss */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  /* line 205, ../node_modules/bootstrap/scss/_forms.scss */
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

/* line 217, ../node_modules/bootstrap/scss/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

/* line 223, ../node_modules/bootstrap/scss/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  /* line 229, ../node_modules/bootstrap/scss/_forms.scss */
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

/* line 235, ../node_modules/bootstrap/scss/_forms.scss */
.form-check-label {
  margin-bottom: 0; }

/* line 239, ../node_modules/bootstrap/scss/_forms.scss */
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  /* line 246, ../node_modules/bootstrap/scss/_forms.scss */
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

/* line 45, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #008A00; }

/* line 53, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(0, 138, 0, 0.9);
  border-radius: 0.25rem; }
  /* line 70, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

/* line 77, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #008A00;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008A00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 95, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #008A00;
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.25); }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #008A00;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008A00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  /* line 131, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #008A00;
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.25); }

/* line 140, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #008A00; }

/* line 144, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 153, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #008A00; }
  /* line 156, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #008A00; }

/* line 162, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00bd00;
  background-color: #00bd00; }

/* line 169, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.25); }

/* line 173, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #008A00; }

/* line 183, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008A00; }

/* line 188, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #008A00;
  box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.25); }

/* line 45, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ED003C; }

/* line 53, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(237, 0, 60, 0.9);
  border-radius: 0.25rem; }
  /* line 70, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

/* line 77, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ED003C;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ED003C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ED003C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 95, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ED003C;
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.25); }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

/* line 33, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ED003C;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ED003C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ED003C' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  /* line 131, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ED003C;
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.25); }

/* line 140, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ED003C; }

/* line 144, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 153, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ED003C; }
  /* line 156, ../node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ED003C; }

/* line 162, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff2159;
  background-color: #ff2159; }

/* line 169, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.25); }

/* line 173, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ED003C; }

/* line 183, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ED003C; }

/* line 188, ../node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ED003C;
  box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.25); }

/* line 275, ../node_modules/bootstrap/scss/_forms.scss */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  /* line 283, ../node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    /* line 289, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    /* line 297, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    /* line 306, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    /* line 313, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control-plaintext {
      display: inline-block; }
    /* line 317, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    /* line 324, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    /* line 331, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    /* line 339, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    /* line 343, ../node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* line 7, ../node_modules/bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, ../node_modules/bootstrap/scss/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  /* line 27, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
  /* line 34, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  /* line 40, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 55, ../node_modules/bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #FFFFFF;
  background-color: #F36633;
  border-color: #F36633; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #f14b0f;
    border-color: #e6470e; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #FFFFFF;
    background-color: #f14b0f;
    border-color: #e6470e;
    box-shadow: 0 0 0 0.2rem rgba(245, 125, 82, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #e6470e;
    border-color: #d9430d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 125, 82, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #FFFFFF;
  background-color: #544F40;
  border-color: #544F40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #3e3b2f;
    border-color: #37342a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    color: #FFFFFF;
    background-color: #3e3b2f;
    border-color: #37342a;
    box-shadow: 0 0 0 0.2rem rgba(110, 105, 93, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #37342a;
    border-color: #302d24; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(110, 105, 93, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-success {
  color: #FFFFFF;
  background-color: #008A00;
  border-color: #008A00; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-success:hover {
    color: #FFFFFF;
    background-color: darkgreen;
    border-color: #005700; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    color: #FFFFFF;
    background-color: darkgreen;
    border-color: #005700;
    box-shadow: 0 0 0 0.2rem rgba(38, 156, 38, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #005700;
    border-color: #004a00; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 156, 38, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-info {
  color: #FFFFFF;
  background-color: #15717D;
  border-color: #15717D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #0f535c;
    border-color: #0e4a51; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    color: #FFFFFF;
    background-color: #0f535c;
    border-color: #0e4a51;
    box-shadow: 0 0 0 0.2rem rgba(56, 134, 145, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0e4a51;
    border-color: #0c4046; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 134, 145, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #E49B13;
  border-color: #E49B13; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-warning:hover {
    color: #FFFFFF;
    background-color: #c18310;
    border-color: #b57b0f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    color: #FFFFFF;
    background-color: #c18310;
    border-color: #b57b0f;
    box-shadow: 0 0 0 0.2rem rgba(199, 137, 22, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #b57b0f;
    border-color: #a9730e; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 137, 22, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #FFFFFF;
  background-color: #ED003C;
  border-color: #ED003C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #c70032;
    border-color: #ba002f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    color: #FFFFFF;
    background-color: #c70032;
    border-color: #ba002f;
    box-shadow: 0 0 0 0.2rem rgba(240, 38, 89, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ba002f;
    border-color: #ad002c; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 38, 89, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #343a40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #23272b;
    border-color: #1d2124; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:focus, .btn-dark.focus {
    color: #FFFFFF;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1d2124;
    border-color: #171a1d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-primary {
  color: #FFFFFF;
  background-color: #F36633;
  border-color: #F36633; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-primary:hover {
    color: #FFFFFF;
    background-color: #f14b0f;
    border-color: #e6470e; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-primary:focus, .btn-gsk-primary.focus {
    color: #FFFFFF;
    background-color: #f14b0f;
    border-color: #e6470e;
    box-shadow: 0 0 0 0.2rem rgba(245, 125, 82, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-primary.disabled, .btn-gsk-primary:disabled {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-primary:not(:disabled):not(.disabled):active, .btn-gsk-primary:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #e6470e;
    border-color: #d9430d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-primary:not(:disabled):not(.disabled):active:focus, .btn-gsk-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 125, 82, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-dark-grey {
  color: #FFFFFF;
  background-color: #544F40;
  border-color: #544F40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-dark-grey:hover {
    color: #FFFFFF;
    background-color: #3e3b2f;
    border-color: #37342a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dark-grey:focus, .btn-gsk-dark-grey.focus {
    color: #FFFFFF;
    background-color: #3e3b2f;
    border-color: #37342a;
    box-shadow: 0 0 0 0.2rem rgba(110, 105, 93, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dark-grey.disabled, .btn-gsk-dark-grey:disabled {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dark-grey:not(:disabled):not(.disabled):active, .btn-gsk-dark-grey:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-dark-grey.dropdown-toggle {
    color: #FFFFFF;
    background-color: #37342a;
    border-color: #302d24; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-dark-grey:not(:disabled):not(.disabled):active:focus, .btn-gsk-dark-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-dark-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(110, 105, 93, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-medium {
  color: #212529;
  background-color: #D5D1CE;
  border-color: #D5D1CE; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-medium:hover {
    color: #212529;
    background-color: #c3beb9;
    border-color: #bdb7b3; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-medium:focus, .btn-gsk-medium.focus {
    color: #212529;
    background-color: #c3beb9;
    border-color: #bdb7b3;
    box-shadow: 0 0 0 0.2rem rgba(186, 183, 181, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-medium.disabled, .btn-gsk-medium:disabled {
    color: #212529;
    background-color: #D5D1CE;
    border-color: #D5D1CE; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-medium:not(:disabled):not(.disabled):active, .btn-gsk-medium:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-medium.dropdown-toggle {
    color: #212529;
    background-color: #bdb7b3;
    border-color: #b8b1ac; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-medium:not(:disabled):not(.disabled):active:focus, .btn-gsk-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 183, 181, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-purple {
  color: #FFFFFF;
  background-color: #BC1077;
  border-color: #BC1077; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-purple:hover {
    color: #FFFFFF;
    background-color: #990d61;
    border-color: #8d0c59; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple:focus, .btn-gsk-purple.focus {
    color: #FFFFFF;
    background-color: #990d61;
    border-color: #8d0c59;
    box-shadow: 0 0 0 0.2rem rgba(198, 52, 139, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple.disabled, .btn-gsk-purple:disabled {
    color: #FFFFFF;
    background-color: #BC1077;
    border-color: #BC1077; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple:not(:disabled):not(.disabled):active, .btn-gsk-purple:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-purple.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8d0c59;
    border-color: #810b52; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-purple:not(:disabled):not(.disabled):active:focus, .btn-gsk-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(198, 52, 139, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-red {
  color: #FFFFFF;
  background-color: #ED003C;
  border-color: #ED003C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-red:hover {
    color: #FFFFFF;
    background-color: #c70032;
    border-color: #ba002f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-red:focus, .btn-gsk-red.focus {
    color: #FFFFFF;
    background-color: #c70032;
    border-color: #ba002f;
    box-shadow: 0 0 0 0.2rem rgba(240, 38, 89, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-red.disabled, .btn-gsk-red:disabled {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-red:not(:disabled):not(.disabled):active, .btn-gsk-red:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ba002f;
    border-color: #ad002c; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-red:not(:disabled):not(.disabled):active:focus, .btn-gsk-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 38, 89, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-indigo-blue {
  color: #FFFFFF;
  background-color: #40488D;
  border-color: #40488D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-indigo-blue:hover {
    color: #FFFFFF;
    background-color: #343b73;
    border-color: #30366a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-indigo-blue:focus, .btn-gsk-indigo-blue.focus {
    color: #FFFFFF;
    background-color: #343b73;
    border-color: #30366a;
    box-shadow: 0 0 0 0.2rem rgba(93, 99, 158, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-indigo-blue.disabled, .btn-gsk-indigo-blue:disabled {
    color: #FFFFFF;
    background-color: #40488D;
    border-color: #40488D; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-indigo-blue:not(:disabled):not(.disabled):active, .btn-gsk-indigo-blue:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-indigo-blue.dropdown-toggle {
    color: #FFFFFF;
    background-color: #30366a;
    border-color: #2c3261; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-indigo-blue:not(:disabled):not(.disabled):active:focus, .btn-gsk-indigo-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-indigo-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(93, 99, 158, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-green {
  color: #FFFFFF;
  background-color: #008A00;
  border-color: #008A00; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-green:hover {
    color: #FFFFFF;
    background-color: darkgreen;
    border-color: #005700; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green:focus, .btn-gsk-green.focus {
    color: #FFFFFF;
    background-color: darkgreen;
    border-color: #005700;
    box-shadow: 0 0 0 0.2rem rgba(38, 156, 38, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green.disabled, .btn-gsk-green:disabled {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green:not(:disabled):not(.disabled):active, .btn-gsk-green:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #005700;
    border-color: #004a00; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-green:not(:disabled):not(.disabled):active:focus, .btn-gsk-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 156, 38, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-teal {
  color: #FFFFFF;
  background-color: #15717D;
  border-color: #15717D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-teal:hover {
    color: #FFFFFF;
    background-color: #0f535c;
    border-color: #0e4a51; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal:focus, .btn-gsk-teal.focus {
    color: #FFFFFF;
    background-color: #0f535c;
    border-color: #0e4a51;
    box-shadow: 0 0 0 0.2rem rgba(56, 134, 145, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal.disabled, .btn-gsk-teal:disabled {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal:not(:disabled):not(.disabled):active, .btn-gsk-teal:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-teal.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0e4a51;
    border-color: #0c4046; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-teal:not(:disabled):not(.disabled):active:focus, .btn-gsk-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 134, 145, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-yellow {
  color: #212529;
  background-color: #E49B13;
  border-color: #E49B13; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-yellow:hover {
    color: #FFFFFF;
    background-color: #c18310;
    border-color: #b57b0f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-yellow:focus, .btn-gsk-yellow.focus {
    color: #FFFFFF;
    background-color: #c18310;
    border-color: #b57b0f;
    box-shadow: 0 0 0 0.2rem rgba(199, 137, 22, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-yellow.disabled, .btn-gsk-yellow:disabled {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-yellow:not(:disabled):not(.disabled):active, .btn-gsk-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-yellow.dropdown-toggle {
    color: #FFFFFF;
    background-color: #b57b0f;
    border-color: #a9730e; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-yellow:not(:disabled):not(.disabled):active:focus, .btn-gsk-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 137, 22, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-white {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-white-85 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.85); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-white-85:hover {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.85);
    border-color: rgba(230, 230, 230, 0.85); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-85:focus, .btn-white-85.focus {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.85);
    border-color: rgba(230, 230, 230, 0.85);
    box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-85.disabled, .btn-white-85:disabled {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-85:not(:disabled):not(.disabled):active, .btn-white-85:not(:disabled):not(.disabled).active,
  .show > .btn-white-85.dropdown-toggle {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.85);
    border-color: rgba(223, 223, 223, 0.85); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-white-85:not(:disabled):not(.disabled):active:focus, .btn-white-85:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white-85.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-white-90 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-white-90:hover {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.9);
    border-color: rgba(230, 230, 230, 0.9); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-90:focus, .btn-white-90.focus {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.9);
    border-color: rgba(230, 230, 230, 0.9);
    box-shadow: 0 0 0 0.2rem rgba(216, 216, 217, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-90.disabled, .btn-white-90:disabled {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-90:not(:disabled):not(.disabled):active, .btn-white-90:not(:disabled):not(.disabled).active,
  .show > .btn-white-90.dropdown-toggle {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.9);
    border-color: rgba(223, 223, 223, 0.9); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-white-90:not(:disabled):not(.disabled):active:focus, .btn-white-90:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white-90.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 216, 217, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-white-95 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.95); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-white-95:hover {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.95);
    border-color: rgba(230, 230, 230, 0.95); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-95:focus, .btn-white-95.focus {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.95);
    border-color: rgba(230, 230, 230, 0.95);
    box-shadow: 0 0 0 0.2rem rgba(219, 219, 220, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-95.disabled, .btn-white-95:disabled {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(255, 255, 255, 0.95); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-white-95:not(:disabled):not(.disabled):active, .btn-white-95:not(:disabled):not(.disabled).active,
  .show > .btn-white-95.dropdown-toggle {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.95);
    border-color: rgba(223, 223, 223, 0.95); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-white-95:not(:disabled):not(.disabled):active:focus, .btn-white-95:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white-95.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(219, 219, 220, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-grey {
  color: #212529;
  background-color: #BEBEBD;
  border-color: #BEBEBD; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-grey:hover {
    color: #212529;
    background-color: #ababaa;
    border-color: #a5a5a3; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-grey:focus, .btn-grey.focus {
    color: #212529;
    background-color: #ababaa;
    border-color: #a5a5a3;
    box-shadow: 0 0 0 0.2rem rgba(166, 167, 167, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-grey.disabled, .btn-grey:disabled {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
  .show > .btn-grey.dropdown-toggle {
    color: #212529;
    background-color: #a5a5a3;
    border-color: #9e9e9d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(166, 167, 167, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-black {
  color: #FFFFFF;
  background-color: #000000;
  border-color: #000000; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-black:hover {
    color: #FFFFFF;
    background-color: black;
    border-color: black; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-black:focus, .btn-black.focus {
    color: #FFFFFF;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-black.disabled, .btn-black:disabled {
    color: #FFFFFF;
    background-color: #000000;
    border-color: #000000; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: black;
    border-color: black; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-purple-60 {
  color: #FFFFFF;
  background-color: rgba(188, 16, 119, 0.6);
  border-color: rgba(188, 16, 119, 0.6); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-purple-60:hover {
    color: #FFFFFF;
    background-color: rgba(153, 13, 97, 0.6);
    border-color: rgba(141, 12, 89, 0.6); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple-60:focus, .btn-gsk-purple-60.focus {
    color: #FFFFFF;
    background-color: rgba(153, 13, 97, 0.6);
    border-color: rgba(141, 12, 89, 0.6);
    box-shadow: 0 0 0 0.2rem rgba(208, 86, 159, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple-60.disabled, .btn-gsk-purple-60:disabled {
    color: #FFFFFF;
    background-color: rgba(188, 16, 119, 0.6);
    border-color: rgba(188, 16, 119, 0.6); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-purple-60:not(:disabled):not(.disabled):active, .btn-gsk-purple-60:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-purple-60.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(141, 12, 89, 0.6);
    border-color: rgba(129, 11, 82, 0.6); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-purple-60:not(:disabled):not(.disabled):active:focus, .btn-gsk-purple-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-purple-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 86, 159, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-teal-60 {
  color: #FFFFFF;
  background-color: rgba(21, 113, 125, 0.6);
  border-color: rgba(21, 113, 125, 0.6); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-teal-60:hover {
    color: #FFFFFF;
    background-color: rgba(15, 83, 92, 0.6);
    border-color: rgba(14, 74, 81, 0.6); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-60:focus, .btn-gsk-teal-60.focus {
    color: #FFFFFF;
    background-color: rgba(15, 83, 92, 0.6);
    border-color: rgba(14, 74, 81, 0.6);
    box-shadow: 0 0 0 0.2rem rgba(89, 154, 163, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-60.disabled, .btn-gsk-teal-60:disabled {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.6);
    border-color: rgba(21, 113, 125, 0.6); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-60:not(:disabled):not(.disabled):active, .btn-gsk-teal-60:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-teal-60.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(14, 74, 81, 0.6);
    border-color: rgba(12, 64, 70, 0.6); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-teal-60:not(:disabled):not(.disabled):active:focus, .btn-gsk-teal-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-teal-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 154, 163, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-teal-30 {
  color: #FFFFFF;
  background-color: rgba(21, 113, 125, 0.3);
  border-color: rgba(21, 113, 125, 0.3); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-teal-30:hover {
    color: #FFFFFF;
    background-color: rgba(15, 83, 92, 0.3);
    border-color: rgba(14, 74, 81, 0.3); }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-30:focus, .btn-gsk-teal-30.focus {
    color: #FFFFFF;
    background-color: rgba(15, 83, 92, 0.3);
    border-color: rgba(14, 74, 81, 0.3);
    box-shadow: 0 0 0 0.2rem rgba(138, 184, 190, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-30.disabled, .btn-gsk-teal-30:disabled {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.3);
    border-color: rgba(21, 113, 125, 0.3); }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-teal-30:not(:disabled):not(.disabled):active, .btn-gsk-teal-30:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-teal-30.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(14, 74, 81, 0.3);
    border-color: rgba(12, 64, 70, 0.3); }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-teal-30:not(:disabled):not(.disabled):active:focus, .btn-gsk-teal-30:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-teal-30.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 184, 190, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-green-60 {
  color: #212529;
  background-color: #66B966;
  border-color: #66B966; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-green-60:hover {
    color: #FFFFFF;
    background-color: #4eab4e;
    border-color: #4aa24a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green-60:focus, .btn-gsk-green-60.focus {
    color: #FFFFFF;
    background-color: #4eab4e;
    border-color: #4aa24a;
    box-shadow: 0 0 0 0.2rem rgba(92, 163, 93, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green-60.disabled, .btn-gsk-green-60:disabled {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-green-60:not(:disabled):not(.disabled):active, .btn-gsk-green-60:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-green-60.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4aa24a;
    border-color: #469946; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-green-60:not(:disabled):not(.disabled):active:focus, .btn-gsk-green-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-green-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 163, 93, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-link-blue {
  color: #FFFFFF;
  background-color: #0D76D1;
  border-color: #0D76D1; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-link-blue:hover {
    color: #FFFFFF;
    background-color: #0b62ad;
    border-color: #0a5ba1; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-link-blue:focus, .btn-link-blue.focus {
    color: #FFFFFF;
    background-color: #0b62ad;
    border-color: #0a5ba1;
    box-shadow: 0 0 0 0.2rem rgba(49, 139, 216, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-link-blue.disabled, .btn-link-blue:disabled {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-link-blue:not(:disabled):not(.disabled):active, .btn-link-blue:not(:disabled):not(.disabled).active,
  .show > .btn-link-blue.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a5ba1;
    border-color: #095495; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-link-blue:not(:disabled):not(.disabled):active:focus, .btn-link-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-link-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(49, 139, 216, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-text-black {
  color: #FFFFFF;
  background-color: #2E2E3C;
  border-color: #2E2E3C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-text-black:hover {
    color: #FFFFFF;
    background-color: #1d1d26;
    border-color: #18181f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-black:focus, .btn-text-black.focus {
    color: #FFFFFF;
    background-color: #1d1d26;
    border-color: #18181f;
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 89, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-black.disabled, .btn-text-black:disabled {
    color: #FFFFFF;
    background-color: #2E2E3C;
    border-color: #2E2E3C; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-black:not(:disabled):not(.disabled):active, .btn-text-black:not(:disabled):not(.disabled).active,
  .show > .btn-text-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: #18181f;
    border-color: #121218; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-text-black:not(:disabled):not(.disabled):active:focus, .btn-text-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 77, 89, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-text-secondary {
  color: #FFFFFF;
  background-color: #777369;
  border-color: #777369; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-text-secondary:hover {
    color: #FFFFFF;
    background-color: #635f57;
    border-color: #5c5951; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-secondary:focus, .btn-text-secondary.focus {
    color: #FFFFFF;
    background-color: #635f57;
    border-color: #5c5951;
    box-shadow: 0 0 0 0.2rem rgba(139, 136, 128, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-secondary.disabled, .btn-text-secondary:disabled {
    color: #FFFFFF;
    background-color: #777369;
    border-color: #777369; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-secondary:not(:disabled):not(.disabled):active, .btn-text-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-text-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #5c5951;
    border-color: #55524b; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-text-secondary:not(:disabled):not(.disabled):active:focus, .btn-text-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 136, 128, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-text-medium {
  color: #FFFFFF;
  background-color: #878379;
  border-color: #878379; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-text-medium:hover {
    color: #FFFFFF;
    background-color: #736f67;
    border-color: #6c6961; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-medium:focus, .btn-text-medium.focus {
    color: #FFFFFF;
    background-color: #736f67;
    border-color: #6c6961;
    box-shadow: 0 0 0 0.2rem rgba(153, 150, 141, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-medium.disabled, .btn-text-medium:disabled {
    color: #FFFFFF;
    background-color: #878379;
    border-color: #878379; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-medium:not(:disabled):not(.disabled):active, .btn-text-medium:not(:disabled):not(.disabled).active,
  .show > .btn-text-medium.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6c6961;
    border-color: #65625b; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-text-medium:not(:disabled):not(.disabled):active:focus, .btn-text-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 150, 141, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-text-light {
  color: #212529;
  background-color: #BBB9B3;
  border-color: #BBB9B3; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-text-light:hover {
    color: #212529;
    background-color: #a9a69f;
    border-color: #a3a098; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-light:focus, .btn-text-light.focus {
    color: #212529;
    background-color: #a9a69f;
    border-color: #a3a098;
    box-shadow: 0 0 0 0.2rem rgba(164, 163, 158, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-light.disabled, .btn-text-light:disabled {
    color: #212529;
    background-color: #BBB9B3;
    border-color: #BBB9B3; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-text-light:not(:disabled):not(.disabled):active, .btn-text-light:not(:disabled):not(.disabled).active,
  .show > .btn-text-light.dropdown-toggle {
    color: #212529;
    background-color: #a3a098;
    border-color: #9d9a91; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-text-light:not(:disabled):not(.disabled):active:focus, .btn-text-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(164, 163, 158, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-surface-dark {
  color: #212529;
  background-color: #BEBEBD;
  border-color: #BEBEBD; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-surface-dark:hover {
    color: #212529;
    background-color: #ababaa;
    border-color: #a5a5a3; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-dark:focus, .btn-surface-dark.focus {
    color: #212529;
    background-color: #ababaa;
    border-color: #a5a5a3;
    box-shadow: 0 0 0 0.2rem rgba(166, 167, 167, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-dark.disabled, .btn-surface-dark:disabled {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-dark:not(:disabled):not(.disabled):active, .btn-surface-dark:not(:disabled):not(.disabled).active,
  .show > .btn-surface-dark.dropdown-toggle {
    color: #212529;
    background-color: #a5a5a3;
    border-color: #9e9e9d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-surface-dark:not(:disabled):not(.disabled):active:focus, .btn-surface-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-surface-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(166, 167, 167, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-surface-medium {
  color: #212529;
  background-color: #EFEFED;
  border-color: #EFEFED; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-surface-medium:hover {
    color: #212529;
    background-color: #ddddd9;
    border-color: #d7d7d2; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-medium:focus, .btn-surface-medium.focus {
    color: #212529;
    background-color: #ddddd9;
    border-color: #d7d7d2;
    box-shadow: 0 0 0 0.2rem rgba(208, 209, 208, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-medium.disabled, .btn-surface-medium:disabled {
    color: #212529;
    background-color: #EFEFED;
    border-color: #EFEFED; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-medium:not(:disabled):not(.disabled):active, .btn-surface-medium:not(:disabled):not(.disabled).active,
  .show > .btn-surface-medium.dropdown-toggle {
    color: #212529;
    background-color: #d7d7d2;
    border-color: #d1d1cb; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-surface-medium:not(:disabled):not(.disabled):active:focus, .btn-surface-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-surface-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 209, 208, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-surface-light {
  color: #212529;
  background-color: #FAFAF8;
  border-color: #FAFAF8; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-surface-light:hover {
    color: #212529;
    background-color: #eaeae2;
    border-color: #e5e5da; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-light:focus, .btn-surface-light.focus {
    color: #212529;
    background-color: #eaeae2;
    border-color: #e5e5da;
    box-shadow: 0 0 0 0.2rem rgba(217, 218, 217, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-light.disabled, .btn-surface-light:disabled {
    color: #212529;
    background-color: #FAFAF8;
    border-color: #FAFAF8; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-surface-light:not(:disabled):not(.disabled):active, .btn-surface-light:not(:disabled):not(.disabled).active,
  .show > .btn-surface-light.dropdown-toggle {
    color: #212529;
    background-color: #e5e5da;
    border-color: #dfdfd3; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-surface-light:not(:disabled):not(.disabled):active:focus, .btn-surface-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-surface-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 218, 217, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-lama {
  color: #212529;
  background-color: #E9A93D;
  border-color: #E9A93D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-lama:hover {
    color: #212529;
    background-color: #e59a1b;
    border-color: #da9219; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama:focus, .btn-gsk-lama.focus {
    color: #212529;
    background-color: #e59a1b;
    border-color: #da9219;
    box-shadow: 0 0 0 0.2rem rgba(203, 149, 58, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama.disabled, .btn-gsk-lama:disabled {
    color: #212529;
    background-color: #E9A93D;
    border-color: #E9A93D; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama:not(:disabled):not(.disabled):active, .btn-gsk-lama:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-lama.dropdown-toggle {
    color: #212529;
    background-color: #da9219;
    border-color: #cf8b17; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-lama:not(:disabled):not(.disabled):active:focus, .btn-gsk-lama:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-lama.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 149, 58, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-laba {
  color: #FFFFFF;
  background-color: #F4668A;
  border-color: #F4668A; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-laba:hover {
    color: #FFFFFF;
    background-color: #f1426f;
    border-color: #f13666; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-laba:focus, .btn-gsk-laba.focus {
    color: #FFFFFF;
    background-color: #f1426f;
    border-color: #f13666;
    box-shadow: 0 0 0 0.2rem rgba(246, 125, 156, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-laba.disabled, .btn-gsk-laba:disabled {
    color: #FFFFFF;
    background-color: #F4668A;
    border-color: #F4668A; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-laba:not(:disabled):not(.disabled):active, .btn-gsk-laba:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-laba.dropdown-toggle {
    color: #FFFFFF;
    background-color: #f13666;
    border-color: #f02b5d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-laba:not(:disabled):not(.disabled):active:focus, .btn-gsk-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 125, 156, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-ics {
  color: #FFFFFF;
  background-color: #0D76D1;
  border-color: #0D76D1; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-ics:hover {
    color: #FFFFFF;
    background-color: #0b62ad;
    border-color: #0a5ba1; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics:focus, .btn-gsk-ics.focus {
    color: #FFFFFF;
    background-color: #0b62ad;
    border-color: #0a5ba1;
    box-shadow: 0 0 0 0.2rem rgba(49, 139, 216, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics.disabled, .btn-gsk-ics:disabled {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics:not(:disabled):not(.disabled):active, .btn-gsk-ics:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-ics.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a5ba1;
    border-color: #095495; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-ics:not(:disabled):not(.disabled):active:focus, .btn-gsk-ics:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-ics.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(49, 139, 216, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-dpi {
  color: #212529;
  background-color: #C5D34E;
  border-color: #C5D34E; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-dpi:hover {
    color: #212529;
    background-color: #b9c932;
    border-color: #b0bf2f; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dpi:focus, .btn-gsk-dpi.focus {
    color: #212529;
    background-color: #b9c932;
    border-color: #b0bf2f;
    box-shadow: 0 0 0 0.2rem rgba(172, 185, 72, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dpi.disabled, .btn-gsk-dpi:disabled {
    color: #212529;
    background-color: #C5D34E;
    border-color: #C5D34E; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-dpi:not(:disabled):not(.disabled):active, .btn-gsk-dpi:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-dpi.dropdown-toggle {
    color: #212529;
    background-color: #b0bf2f;
    border-color: #a6b42d; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-dpi:not(:disabled):not(.disabled):active:focus, .btn-gsk-dpi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-dpi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(172, 185, 72, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-fmi {
  color: #212529;
  background-color: #60B0C9;
  border-color: #60B0C9; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-fmi:hover {
    color: #FFFFFF;
    background-color: #43a2bf;
    border-color: #3e9bb8; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-fmi:focus, .btn-gsk-fmi.focus {
    color: #FFFFFF;
    background-color: #43a2bf;
    border-color: #3e9bb8;
    box-shadow: 0 0 0 0.2rem rgba(87, 155, 177, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-fmi.disabled, .btn-gsk-fmi:disabled {
    color: #212529;
    background-color: #60B0C9;
    border-color: #60B0C9; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-fmi:not(:disabled):not(.disabled):active, .btn-gsk-fmi:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-fmi.dropdown-toggle {
    color: #FFFFFF;
    background-color: #3e9bb8;
    border-color: #3b93ae; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-fmi:not(:disabled):not(.disabled):active:focus, .btn-gsk-fmi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-fmi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 155, 177, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-mdi {
  color: #FFFFFF;
  background-color: #747EB6;
  border-color: #747EB6; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-mdi:hover {
    color: #FFFFFF;
    background-color: #5b67a9;
    border-color: #5561a2; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-mdi:focus, .btn-gsk-mdi.focus {
    color: #FFFFFF;
    background-color: #5b67a9;
    border-color: #5561a2;
    box-shadow: 0 0 0 0.2rem rgba(137, 145, 193, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-mdi.disabled, .btn-gsk-mdi:disabled {
    color: #FFFFFF;
    background-color: #747EB6;
    border-color: #747EB6; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-mdi:not(:disabled):not(.disabled):active, .btn-gsk-mdi:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-mdi.dropdown-toggle {
    color: #FFFFFF;
    background-color: #5561a2;
    border-color: #515c9a; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-mdi:not(:disabled):not(.disabled):active:focus, .btn-gsk-mdi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-mdi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(137, 145, 193, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-lama-laba {
  color: #212529;
  background-color: #F5DB4A;
  border-color: #F5DB4A; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-lama-laba:hover {
    color: #212529;
    background-color: #f3d426;
    border-color: #f2d11a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama-laba:focus, .btn-gsk-lama-laba.focus {
    color: #212529;
    background-color: #f3d426;
    border-color: #f2d11a;
    box-shadow: 0 0 0 0.2rem rgba(213, 192, 69, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama-laba.disabled, .btn-gsk-lama-laba:disabled {
    color: #212529;
    background-color: #F5DB4A;
    border-color: #F5DB4A; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-lama-laba:not(:disabled):not(.disabled):active, .btn-gsk-lama-laba:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-lama-laba.dropdown-toggle {
    color: #212529;
    background-color: #f2d11a;
    border-color: #f2cf0e; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-lama-laba:not(:disabled):not(.disabled):active:focus, .btn-gsk-lama-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-lama-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 192, 69, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-ics-laba {
  color: #212529;
  background-color: #66B966;
  border-color: #66B966; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-ics-laba:hover {
    color: #FFFFFF;
    background-color: #4eab4e;
    border-color: #4aa24a; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics-laba:focus, .btn-gsk-ics-laba.focus {
    color: #FFFFFF;
    background-color: #4eab4e;
    border-color: #4aa24a;
    box-shadow: 0 0 0 0.2rem rgba(92, 163, 93, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics-laba.disabled, .btn-gsk-ics-laba:disabled {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-ics-laba:not(:disabled):not(.disabled):active, .btn-gsk-ics-laba:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-ics-laba.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4aa24a;
    border-color: #469946; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-ics-laba:not(:disabled):not(.disabled):active:focus, .btn-gsk-ics-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-ics-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 163, 93, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-gsk-isc-lama-laba {
  color: #FFFFFF;
  background-color: #8A347F;
  border-color: #8A347F; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-gsk-isc-lama-laba:hover {
    color: #FFFFFF;
    background-color: #6e2a65;
    border-color: #65265d; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-isc-lama-laba:focus, .btn-gsk-isc-lama-laba.focus {
    color: #FFFFFF;
    background-color: #6e2a65;
    border-color: #65265d;
    box-shadow: 0 0 0 0.2rem rgba(156, 82, 146, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-isc-lama-laba.disabled, .btn-gsk-isc-lama-laba:disabled {
    color: #FFFFFF;
    background-color: #8A347F;
    border-color: #8A347F; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-gsk-isc-lama-laba:not(:disabled):not(.disabled):active, .btn-gsk-isc-lama-laba:not(:disabled):not(.disabled).active,
  .show > .btn-gsk-isc-lama-laba.dropdown-toggle {
    color: #FFFFFF;
    background-color: #65265d;
    border-color: #5c2354; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-gsk-isc-lama-laba:not(:disabled):not(.disabled):active:focus, .btn-gsk-isc-lama-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gsk-isc-lama-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 82, 146, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-aah-red {
  color: #FFFFFF;
  background-color: #DF410F;
  border-color: #DF410F; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-aah-red:hover {
    color: #FFFFFF;
    background-color: #bb370d;
    border-color: #af330c; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-aah-red:focus, .btn-aah-red.focus {
    color: #FFFFFF;
    background-color: #bb370d;
    border-color: #af330c;
    box-shadow: 0 0 0 0.2rem rgba(228, 94, 51, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-aah-red.disabled, .btn-aah-red:disabled {
    color: #FFFFFF;
    background-color: #DF410F;
    border-color: #DF410F; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-aah-red:not(:disabled):not(.disabled):active, .btn-aah-red:not(:disabled):not(.disabled).active,
  .show > .btn-aah-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #af330c;
    border-color: #a3300b; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-aah-red:not(:disabled):not(.disabled):active:focus, .btn-aah-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-aah-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 94, 51, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-landing-1 {
  color: #FFFFFF;
  background-color: #914718;
  border-color: #914718; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-landing-1:hover {
    color: #FFFFFF;
    background-color: #703713;
    border-color: #653211; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-1:focus, .btn-landing-1.focus {
    color: #FFFFFF;
    background-color: #703713;
    border-color: #653211;
    box-shadow: 0 0 0 0.2rem rgba(162, 99, 59, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-1.disabled, .btn-landing-1:disabled {
    color: #FFFFFF;
    background-color: #914718;
    border-color: #914718; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-1:not(:disabled):not(.disabled):active, .btn-landing-1:not(:disabled):not(.disabled).active,
  .show > .btn-landing-1.dropdown-toggle {
    color: #FFFFFF;
    background-color: #653211;
    border-color: #5a2c0f; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-landing-1:not(:disabled):not(.disabled):active:focus, .btn-landing-1:not(:disabled):not(.disabled).active:focus,
    .show > .btn-landing-1.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(162, 99, 59, 0.5); }

/* line 66, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-landing-2 {
  color: #FFFFFF;
  background-color: #FF6605;
  border-color: #FF6605; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-landing-2:hover {
    color: #FFFFFF;
    background-color: #de5600;
    border-color: #d15100; }
  /* line 18, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-2:focus, .btn-landing-2.focus {
    color: #FFFFFF;
    background-color: #de5600;
    border-color: #d15100;
    box-shadow: 0 0 0 0.2rem rgba(255, 125, 43, 0.5); }
  /* line 32, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-2.disabled, .btn-landing-2:disabled {
    color: #FFFFFF;
    background-color: #FF6605;
    border-color: #FF6605; }
  /* line 43, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-landing-2:not(:disabled):not(.disabled):active, .btn-landing-2:not(:disabled):not(.disabled).active,
  .show > .btn-landing-2.dropdown-toggle {
    color: #FFFFFF;
    background-color: #d15100;
    border-color: #c44c00; }
    /* line 53, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-landing-2:not(:disabled):not(.disabled):active:focus, .btn-landing-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-landing-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 125, 43, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #F36633;
  border-color: #F36633; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #F36633;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #544F40;
  border-color: #544F40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #544F40;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #008A00;
  border-color: #008A00; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #008A00;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #15717D;
  border-color: #15717D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #15717D;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #E49B13;
  border-color: #E49B13; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #E49B13;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #ED003C;
  border-color: #ED003C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ED003C;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-primary {
  color: #F36633;
  border-color: #F36633; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-primary:hover {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-primary:focus, .btn-outline-gsk-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-primary.disabled, .btn-outline-gsk-primary:disabled {
    color: #F36633;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-primary:not(:disabled):not(.disabled):active, .btn-outline-gsk-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-dark-grey {
  color: #544F40;
  border-color: #544F40; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-dark-grey:hover {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dark-grey:focus, .btn-outline-gsk-dark-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dark-grey.disabled, .btn-outline-gsk-dark-grey:disabled {
    color: #544F40;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dark-grey:not(:disabled):not(.disabled):active, .btn-outline-gsk-dark-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-dark-grey.dropdown-toggle {
    color: #FFFFFF;
    background-color: #544F40;
    border-color: #544F40; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-dark-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-dark-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-dark-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-medium {
  color: #D5D1CE;
  border-color: #D5D1CE; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-medium:hover {
    color: #212529;
    background-color: #D5D1CE;
    border-color: #D5D1CE; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-medium:focus, .btn-outline-gsk-medium.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 209, 206, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-medium.disabled, .btn-outline-gsk-medium:disabled {
    color: #D5D1CE;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-medium:not(:disabled):not(.disabled):active, .btn-outline-gsk-medium:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-medium.dropdown-toggle {
    color: #212529;
    background-color: #D5D1CE;
    border-color: #D5D1CE; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 209, 206, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-purple {
  color: #BC1077;
  border-color: #BC1077; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-purple:hover {
    color: #FFFFFF;
    background-color: #BC1077;
    border-color: #BC1077; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple:focus, .btn-outline-gsk-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple.disabled, .btn-outline-gsk-purple:disabled {
    color: #BC1077;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple:not(:disabled):not(.disabled):active, .btn-outline-gsk-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-purple.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BC1077;
    border-color: #BC1077; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-red {
  color: #ED003C;
  border-color: #ED003C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-red:hover {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-red:focus, .btn-outline-gsk-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-red.disabled, .btn-outline-gsk-red:disabled {
    color: #ED003C;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-red:not(:disabled):not(.disabled):active, .btn-outline-gsk-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ED003C;
    border-color: #ED003C; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-red:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-indigo-blue {
  color: #40488D;
  border-color: #40488D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-indigo-blue:hover {
    color: #FFFFFF;
    background-color: #40488D;
    border-color: #40488D; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-indigo-blue:focus, .btn-outline-gsk-indigo-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 72, 141, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-indigo-blue.disabled, .btn-outline-gsk-indigo-blue:disabled {
    color: #40488D;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-indigo-blue:not(:disabled):not(.disabled):active, .btn-outline-gsk-indigo-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-indigo-blue.dropdown-toggle {
    color: #FFFFFF;
    background-color: #40488D;
    border-color: #40488D; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-indigo-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-indigo-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-indigo-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 72, 141, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-green {
  color: #008A00;
  border-color: #008A00; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-green:hover {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green:focus, .btn-outline-gsk-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green.disabled, .btn-outline-gsk-green:disabled {
    color: #008A00;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green:not(:disabled):not(.disabled):active, .btn-outline-gsk-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #008A00;
    border-color: #008A00; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-green:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-teal {
  color: #15717D;
  border-color: #15717D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-teal:hover {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal:focus, .btn-outline-gsk-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal.disabled, .btn-outline-gsk-teal:disabled {
    color: #15717D;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal:not(:disabled):not(.disabled):active, .btn-outline-gsk-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-teal.dropdown-toggle {
    color: #FFFFFF;
    background-color: #15717D;
    border-color: #15717D; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-yellow {
  color: #E49B13;
  border-color: #E49B13; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-yellow:hover {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-yellow:focus, .btn-outline-gsk-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-yellow.disabled, .btn-outline-gsk-yellow:disabled {
    color: #E49B13;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-yellow:not(:disabled):not(.disabled):active, .btn-outline-gsk-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-yellow.dropdown-toggle {
    color: #212529;
    background-color: #E49B13;
    border-color: #E49B13; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-white {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-white:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-white-85 {
  color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.85); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-white-85:hover {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-85:focus, .btn-outline-white-85.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-85.disabled, .btn-outline-white-85:disabled {
    color: rgba(255, 255, 255, 0.85);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-85:not(:disabled):not(.disabled):active, .btn-outline-white-85:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white-85.dropdown-toggle {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-white-85:not(:disabled):not(.disabled):active:focus, .btn-outline-white-85:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white-85.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-white-90 {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-white-90:hover {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-90:focus, .btn-outline-white-90.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-90.disabled, .btn-outline-white-90:disabled {
    color: rgba(255, 255, 255, 0.9);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-90:not(:disabled):not(.disabled):active, .btn-outline-white-90:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white-90.dropdown-toggle {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-white-90:not(:disabled):not(.disabled):active:focus, .btn-outline-white-90:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white-90.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-white-95 {
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.95); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-white-95:hover {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(255, 255, 255, 0.95); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-95:focus, .btn-outline-white-95.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-95.disabled, .btn-outline-white-95:disabled {
    color: rgba(255, 255, 255, 0.95);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-white-95:not(:disabled):not(.disabled):active, .btn-outline-white-95:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white-95.dropdown-toggle {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(255, 255, 255, 0.95); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-white-95:not(:disabled):not(.disabled):active:focus, .btn-outline-white-95:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white-95.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-grey {
  color: #BEBEBD;
  border-color: #BEBEBD; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-grey:hover {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-grey:focus, .btn-outline-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-grey.disabled, .btn-outline-grey:disabled {
    color: #BEBEBD;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey.dropdown-toggle {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-black {
  color: #000000;
  border-color: #000000; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-black:hover {
    color: #FFFFFF;
    background-color: #000000;
    border-color: #000000; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000000;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: #000000;
    border-color: #000000; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-purple-60 {
  color: rgba(188, 16, 119, 0.6);
  border-color: rgba(188, 16, 119, 0.6); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-purple-60:hover {
    color: #FFFFFF;
    background-color: rgba(188, 16, 119, 0.6);
    border-color: rgba(188, 16, 119, 0.6); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple-60:focus, .btn-outline-gsk-purple-60.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple-60.disabled, .btn-outline-gsk-purple-60:disabled {
    color: rgba(188, 16, 119, 0.6);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-purple-60:not(:disabled):not(.disabled):active, .btn-outline-gsk-purple-60:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-purple-60.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(188, 16, 119, 0.6);
    border-color: rgba(188, 16, 119, 0.6); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-purple-60:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-purple-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-purple-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-teal-60 {
  color: rgba(21, 113, 125, 0.6);
  border-color: rgba(21, 113, 125, 0.6); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-teal-60:hover {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.6);
    border-color: rgba(21, 113, 125, 0.6); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-60:focus, .btn-outline-gsk-teal-60.focus {
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-60.disabled, .btn-outline-gsk-teal-60:disabled {
    color: rgba(21, 113, 125, 0.6);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-60:not(:disabled):not(.disabled):active, .btn-outline-gsk-teal-60:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-teal-60.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.6);
    border-color: rgba(21, 113, 125, 0.6); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-teal-60:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-teal-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-teal-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-teal-30 {
  color: rgba(21, 113, 125, 0.3);
  border-color: rgba(21, 113, 125, 0.3); }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-teal-30:hover {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.3);
    border-color: rgba(21, 113, 125, 0.3); }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-30:focus, .btn-outline-gsk-teal-30.focus {
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-30.disabled, .btn-outline-gsk-teal-30:disabled {
    color: rgba(21, 113, 125, 0.3);
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-teal-30:not(:disabled):not(.disabled):active, .btn-outline-gsk-teal-30:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-teal-30.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(21, 113, 125, 0.3);
    border-color: rgba(21, 113, 125, 0.3); }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-teal-30:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-teal-30:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-teal-30.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-green-60 {
  color: #66B966;
  border-color: #66B966; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-green-60:hover {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green-60:focus, .btn-outline-gsk-green-60.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green-60.disabled, .btn-outline-gsk-green-60:disabled {
    color: #66B966;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-green-60:not(:disabled):not(.disabled):active, .btn-outline-gsk-green-60:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-green-60.dropdown-toggle {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-green-60:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-green-60:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-green-60.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-link-blue {
  color: #0D76D1;
  border-color: #0D76D1; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-link-blue:hover {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-link-blue:focus, .btn-outline-link-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-link-blue.disabled, .btn-outline-link-blue:disabled {
    color: #0D76D1;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-link-blue:not(:disabled):not(.disabled):active, .btn-outline-link-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-link-blue.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-link-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-link-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-link-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-text-black {
  color: #2E2E3C;
  border-color: #2E2E3C; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-text-black:hover {
    color: #FFFFFF;
    background-color: #2E2E3C;
    border-color: #2E2E3C; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-black:focus, .btn-outline-text-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(46, 46, 60, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-black.disabled, .btn-outline-text-black:disabled {
    color: #2E2E3C;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-black:not(:disabled):not(.disabled):active, .btn-outline-text-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: #2E2E3C;
    border-color: #2E2E3C; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-text-black:not(:disabled):not(.disabled):active:focus, .btn-outline-text-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(46, 46, 60, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-text-secondary {
  color: #777369;
  border-color: #777369; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-text-secondary:hover {
    color: #FFFFFF;
    background-color: #777369;
    border-color: #777369; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-secondary:focus, .btn-outline-text-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 115, 105, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-secondary.disabled, .btn-outline-text-secondary:disabled {
    color: #777369;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-secondary:not(:disabled):not(.disabled):active, .btn-outline-text-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #777369;
    border-color: #777369; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-text-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-text-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 115, 105, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-text-medium {
  color: #878379;
  border-color: #878379; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-text-medium:hover {
    color: #FFFFFF;
    background-color: #878379;
    border-color: #878379; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-medium:focus, .btn-outline-text-medium.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 131, 121, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-medium.disabled, .btn-outline-text-medium:disabled {
    color: #878379;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-medium:not(:disabled):not(.disabled):active, .btn-outline-text-medium:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text-medium.dropdown-toggle {
    color: #FFFFFF;
    background-color: #878379;
    border-color: #878379; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-text-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-text-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 131, 121, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-text-light {
  color: #BBB9B3;
  border-color: #BBB9B3; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-text-light:hover {
    color: #212529;
    background-color: #BBB9B3;
    border-color: #BBB9B3; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-light:focus, .btn-outline-text-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 185, 179, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-light.disabled, .btn-outline-text-light:disabled {
    color: #BBB9B3;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-text-light:not(:disabled):not(.disabled):active, .btn-outline-text-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text-light.dropdown-toggle {
    color: #212529;
    background-color: #BBB9B3;
    border-color: #BBB9B3; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-text-light:not(:disabled):not(.disabled):active:focus, .btn-outline-text-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(187, 185, 179, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-surface-dark {
  color: #BEBEBD;
  border-color: #BEBEBD; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-surface-dark:hover {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-dark:focus, .btn-outline-surface-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-dark.disabled, .btn-outline-surface-dark:disabled {
    color: #BEBEBD;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-dark:not(:disabled):not(.disabled):active, .btn-outline-surface-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-surface-dark.dropdown-toggle {
    color: #212529;
    background-color: #BEBEBD;
    border-color: #BEBEBD; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-surface-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-surface-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-surface-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-surface-medium {
  color: #EFEFED;
  border-color: #EFEFED; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-surface-medium:hover {
    color: #212529;
    background-color: #EFEFED;
    border-color: #EFEFED; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-medium:focus, .btn-outline-surface-medium.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 237, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-medium.disabled, .btn-outline-surface-medium:disabled {
    color: #EFEFED;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-medium:not(:disabled):not(.disabled):active, .btn-outline-surface-medium:not(:disabled):not(.disabled).active,
  .show > .btn-outline-surface-medium.dropdown-toggle {
    color: #212529;
    background-color: #EFEFED;
    border-color: #EFEFED; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-surface-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-surface-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-surface-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 239, 237, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-surface-light {
  color: #FAFAF8;
  border-color: #FAFAF8; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-surface-light:hover {
    color: #212529;
    background-color: #FAFAF8;
    border-color: #FAFAF8; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-light:focus, .btn-outline-surface-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 248, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-light.disabled, .btn-outline-surface-light:disabled {
    color: #FAFAF8;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-surface-light:not(:disabled):not(.disabled):active, .btn-outline-surface-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-surface-light.dropdown-toggle {
    color: #212529;
    background-color: #FAFAF8;
    border-color: #FAFAF8; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-surface-light:not(:disabled):not(.disabled):active:focus, .btn-outline-surface-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-surface-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 248, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-lama {
  color: #E9A93D;
  border-color: #E9A93D; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-lama:hover {
    color: #212529;
    background-color: #E9A93D;
    border-color: #E9A93D; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama:focus, .btn-outline-gsk-lama.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 169, 61, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama.disabled, .btn-outline-gsk-lama:disabled {
    color: #E9A93D;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama:not(:disabled):not(.disabled):active, .btn-outline-gsk-lama:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-lama.dropdown-toggle {
    color: #212529;
    background-color: #E9A93D;
    border-color: #E9A93D; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-lama:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-lama:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-lama.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 169, 61, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-laba {
  color: #F4668A;
  border-color: #F4668A; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-laba:hover {
    color: #FFFFFF;
    background-color: #F4668A;
    border-color: #F4668A; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-laba:focus, .btn-outline-gsk-laba.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 102, 138, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-laba.disabled, .btn-outline-gsk-laba:disabled {
    color: #F4668A;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-laba:not(:disabled):not(.disabled):active, .btn-outline-gsk-laba:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-laba.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F4668A;
    border-color: #F4668A; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-laba:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 102, 138, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-ics {
  color: #0D76D1;
  border-color: #0D76D1; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-ics:hover {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics:focus, .btn-outline-gsk-ics.focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics.disabled, .btn-outline-gsk-ics:disabled {
    color: #0D76D1;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics:not(:disabled):not(.disabled):active, .btn-outline-gsk-ics:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-ics.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0D76D1;
    border-color: #0D76D1; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-ics:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-ics:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-ics.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-dpi {
  color: #C5D34E;
  border-color: #C5D34E; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-dpi:hover {
    color: #212529;
    background-color: #C5D34E;
    border-color: #C5D34E; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dpi:focus, .btn-outline-gsk-dpi.focus {
    box-shadow: 0 0 0 0.2rem rgba(197, 211, 78, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dpi.disabled, .btn-outline-gsk-dpi:disabled {
    color: #C5D34E;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-dpi:not(:disabled):not(.disabled):active, .btn-outline-gsk-dpi:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-dpi.dropdown-toggle {
    color: #212529;
    background-color: #C5D34E;
    border-color: #C5D34E; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-dpi:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-dpi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-dpi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(197, 211, 78, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-fmi {
  color: #60B0C9;
  border-color: #60B0C9; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-fmi:hover {
    color: #212529;
    background-color: #60B0C9;
    border-color: #60B0C9; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-fmi:focus, .btn-outline-gsk-fmi.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 176, 201, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-fmi.disabled, .btn-outline-gsk-fmi:disabled {
    color: #60B0C9;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-fmi:not(:disabled):not(.disabled):active, .btn-outline-gsk-fmi:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-fmi.dropdown-toggle {
    color: #212529;
    background-color: #60B0C9;
    border-color: #60B0C9; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-fmi:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-fmi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-fmi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 176, 201, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-mdi {
  color: #747EB6;
  border-color: #747EB6; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-mdi:hover {
    color: #FFFFFF;
    background-color: #747EB6;
    border-color: #747EB6; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-mdi:focus, .btn-outline-gsk-mdi.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 126, 182, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-mdi.disabled, .btn-outline-gsk-mdi:disabled {
    color: #747EB6;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-mdi:not(:disabled):not(.disabled):active, .btn-outline-gsk-mdi:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-mdi.dropdown-toggle {
    color: #FFFFFF;
    background-color: #747EB6;
    border-color: #747EB6; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-mdi:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-mdi:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-mdi.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 126, 182, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-lama-laba {
  color: #F5DB4A;
  border-color: #F5DB4A; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-lama-laba:hover {
    color: #212529;
    background-color: #F5DB4A;
    border-color: #F5DB4A; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama-laba:focus, .btn-outline-gsk-lama-laba.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 219, 74, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama-laba.disabled, .btn-outline-gsk-lama-laba:disabled {
    color: #F5DB4A;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-lama-laba:not(:disabled):not(.disabled):active, .btn-outline-gsk-lama-laba:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-lama-laba.dropdown-toggle {
    color: #212529;
    background-color: #F5DB4A;
    border-color: #F5DB4A; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-lama-laba:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-lama-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-lama-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 219, 74, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-ics-laba {
  color: #66B966;
  border-color: #66B966; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-ics-laba:hover {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics-laba:focus, .btn-outline-gsk-ics-laba.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics-laba.disabled, .btn-outline-gsk-ics-laba:disabled {
    color: #66B966;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-ics-laba:not(:disabled):not(.disabled):active, .btn-outline-gsk-ics-laba:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-ics-laba.dropdown-toggle {
    color: #212529;
    background-color: #66B966;
    border-color: #66B966; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-ics-laba:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-ics-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-ics-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-gsk-isc-lama-laba {
  color: #8A347F;
  border-color: #8A347F; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-gsk-isc-lama-laba:hover {
    color: #FFFFFF;
    background-color: #8A347F;
    border-color: #8A347F; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-isc-lama-laba:focus, .btn-outline-gsk-isc-lama-laba.focus {
    box-shadow: 0 0 0 0.2rem rgba(138, 52, 127, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-isc-lama-laba.disabled, .btn-outline-gsk-isc-lama-laba:disabled {
    color: #8A347F;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-gsk-isc-lama-laba:not(:disabled):not(.disabled):active, .btn-outline-gsk-isc-lama-laba:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gsk-isc-lama-laba.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8A347F;
    border-color: #8A347F; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-gsk-isc-lama-laba:not(:disabled):not(.disabled):active:focus, .btn-outline-gsk-isc-lama-laba:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gsk-isc-lama-laba.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 52, 127, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-aah-red {
  color: #DF410F;
  border-color: #DF410F; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-aah-red:hover {
    color: #FFFFFF;
    background-color: #DF410F;
    border-color: #DF410F; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-aah-red:focus, .btn-outline-aah-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 65, 15, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-aah-red.disabled, .btn-outline-aah-red:disabled {
    color: #DF410F;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-aah-red:not(:disabled):not(.disabled):active, .btn-outline-aah-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-aah-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #DF410F;
    border-color: #DF410F; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-aah-red:not(:disabled):not(.disabled):active:focus, .btn-outline-aah-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-aah-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 65, 15, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-landing-1 {
  color: #914718;
  border-color: #914718; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-landing-1:hover {
    color: #FFFFFF;
    background-color: #914718;
    border-color: #914718; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-1:focus, .btn-outline-landing-1.focus {
    box-shadow: 0 0 0 0.2rem rgba(145, 71, 24, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-1.disabled, .btn-outline-landing-1:disabled {
    color: #914718;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-1:not(:disabled):not(.disabled):active, .btn-outline-landing-1:not(:disabled):not(.disabled).active,
  .show > .btn-outline-landing-1.dropdown-toggle {
    color: #FFFFFF;
    background-color: #914718;
    border-color: #914718; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-landing-1:not(:disabled):not(.disabled):active:focus, .btn-outline-landing-1:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-landing-1.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(145, 71, 24, 0.5); }

/* line 72, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-landing-2 {
  color: #FF6605;
  border-color: #FF6605; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-landing-2:hover {
    color: #FFFFFF;
    background-color: #FF6605;
    border-color: #FF6605; }
  /* line 74, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-2:focus, .btn-outline-landing-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 102, 5, 0.5); }
  /* line 79, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-2.disabled, .btn-outline-landing-2:disabled {
    color: #FF6605;
    background-color: transparent; }
  /* line 85, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-landing-2:not(:disabled):not(.disabled):active, .btn-outline-landing-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-landing-2.dropdown-toggle {
    color: #FFFFFF;
    background-color: #FF6605;
    border-color: #FF6605; }
    /* line 92, ../node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-landing-2:not(:disabled):not(.disabled):active:focus, .btn-outline-landing-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-landing-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 102, 5, 0.5); }

/* line 83, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #F36633;
  text-decoration: none; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-link:hover {
    color: #cd3f0c;
    text-decoration: underline; }
  /* line 93, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  /* line 98, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

/* line 112, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 116, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 125, ../node_modules/bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }
  /* line 130, ../node_modules/bootstrap/scss/_buttons.scss */
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

/* line 139, ../node_modules/bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 1, ../node_modules/bootstrap/scss/_transitions.scss */
.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    /* line 1, ../node_modules/bootstrap/scss/_transitions.scss */
    .fade {
      transition: none; } }
  /* line 4, ../node_modules/bootstrap/scss/_transitions.scss */
  .fade:not(.show) {
    opacity: 0; }

/* line 10, ../node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none; }

/* line 15, ../node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 15, ../node_modules/bootstrap/scss/_transitions.scss */
    .collapsing {
      transition: none; } }
  /* line 21, ../node_modules/bootstrap/scss/_transitions.scss */
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      /* line 21, ../node_modules/bootstrap/scss/_transitions.scss */
      .collapsing.width {
        transition: none; } }

/* line 2, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

/* line 9, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-toggle {
  white-space: nowrap; }
  /* line 30, ../node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  /* line 58, ../node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle:empty::after {
    margin-left: 0; }

/* line 17, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

/* line 42, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-left {
  right: auto;
  left: 0; }

/* line 47, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  /* line 42, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  /* line 47, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  /* line 42, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  /* line 47, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  /* line 42, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  /* line 47, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  /* line 42, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  /* line 47, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

/* line 57, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

/* line 30, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

/* line 58, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 70, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

/* line 30, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

/* line 58, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 80, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

/* line 87, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

/* line 30, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

/* line 45, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: none; }

/* line 49, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

/* line 58, ../node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 97, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

/* line 106, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

/* line 116, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-divider {
  height: 0;
  margin: 4px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

/* line 123, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  /* line 154, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #F36633; }
  /* line 161, ../node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

/* line 173, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu.show {
  display: block; }

/* line 178, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

/* line 188, ../node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

/* line 4, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  /* line 10, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    /* line 19, ../node_modules/bootstrap/scss/_button-group.scss */
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

/* line 28, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  /* line 33, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-toolbar .input-group {
    width: auto; }

/* line 40, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

/* line 46, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 51, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 69, ../node_modules/bootstrap/scss/_button-group.scss */
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  /* line 73, ../node_modules/bootstrap/scss/_button-group.scss */
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  /* line 79, ../node_modules/bootstrap/scss/_button-group.scss */
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

/* line 84, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

/* line 89, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

/* line 111, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  /* line 116, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  /* line 121, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  /* line 127, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 132, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 152, ../node_modules/bootstrap/scss/_button-group.scss */
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  /* line 156, ../node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

/* line 7, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  /* line 14, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    /* line 24, ../node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  /* line 32, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  /* line 39, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  /* line 45, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 50, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    /* line 54, ../node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    /* line 56, ../node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  /* line 60, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  /* line 69, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

/* line 85, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend,
.input-group-append {
  display: flex; }
  /* line 92, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    /* line 96, ../node_modules/bootstrap/scss/_input-group.scss */
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  /* line 101, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

/* line 109, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend {
  margin-right: -1px; }

/* line 110, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-append {
  margin-left: -1px; }

/* line 118, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 134, ../node_modules/bootstrap/scss/_input-group.scss */
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

/* line 146, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

/* line 151, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 163, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

/* line 168, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 180, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

/* line 193, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 204, ../node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 10, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

/* line 19, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

/* line 24, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  /* line 32, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #F36633;
    background-color: #F36633; }
  /* line 39, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
  /* line 48, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fac0ab; }
  /* line 52, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #fde4dc;
    border-color: #fde4dc; }
  /* line 62, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    /* line 65, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

/* line 76, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  /* line 84, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFFFFF;
    border: 1px solid #adb5bd; }
  /* line 99, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

/* line 117, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

/* line 122, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

/* line 128, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #F36633;
  background-color: #F36633; }

/* line 133, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

/* line 139, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(243, 102, 51, 0.5); }

/* line 142, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(243, 102, 51, 0.5); }

/* line 153, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-label::before {
  border-radius: 50%; }

/* line 159, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

/* line 165, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(243, 102, 51, 0.5); }

/* line 176, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch {
  padding-left: 2.25rem; }
  /* line 180, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  /* line 188, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      /* line 188, ../node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-switch .custom-control-label::after {
        transition: none; } }
  /* line 201, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    transform: translateX(0.75rem); }
  /* line 208, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(243, 102, 51, 0.5); }

/* line 221, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  /* line 238, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:focus {
    border-color: #fac0ab;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
    /* line 248, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  /* line 259, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  /* line 266, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  /* line 272, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select::-ms-expand {
    display: none; }
  /* line 277, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

/* line 283, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

/* line 291, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

/* line 304, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

/* line 312, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  /* line 321, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #fac0ab;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
  /* line 327, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  /* line 333, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  /* line 338, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

/* line 343, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 361, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

/* line 385, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  /* line 392, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:focus {
    outline: 0; }
    /* line 397, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
    /* line 398, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
    /* line 399, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }
  /* line 402, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-focus-outer {
    border: 0; }
  /* line 406, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #F36633;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 406, ../node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    /* line 417, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fde4dc; }
  /* line 422, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 433, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #F36633;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 433, ../node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-moz-range-thumb {
        transition: none; } }
    /* line 443, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-moz-range-thumb:active {
      background-color: #fde4dc; }
  /* line 448, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 459, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #F36633;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 459, ../node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-ms-thumb {
        transition: none; } }
    /* line 472, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-ms-thumb:active {
      background-color: #fde4dc; }
  /* line 477, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  /* line 488, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 493, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 500, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  /* line 504, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  /* line 508, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  /* line 512, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  /* line 516, ../node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

/* line 522, ../node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 522, ../node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

/* line 6, ../node_modules/bootstrap/scss/_nav.scss */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

/* line 14, ../node_modules/bootstrap/scss/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  /* line 24, ../node_modules/bootstrap/scss/_nav.scss */
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

/* line 35, ../node_modules/bootstrap/scss/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  /* line 38, ../node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    /* line 50, ../node_modules/bootstrap/scss/_nav.scss */
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  /* line 57, ../node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  /* line 64, ../node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 78, ../node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

/* line 84, ../node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #F36633; }

/* line 97, ../node_modules/bootstrap/scss/_nav.scss */
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

/* line 105, ../node_modules/bootstrap/scss/_nav.scss */
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* line 119, ../node_modules/bootstrap/scss/_nav.scss */
.tab-content > .tab-pane {
  display: none; }

/* line 122, ../node_modules/bootstrap/scss/_nav.scss */
.tab-content > .active {
  display: block; }

/* line 18, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px; }
  /* line 28, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

/* line 52, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 8px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

/* line 71, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  /* line 78, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  /* line 83, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

/* line 94, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

/* line 109, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

/* line 118, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

/* line 133, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

/* line 142, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  /* line 156, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  /* line 154, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 177, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      /* line 180, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 184, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 191, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    /* line 206, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    /* line 210, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 217, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  /* line 156, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  /* line 154, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 177, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      /* line 180, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 184, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 191, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    /* line 206, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    /* line 210, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 217, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  /* line 156, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  /* line 154, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 177, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      /* line 180, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 184, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 191, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    /* line 206, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    /* line 210, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 217, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  /* line 156, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  /* line 154, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 177, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      /* line 180, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 184, ../node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 191, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    /* line 206, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    /* line 210, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 217, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

/* line 154, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  /* line 156, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  /* line 177, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    /* line 180, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    /* line 184, ../node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  /* line 191, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  /* line 206, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  /* line 210, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  /* line 217, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-toggler {
    display: none; }

/* line 232, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

/* line 241, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  /* line 248, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

/* line 253, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

/* line 261, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

/* line 266, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 270, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  /* line 272, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

/* line 284, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

/* line 293, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  /* line 300, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

/* line 305, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

/* line 313, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

/* line 318, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 322, ../node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  /* line 324, ../node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

/* line 5, ../node_modules/bootstrap/scss/_card.scss */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  /* line 17, ../node_modules/bootstrap/scss/_card.scss */
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  /* line 22, ../node_modules/bootstrap/scss/_card.scss */
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    /* line 26, ../node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    /* line 31, ../node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  /* line 39, ../node_modules/bootstrap/scss/_card.scss */
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

/* line 45, ../node_modules/bootstrap/scss/_card.scss */
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

/* line 56, ../node_modules/bootstrap/scss/_card.scss */
.card-title {
  margin-bottom: 0.75rem; }

/* line 60, ../node_modules/bootstrap/scss/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

/* line 65, ../node_modules/bootstrap/scss/_card.scss */
.card-text:last-child {
  margin-bottom: 0; }

/* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none; }

/* line 74, ../node_modules/bootstrap/scss/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem; }

/* line 83, ../node_modules/bootstrap/scss/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 90, ../node_modules/bootstrap/scss/_card.scss */
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

/* line 95, ../node_modules/bootstrap/scss/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 101, ../node_modules/bootstrap/scss/_card.scss */
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

/* line 111, ../node_modules/bootstrap/scss/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

/* line 118, ../node_modules/bootstrap/scss/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

/* line 124, ../node_modules/bootstrap/scss/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

/* line 134, ../node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

/* line 141, ../node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

/* line 146, ../node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

/* line 155, ../node_modules/bootstrap/scss/_card.scss */
.card-deck .card {
  margin-bottom: 16px; }

@media (min-width: 576px) {
  /* line 154, ../node_modules/bootstrap/scss/_card.scss */
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px; }
    /* line 165, ../node_modules/bootstrap/scss/_card.scss */
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 16px;
      margin-bottom: 0;
      margin-left: 16px; } }

/* line 183, ../node_modules/bootstrap/scss/_card.scss */
.card-group > .card {
  margin-bottom: 16px; }

@media (min-width: 576px) {
  /* line 180, ../node_modules/bootstrap/scss/_card.scss */
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    /* line 192, ../node_modules/bootstrap/scss/_card.scss */
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      /* line 197, ../node_modules/bootstrap/scss/_card.scss */
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      /* line 204, ../node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        /* line 207, ../node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        /* line 212, ../node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      /* line 219, ../node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        /* line 222, ../node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        /* line 227, ../node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

/* line 244, ../node_modules/bootstrap/scss/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  /* line 243, ../node_modules/bootstrap/scss/_card.scss */
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    /* line 254, ../node_modules/bootstrap/scss/_card.scss */
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

/* line 266, ../node_modules/bootstrap/scss/_card.scss */
.accordion {
  overflow-anchor: none; }
  /* line 269, ../node_modules/bootstrap/scss/_card.scss */
  .accordion > .card {
    overflow: hidden; }
    /* line 272, ../node_modules/bootstrap/scss/_card.scss */
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    /* line 277, ../node_modules/bootstrap/scss/_card.scss */
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    /* line 281, ../node_modules/bootstrap/scss/_card.scss */
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

/* line 1, ../node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 14, ../node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  /* line 17, ../node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

/* line 31, ../node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

/* line 35, ../node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

/* line 39, ../node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item.active {
  color: #6c757d; }

/* line 1, ../node_modules/bootstrap/scss/_pagination.scss */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

/* line 7, ../node_modules/bootstrap/scss/_pagination.scss */
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #F36633;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  /* line 18, ../node_modules/bootstrap/scss/_pagination.scss */
  .page-link:hover {
    z-index: 2;
    color: #cd3f0c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  /* line 26, ../node_modules/bootstrap/scss/_pagination.scss */
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }

/* line 35, ../node_modules/bootstrap/scss/_pagination.scss */
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

/* line 41, ../node_modules/bootstrap/scss/_pagination.scss */
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

/* line 46, ../node_modules/bootstrap/scss/_pagination.scss */
.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #F36633;
  border-color: #F36633; }

/* line 53, ../node_modules/bootstrap/scss/_pagination.scss */
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 12, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 12, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

/* line 6, ../node_modules/bootstrap/scss/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 6, ../node_modules/bootstrap/scss/_badge.scss */
    .badge {
      transition: none; } }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  /* line 25, ../node_modules/bootstrap/scss/_badge.scss */
  .badge:empty {
    display: none; }

/* line 31, ../node_modules/bootstrap/scss/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px; }

/* line 40, ../node_modules/bootstrap/scss/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-primary {
  color: #FFFFFF;
  background-color: #F36633; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #e6470e; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-secondary {
  color: #FFFFFF;
  background-color: #544F40; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #37342a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-success {
  color: #FFFFFF;
  background-color: #008A00; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #005700; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-info {
  color: #FFFFFF;
  background-color: #15717D; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFFF;
    background-color: #0e4a51; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-warning {
  color: #212529;
  background-color: #E49B13; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #b57b0f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-danger {
  color: #FFFFFF;
  background-color: #ED003C; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #ba002f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFFF;
    background-color: #1d2124; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-primary {
  color: #FFFFFF;
  background-color: #F36633; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-primary:hover, a.badge-gsk-primary:focus {
    color: #FFFFFF;
    background-color: #e6470e; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-primary:focus, a.badge-gsk-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-dark-grey {
  color: #FFFFFF;
  background-color: #544F40; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-dark-grey:hover, a.badge-gsk-dark-grey:focus {
    color: #FFFFFF;
    background-color: #37342a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-dark-grey:focus, a.badge-gsk-dark-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(84, 79, 64, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-medium {
  color: #212529;
  background-color: #D5D1CE; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-medium:hover, a.badge-gsk-medium:focus {
    color: #212529;
    background-color: #bdb7b3; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-medium:focus, a.badge-gsk-medium.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(213, 209, 206, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-purple {
  color: #FFFFFF;
  background-color: #BC1077; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-purple:hover, a.badge-gsk-purple:focus {
    color: #FFFFFF;
    background-color: #8d0c59; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-purple:focus, a.badge-gsk-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-red {
  color: #FFFFFF;
  background-color: #ED003C; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-red:hover, a.badge-gsk-red:focus {
    color: #FFFFFF;
    background-color: #ba002f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-red:focus, a.badge-gsk-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(237, 0, 60, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-indigo-blue {
  color: #FFFFFF;
  background-color: #40488D; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-indigo-blue:hover, a.badge-gsk-indigo-blue:focus {
    color: #FFFFFF;
    background-color: #30366a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-indigo-blue:focus, a.badge-gsk-indigo-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(64, 72, 141, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-green {
  color: #FFFFFF;
  background-color: #008A00; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-green:hover, a.badge-gsk-green:focus {
    color: #FFFFFF;
    background-color: #005700; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-green:focus, a.badge-gsk-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 138, 0, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-teal {
  color: #FFFFFF;
  background-color: #15717D; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-teal:hover, a.badge-gsk-teal:focus {
    color: #FFFFFF;
    background-color: #0e4a51; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-teal:focus, a.badge-gsk-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-yellow {
  color: #212529;
  background-color: #E49B13; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-yellow:hover, a.badge-gsk-yellow:focus {
    color: #212529;
    background-color: #b57b0f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-yellow:focus, a.badge-gsk-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(228, 155, 19, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-white {
  color: #212529;
  background-color: #FFFFFF; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-white-85 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.85); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-white-85:hover, a.badge-white-85:focus {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.85); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-white-85:focus, a.badge-white-85.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-white-90 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.9); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-white-90:hover, a.badge-white-90:focus {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.9); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-white-90:focus, a.badge-white-90.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-white-95 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.95); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-white-95:hover, a.badge-white-95:focus {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.95); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-white-95:focus, a.badge-white-95.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-grey {
  color: #212529;
  background-color: #BEBEBD; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-grey:hover, a.badge-grey:focus {
    color: #212529;
    background-color: #a5a5a3; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-grey:focus, a.badge-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-black {
  color: #FFFFFF;
  background-color: #000000; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-black:hover, a.badge-black:focus {
    color: #FFFFFF;
    background-color: black; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-purple-60 {
  color: #FFFFFF;
  background-color: rgba(188, 16, 119, 0.6); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-purple-60:hover, a.badge-gsk-purple-60:focus {
    color: #FFFFFF;
    background-color: rgba(141, 12, 89, 0.6); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-purple-60:focus, a.badge-gsk-purple-60.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(188, 16, 119, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-teal-60 {
  color: #FFFFFF;
  background-color: rgba(21, 113, 125, 0.6); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-teal-60:hover, a.badge-gsk-teal-60:focus {
    color: #FFFFFF;
    background-color: rgba(14, 74, 81, 0.6); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-teal-60:focus, a.badge-gsk-teal-60.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-teal-30 {
  color: #FFFFFF;
  background-color: rgba(21, 113, 125, 0.3); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-teal-30:hover, a.badge-gsk-teal-30:focus {
    color: #FFFFFF;
    background-color: rgba(14, 74, 81, 0.3); }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-teal-30:focus, a.badge-gsk-teal-30.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(21, 113, 125, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-green-60 {
  color: #212529;
  background-color: #66B966; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-green-60:hover, a.badge-gsk-green-60:focus {
    color: #212529;
    background-color: #4aa24a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-green-60:focus, a.badge-gsk-green-60.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-link-blue {
  color: #FFFFFF;
  background-color: #0D76D1; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-link-blue:hover, a.badge-link-blue:focus {
    color: #FFFFFF;
    background-color: #0a5ba1; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-link-blue:focus, a.badge-link-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-text-black {
  color: #FFFFFF;
  background-color: #2E2E3C; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-text-black:hover, a.badge-text-black:focus {
    color: #FFFFFF;
    background-color: #18181f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-text-black:focus, a.badge-text-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 46, 60, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-text-secondary {
  color: #FFFFFF;
  background-color: #777369; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-text-secondary:hover, a.badge-text-secondary:focus {
    color: #FFFFFF;
    background-color: #5c5951; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-text-secondary:focus, a.badge-text-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(119, 115, 105, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-text-medium {
  color: #FFFFFF;
  background-color: #878379; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-text-medium:hover, a.badge-text-medium:focus {
    color: #FFFFFF;
    background-color: #6c6961; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-text-medium:focus, a.badge-text-medium.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(135, 131, 121, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-text-light {
  color: #212529;
  background-color: #BBB9B3; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-text-light:hover, a.badge-text-light:focus {
    color: #212529;
    background-color: #a3a098; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-text-light:focus, a.badge-text-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(187, 185, 179, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-surface-dark {
  color: #212529;
  background-color: #BEBEBD; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-surface-dark:hover, a.badge-surface-dark:focus {
    color: #212529;
    background-color: #a5a5a3; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-surface-dark:focus, a.badge-surface-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(190, 190, 189, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-surface-medium {
  color: #212529;
  background-color: #EFEFED; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-surface-medium:hover, a.badge-surface-medium:focus {
    color: #212529;
    background-color: #d7d7d2; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-surface-medium:focus, a.badge-surface-medium.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 237, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-surface-light {
  color: #212529;
  background-color: #FAFAF8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-surface-light:hover, a.badge-surface-light:focus {
    color: #212529;
    background-color: #e5e5da; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-surface-light:focus, a.badge-surface-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 248, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-lama {
  color: #212529;
  background-color: #E9A93D; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-lama:hover, a.badge-gsk-lama:focus {
    color: #212529;
    background-color: #da9219; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-lama:focus, a.badge-gsk-lama.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 169, 61, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-laba {
  color: #FFFFFF;
  background-color: #F4668A; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-laba:hover, a.badge-gsk-laba:focus {
    color: #FFFFFF;
    background-color: #f13666; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-laba:focus, a.badge-gsk-laba.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 102, 138, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-ics {
  color: #FFFFFF;
  background-color: #0D76D1; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-ics:hover, a.badge-gsk-ics:focus {
    color: #FFFFFF;
    background-color: #0a5ba1; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-ics:focus, a.badge-gsk-ics.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 118, 209, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-dpi {
  color: #212529;
  background-color: #C5D34E; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-dpi:hover, a.badge-gsk-dpi:focus {
    color: #212529;
    background-color: #b0bf2f; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-dpi:focus, a.badge-gsk-dpi.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(197, 211, 78, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-fmi {
  color: #212529;
  background-color: #60B0C9; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-fmi:hover, a.badge-gsk-fmi:focus {
    color: #212529;
    background-color: #3e9bb8; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-fmi:focus, a.badge-gsk-fmi.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 176, 201, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-mdi {
  color: #FFFFFF;
  background-color: #747EB6; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-mdi:hover, a.badge-gsk-mdi:focus {
    color: #FFFFFF;
    background-color: #5561a2; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-mdi:focus, a.badge-gsk-mdi.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 126, 182, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-lama-laba {
  color: #212529;
  background-color: #F5DB4A; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-lama-laba:hover, a.badge-gsk-lama-laba:focus {
    color: #212529;
    background-color: #f2d11a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-lama-laba:focus, a.badge-gsk-lama-laba.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 219, 74, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-ics-laba {
  color: #212529;
  background-color: #66B966; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-ics-laba:hover, a.badge-gsk-ics-laba:focus {
    color: #212529;
    background-color: #4aa24a; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-ics-laba:focus, a.badge-gsk-ics-laba.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 185, 102, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-gsk-isc-lama-laba {
  color: #FFFFFF;
  background-color: #8A347F; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-gsk-isc-lama-laba:hover, a.badge-gsk-isc-lama-laba:focus {
    color: #FFFFFF;
    background-color: #65265d; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-gsk-isc-lama-laba:focus, a.badge-gsk-isc-lama-laba.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(138, 52, 127, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-aah-red {
  color: #FFFFFF;
  background-color: #DF410F; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-aah-red:hover, a.badge-aah-red:focus {
    color: #FFFFFF;
    background-color: #af330c; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-aah-red:focus, a.badge-aah-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 65, 15, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-landing-1 {
  color: #FFFFFF;
  background-color: #914718; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-landing-1:hover, a.badge-landing-1:focus {
    color: #FFFFFF;
    background-color: #653211; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-landing-1:focus, a.badge-landing-1.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(145, 71, 24, 0.5); }

/* line 51, ../node_modules/bootstrap/scss/_badge.scss */
.badge-landing-2 {
  color: #FFFFFF;
  background-color: #FF6605; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-landing-2:hover, a.badge-landing-2:focus {
    color: #FFFFFF;
    background-color: #d15100; }
  /* line 11, ../node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-landing-2:focus, a.badge-landing-2.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 102, 5, 0.5); }

/* line 1, ../node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    /* line 1, ../node_modules/bootstrap/scss/_jumbotron.scss */
    .jumbotron {
      padding: 4rem 2rem; } }

/* line 13, ../node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

/* line 5, ../node_modules/bootstrap/scss/_alert.scss */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

/* line 14, ../node_modules/bootstrap/scss/_alert.scss */
.alert-heading {
  color: inherit; }

/* line 20, ../node_modules/bootstrap/scss/_alert.scss */
.alert-link {
  font-weight: 700; }

/* line 29, ../node_modules/bootstrap/scss/_alert.scss */
.alert-dismissible {
  padding-right: 4rem; }
  /* line 33, ../node_modules/bootstrap/scss/_alert.scss */
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-primary {
  color: #7e351b;
  background-color: #fde0d6;
  border-color: #fcd4c6; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary hr {
    border-top-color: #fbc2ae; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary .alert-link {
    color: #542312; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-secondary {
  color: #2c2921;
  background-color: #dddcd9;
  border-color: #cfceca; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary hr {
    border-top-color: #c3c2bd; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary .alert-link {
    color: #0f0e0b; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-success {
  color: #004800;
  background-color: #cce8cc;
  border-color: #b8deb8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success hr {
    border-top-color: #a7d6a7; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success .alert-link {
    color: #001500; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-info {
  color: #0b3b41;
  background-color: #d0e3e5;
  border-color: #bdd7db; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info hr {
    border-top-color: #adcdd2; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info .alert-link {
    color: #041315; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-warning {
  color: #77510a;
  background-color: #faebd0;
  border-color: #f7e3bd; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning hr {
    border-top-color: #f4d9a6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning .alert-link {
    color: #483106; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-danger {
  color: #7b001f;
  background-color: #fbccd8;
  border-color: #fab8c8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger hr {
    border-top-color: #f8a0b6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger .alert-link {
    color: #480012; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light hr {
    border-top-color: #ececf6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light .alert-link {
    color: #686868; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark .alert-link {
    color: #040505; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-primary {
  color: #7e351b;
  background-color: #fde0d6;
  border-color: #fcd4c6; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-primary hr {
    border-top-color: #fbc2ae; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-primary .alert-link {
    color: #542312; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-dark-grey {
  color: #2c2921;
  background-color: #dddcd9;
  border-color: #cfceca; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-dark-grey hr {
    border-top-color: #c3c2bd; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-dark-grey .alert-link {
    color: #0f0e0b; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-medium {
  color: #6f6d6b;
  background-color: #f7f6f5;
  border-color: #f3f2f1; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-medium hr {
    border-top-color: #e7e5e3; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-medium .alert-link {
    color: #555452; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-purple {
  color: #62083e;
  background-color: #f2cfe4;
  border-color: #ecbcd9; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-purple hr {
    border-top-color: #e6a8ce; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-purple .alert-link {
    color: #330420; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-red {
  color: #7b001f;
  background-color: #fbccd8;
  border-color: #fab8c8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-red hr {
    border-top-color: #f8a0b6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-red .alert-link {
    color: #480012; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-indigo-blue {
  color: #212549;
  background-color: #d9dae8;
  border-color: #caccdf; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-indigo-blue hr {
    border-top-color: #babdd5; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-indigo-blue .alert-link {
    color: #111326; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-green {
  color: #004800;
  background-color: #cce8cc;
  border-color: #b8deb8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-green hr {
    border-top-color: #a7d6a7; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-green .alert-link {
    color: #001500; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-teal {
  color: #0b3b41;
  background-color: #d0e3e5;
  border-color: #bdd7db; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal hr {
    border-top-color: #adcdd2; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal .alert-link {
    color: #041315; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-yellow {
  color: #77510a;
  background-color: #faebd0;
  border-color: #f7e3bd; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-yellow hr {
    border-top-color: #f4d9a6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-yellow .alert-link {
    color: #483106; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white hr {
    border-top-color: #f2f2f2; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white .alert-link {
    color: #6c6c6c; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-white-85 {
  color: rgba(113, 113, 113, 0.922);
  background-color: rgba(255, 255, 255, 0.97);
  border-color: rgba(255, 255, 255, 0.958); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-85 hr {
    border-top-color: rgba(242, 242, 242, 0.958); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-85 .alert-link {
    color: rgba(88, 88, 88, 0.922); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-white-90 {
  color: rgba(120, 120, 120, 0.948);
  background-color: rgba(255, 255, 255, 0.98);
  border-color: rgba(255, 255, 255, 0.972); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-90 hr {
    border-top-color: rgba(242, 242, 242, 0.972); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-90 .alert-link {
    color: rgba(95, 95, 95, 0.948); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-white-95 {
  color: rgba(126, 126, 126, 0.974);
  background-color: rgba(255, 255, 255, 0.99);
  border-color: rgba(255, 255, 255, 0.986); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-95 hr {
    border-top-color: rgba(242, 242, 242, 0.986); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-white-95 .alert-link {
    color: rgba(101, 101, 101, 0.974); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-grey {
  color: #636362;
  background-color: #f2f2f2;
  border-color: #ededed; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-grey hr {
    border-top-color: #e0e0e0; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-grey .alert-link {
    color: #494949; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-black hr {
    border-top-color: #ababab; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-black .alert-link {
    color: black; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-purple-60 {
  color: rgba(60, 5, 38, 0.792);
  background-color: rgba(249, 232, 242, 0.92);
  border-color: rgba(245, 221, 236, 0.888); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-purple-60 hr {
    border-top-color: rgba(239, 201, 225, 0.888); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-purple-60 .alert-link {
    color: rgba(13, 1, 8, 0.792); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-teal-60 {
  color: rgba(7, 36, 40, 0.792);
  background-color: rgba(232, 241, 242, 0.92);
  border-color: rgba(222, 235, 236, 0.888); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal-60 hr {
    border-top-color: rgba(206, 225, 227, 0.888); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal-60 .alert-link {
    color: rgba(0, 0, 0, 0.792); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-teal-30 {
  color: rgba(3, 18, 20, 0.636);
  background-color: rgba(245, 249, 250, 0.86);
  border-color: rgba(240, 246, 247, 0.804); }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal-30 hr {
    border-top-color: rgba(223, 236, 238, 0.804); }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-teal-30 .alert-link {
    color: rgba(0, 0, 0, 0.636); }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-green-60 {
  color: #356035;
  background-color: #e0f1e0;
  border-color: #d4ebd4; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-green-60 hr {
    border-top-color: #c3e3c3; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-green-60 .alert-link {
    color: #233f23; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-link-blue {
  color: #073d6d;
  background-color: #cfe4f6;
  border-color: #bbd9f2; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-link-blue hr {
    border-top-color: #a6cdee; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-link-blue .alert-link {
    color: #04223d; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-text-black {
  color: #18181f;
  background-color: #d5d5d8;
  border-color: #c4c4c8; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-black hr {
    border-top-color: #b7b7bc; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-black .alert-link {
    color: #020202; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-text-secondary {
  color: #3e3c37;
  background-color: #e4e3e1;
  border-color: #d9d8d5; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-secondary hr {
    border-top-color: #cdccc8; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-secondary .alert-link {
    color: #23221f; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-text-medium {
  color: #46443f;
  background-color: #e7e6e4;
  border-color: #dddcd9; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-medium hr {
    border-top-color: #d1d0cc; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-medium .alert-link {
    color: #2b2a27; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-text-light {
  color: #61605d;
  background-color: #f1f1f0;
  border-color: #ecebea; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-light hr {
    border-top-color: #e0dedd; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-text-light .alert-link {
    color: #474644; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-surface-dark {
  color: #636362;
  background-color: #f2f2f2;
  border-color: #ededed; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-dark hr {
    border-top-color: #e0e0e0; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-dark .alert-link {
    color: #494949; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-surface-medium {
  color: #7c7c7b;
  background-color: #fcfcfb;
  border-color: #fbfbfa; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-medium hr {
    border-top-color: #f0f0ec; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-medium .alert-link {
    color: #626262; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-surface-light {
  color: #828281;
  background-color: #fefefe;
  border-color: #fefefd; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-light hr {
    border-top-color: #f6f6ec; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-surface-light .alert-link {
    color: #686868; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-lama {
  color: #795820;
  background-color: #fbeed8;
  border-color: #f9e7c9; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-lama hr {
    border-top-color: #f6ddb2; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-lama .alert-link {
    color: #513b15; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-laba {
  color: #7f3548;
  background-color: #fde0e8;
  border-color: #fcd4de; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-laba hr {
    border-top-color: #fabccc; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-laba .alert-link {
    color: #5b2634; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-ics {
  color: #073d6d;
  background-color: #cfe4f6;
  border-color: #bbd9f2; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-ics hr {
    border-top-color: #a6cdee; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-ics .alert-link {
    color: #04223d; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-dpi {
  color: #666e29;
  background-color: #f3f6dc;
  border-color: #eff3cd; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-dpi hr {
    border-top-color: #e8eeb8; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-dpi .alert-link {
    color: #44491b; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-fmi {
  color: #325c69;
  background-color: #dfeff4;
  border-color: #d2e9f0; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-fmi hr {
    border-top-color: #bfe0ea; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-fmi .alert-link {
    color: #223e46; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-mdi {
  color: #3c425f;
  background-color: #e3e5f0;
  border-color: #d8dbeb; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-mdi hr {
    border-top-color: #c7cbe2; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-mdi .alert-link {
    color: #282c40; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-lama-laba {
  color: #7f7226;
  background-color: #fdf8db;
  border-color: #fcf5cc; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-lama-laba hr {
    border-top-color: #fbf0b4; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-lama-laba .alert-link {
    color: #584f1a; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-ics-laba {
  color: #356035;
  background-color: #e0f1e0;
  border-color: #d4ebd4; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-ics-laba hr {
    border-top-color: #c3e3c3; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-ics-laba .alert-link {
    color: #233f23; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-gsk-isc-lama-laba {
  color: #481b42;
  background-color: #e8d6e5;
  border-color: #dec6db; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-isc-lama-laba hr {
    border-top-color: #d5b6d1; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-gsk-isc-lama-laba .alert-link {
    color: #230d20; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-aah-red {
  color: #742208;
  background-color: #f9d9cf;
  border-color: #f6cabc; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-aah-red hr {
    border-top-color: #f3b8a6; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-aah-red .alert-link {
    color: #441405; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-landing-1 {
  color: #4b250c;
  background-color: #e9dad1;
  border-color: #e0cbbe; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-landing-1 hr {
    border-top-color: #d8bdad; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-landing-1 .alert-link {
    color: #1f0f05; }

/* line 49, ../node_modules/bootstrap/scss/_alert.scss */
.alert-landing-2 {
  color: #853503;
  background-color: #ffe0cd;
  border-color: #ffd4b9; }
  /* line 6, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-landing-2 hr {
    border-top-color: #ffc4a0; }
  /* line 10, ../node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-landing-2 .alert-link {
    color: #532102; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

/* line 9, ../node_modules/bootstrap/scss/_progress.scss */
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 20, ../node_modules/bootstrap/scss/_progress.scss */
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #F36633;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 20, ../node_modules/bootstrap/scss/_progress.scss */
    .progress-bar {
      transition: none; } }

/* line 32, ../node_modules/bootstrap/scss/_progress.scss */
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

/* line 38, ../node_modules/bootstrap/scss/_progress.scss */
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    /* line 38, ../node_modules/bootstrap/scss/_progress.scss */
    .progress-bar-animated {
      animation: none; } }

/* line 1, ../node_modules/bootstrap/scss/_media.scss */
.media {
  display: flex;
  align-items: flex-start; }

/* line 6, ../node_modules/bootstrap/scss/_media.scss */
.media-body {
  flex: 1; }

/* line 5, ../node_modules/bootstrap/scss/_list-group.scss */
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

/* line 21, ../node_modules/bootstrap/scss/_list-group.scss */
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  /* line 34, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

/* line 45, ../node_modules/bootstrap/scss/_list-group.scss */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 54, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  /* line 58, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  /* line 62, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFFFFF; }
  /* line 70, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #F36633;
    border-color: #F36633; }
  /* line 77, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    /* line 80, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

/* line 96, ../node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal {
  flex-direction: row; }
  /* line 100, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  /* line 105, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  /* line 110, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  /* line 114, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    /* line 118, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  /* line 96, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm {
    flex-direction: row; }
    /* line 100, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 105, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 110, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    /* line 114, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, ../node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  /* line 96, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md {
    flex-direction: row; }
    /* line 100, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 105, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 110, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    /* line 114, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, ../node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  /* line 96, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg {
    flex-direction: row; }
    /* line 100, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 105, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 110, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    /* line 114, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, ../node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  /* line 96, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl {
    flex-direction: row; }
    /* line 100, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 105, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 110, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    /* line 114, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, ../node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

/* line 134, ../node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush {
  border-radius: 0; }
  /* line 137, ../node_modules/bootstrap/scss/_list-group.scss */
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    /* line 140, ../node_modules/bootstrap/scss/_list-group.scss */
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-primary {
  color: #7e351b;
  background-color: #fcd4c6; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #7e351b;
    background-color: #fbc2ae; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7e351b;
    border-color: #7e351b; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-secondary {
  color: #2c2921;
  background-color: #cfceca; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2c2921;
    background-color: #c3c2bd; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2c2921;
    border-color: #2c2921; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-success {
  color: #004800;
  background-color: #b8deb8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #004800;
    background-color: #a7d6a7; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004800;
    border-color: #004800; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-info {
  color: #0b3b41;
  background-color: #bdd7db; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0b3b41;
    background-color: #adcdd2; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #0b3b41;
    border-color: #0b3b41; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-warning {
  color: #77510a;
  background-color: #f7e3bd; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #77510a;
    background-color: #f4d9a6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #77510a;
    border-color: #77510a; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-danger {
  color: #7b001f;
  background-color: #fab8c8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7b001f;
    background-color: #f8a0b6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7b001f;
    border-color: #7b001f; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-primary {
  color: #7e351b;
  background-color: #fcd4c6; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-primary.list-group-item-action:hover, .list-group-item-gsk-primary.list-group-item-action:focus {
    color: #7e351b;
    background-color: #fbc2ae; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7e351b;
    border-color: #7e351b; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-dark-grey {
  color: #2c2921;
  background-color: #cfceca; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-dark-grey.list-group-item-action:hover, .list-group-item-gsk-dark-grey.list-group-item-action:focus {
    color: #2c2921;
    background-color: #c3c2bd; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-dark-grey.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2c2921;
    border-color: #2c2921; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-medium {
  color: #6f6d6b;
  background-color: #f3f2f1; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-medium.list-group-item-action:hover, .list-group-item-gsk-medium.list-group-item-action:focus {
    color: #6f6d6b;
    background-color: #e7e5e3; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-medium.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #6f6d6b;
    border-color: #6f6d6b; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-purple {
  color: #62083e;
  background-color: #ecbcd9; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-purple.list-group-item-action:hover, .list-group-item-gsk-purple.list-group-item-action:focus {
    color: #62083e;
    background-color: #e6a8ce; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-purple.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #62083e;
    border-color: #62083e; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-red {
  color: #7b001f;
  background-color: #fab8c8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-red.list-group-item-action:hover, .list-group-item-gsk-red.list-group-item-action:focus {
    color: #7b001f;
    background-color: #f8a0b6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-red.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7b001f;
    border-color: #7b001f; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-indigo-blue {
  color: #212549;
  background-color: #caccdf; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-indigo-blue.list-group-item-action:hover, .list-group-item-gsk-indigo-blue.list-group-item-action:focus {
    color: #212549;
    background-color: #babdd5; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-indigo-blue.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #212549;
    border-color: #212549; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-green {
  color: #004800;
  background-color: #b8deb8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-green.list-group-item-action:hover, .list-group-item-gsk-green.list-group-item-action:focus {
    color: #004800;
    background-color: #a7d6a7; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-green.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004800;
    border-color: #004800; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-teal {
  color: #0b3b41;
  background-color: #bdd7db; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-teal.list-group-item-action:hover, .list-group-item-gsk-teal.list-group-item-action:focus {
    color: #0b3b41;
    background-color: #adcdd2; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-teal.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #0b3b41;
    border-color: #0b3b41; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-yellow {
  color: #77510a;
  background-color: #f7e3bd; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-yellow.list-group-item-action:hover, .list-group-item-gsk-yellow.list-group-item-action:focus {
    color: #77510a;
    background-color: #f4d9a6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-yellow.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #77510a;
    border-color: #77510a; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-white {
  color: #858585;
  background-color: white; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-white.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #858585;
    border-color: #858585; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-white-85 {
  color: rgba(113, 113, 113, 0.922);
  background-color: rgba(255, 255, 255, 0.958); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-white-85.list-group-item-action:hover, .list-group-item-white-85.list-group-item-action:focus {
    color: rgba(113, 113, 113, 0.922);
    background-color: rgba(242, 242, 242, 0.958); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-white-85.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(113, 113, 113, 0.922);
    border-color: rgba(113, 113, 113, 0.922); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-white-90 {
  color: rgba(120, 120, 120, 0.948);
  background-color: rgba(255, 255, 255, 0.972); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-white-90.list-group-item-action:hover, .list-group-item-white-90.list-group-item-action:focus {
    color: rgba(120, 120, 120, 0.948);
    background-color: rgba(242, 242, 242, 0.972); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-white-90.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(120, 120, 120, 0.948);
    border-color: rgba(120, 120, 120, 0.948); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-white-95 {
  color: rgba(126, 126, 126, 0.974);
  background-color: rgba(255, 255, 255, 0.986); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-white-95.list-group-item-action:hover, .list-group-item-white-95.list-group-item-action:focus {
    color: rgba(126, 126, 126, 0.974);
    background-color: rgba(242, 242, 242, 0.986); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-white-95.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(126, 126, 126, 0.974);
    border-color: rgba(126, 126, 126, 0.974); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-grey {
  color: #636362;
  background-color: #ededed; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
    color: #636362;
    background-color: #e0e0e0; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-grey.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #636362;
    border-color: #636362; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-black {
  color: black;
  background-color: #b8b8b8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-black.list-group-item-action.active {
    color: #FFFFFF;
    background-color: black;
    border-color: black; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-purple-60 {
  color: rgba(60, 5, 38, 0.792);
  background-color: rgba(245, 221, 236, 0.888); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-purple-60.list-group-item-action:hover, .list-group-item-gsk-purple-60.list-group-item-action:focus {
    color: rgba(60, 5, 38, 0.792);
    background-color: rgba(239, 201, 225, 0.888); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-purple-60.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(60, 5, 38, 0.792);
    border-color: rgba(60, 5, 38, 0.792); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-teal-60 {
  color: rgba(7, 36, 40, 0.792);
  background-color: rgba(222, 235, 236, 0.888); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-teal-60.list-group-item-action:hover, .list-group-item-gsk-teal-60.list-group-item-action:focus {
    color: rgba(7, 36, 40, 0.792);
    background-color: rgba(206, 225, 227, 0.888); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-teal-60.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(7, 36, 40, 0.792);
    border-color: rgba(7, 36, 40, 0.792); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-teal-30 {
  color: rgba(3, 18, 20, 0.636);
  background-color: rgba(240, 246, 247, 0.804); }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-teal-30.list-group-item-action:hover, .list-group-item-gsk-teal-30.list-group-item-action:focus {
    color: rgba(3, 18, 20, 0.636);
    background-color: rgba(223, 236, 238, 0.804); }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-teal-30.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(3, 18, 20, 0.636);
    border-color: rgba(3, 18, 20, 0.636); }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-green-60 {
  color: #356035;
  background-color: #d4ebd4; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-green-60.list-group-item-action:hover, .list-group-item-gsk-green-60.list-group-item-action:focus {
    color: #356035;
    background-color: #c3e3c3; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-green-60.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #356035;
    border-color: #356035; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-link-blue {
  color: #073d6d;
  background-color: #bbd9f2; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-link-blue.list-group-item-action:hover, .list-group-item-link-blue.list-group-item-action:focus {
    color: #073d6d;
    background-color: #a6cdee; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-link-blue.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #073d6d;
    border-color: #073d6d; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-text-black {
  color: #18181f;
  background-color: #c4c4c8; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-text-black.list-group-item-action:hover, .list-group-item-text-black.list-group-item-action:focus {
    color: #18181f;
    background-color: #b7b7bc; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-text-black.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #18181f;
    border-color: #18181f; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-text-secondary {
  color: #3e3c37;
  background-color: #d9d8d5; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-text-secondary.list-group-item-action:hover, .list-group-item-text-secondary.list-group-item-action:focus {
    color: #3e3c37;
    background-color: #cdccc8; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-text-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #3e3c37;
    border-color: #3e3c37; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-text-medium {
  color: #46443f;
  background-color: #dddcd9; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-text-medium.list-group-item-action:hover, .list-group-item-text-medium.list-group-item-action:focus {
    color: #46443f;
    background-color: #d1d0cc; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-text-medium.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #46443f;
    border-color: #46443f; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-text-light {
  color: #61605d;
  background-color: #ecebea; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-text-light.list-group-item-action:hover, .list-group-item-text-light.list-group-item-action:focus {
    color: #61605d;
    background-color: #e0dedd; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-text-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #61605d;
    border-color: #61605d; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-surface-dark {
  color: #636362;
  background-color: #ededed; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-surface-dark.list-group-item-action:hover, .list-group-item-surface-dark.list-group-item-action:focus {
    color: #636362;
    background-color: #e0e0e0; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-surface-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #636362;
    border-color: #636362; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-surface-medium {
  color: #7c7c7b;
  background-color: #fbfbfa; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-surface-medium.list-group-item-action:hover, .list-group-item-surface-medium.list-group-item-action:focus {
    color: #7c7c7b;
    background-color: #f0f0ec; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-surface-medium.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7c7c7b;
    border-color: #7c7c7b; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-surface-light {
  color: #828281;
  background-color: #fefefd; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-surface-light.list-group-item-action:hover, .list-group-item-surface-light.list-group-item-action:focus {
    color: #828281;
    background-color: #f6f6ec; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-surface-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #828281;
    border-color: #828281; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-lama {
  color: #795820;
  background-color: #f9e7c9; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-lama.list-group-item-action:hover, .list-group-item-gsk-lama.list-group-item-action:focus {
    color: #795820;
    background-color: #f6ddb2; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-lama.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #795820;
    border-color: #795820; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-laba {
  color: #7f3548;
  background-color: #fcd4de; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-laba.list-group-item-action:hover, .list-group-item-gsk-laba.list-group-item-action:focus {
    color: #7f3548;
    background-color: #fabccc; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-laba.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f3548;
    border-color: #7f3548; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-ics {
  color: #073d6d;
  background-color: #bbd9f2; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-ics.list-group-item-action:hover, .list-group-item-gsk-ics.list-group-item-action:focus {
    color: #073d6d;
    background-color: #a6cdee; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-ics.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #073d6d;
    border-color: #073d6d; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-dpi {
  color: #666e29;
  background-color: #eff3cd; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-dpi.list-group-item-action:hover, .list-group-item-gsk-dpi.list-group-item-action:focus {
    color: #666e29;
    background-color: #e8eeb8; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-dpi.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #666e29;
    border-color: #666e29; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-fmi {
  color: #325c69;
  background-color: #d2e9f0; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-fmi.list-group-item-action:hover, .list-group-item-gsk-fmi.list-group-item-action:focus {
    color: #325c69;
    background-color: #bfe0ea; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-fmi.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #325c69;
    border-color: #325c69; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-mdi {
  color: #3c425f;
  background-color: #d8dbeb; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-mdi.list-group-item-action:hover, .list-group-item-gsk-mdi.list-group-item-action:focus {
    color: #3c425f;
    background-color: #c7cbe2; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-mdi.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #3c425f;
    border-color: #3c425f; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-lama-laba {
  color: #7f7226;
  background-color: #fcf5cc; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-lama-laba.list-group-item-action:hover, .list-group-item-gsk-lama-laba.list-group-item-action:focus {
    color: #7f7226;
    background-color: #fbf0b4; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-lama-laba.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f7226;
    border-color: #7f7226; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-ics-laba {
  color: #356035;
  background-color: #d4ebd4; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-ics-laba.list-group-item-action:hover, .list-group-item-gsk-ics-laba.list-group-item-action:focus {
    color: #356035;
    background-color: #c3e3c3; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-ics-laba.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #356035;
    border-color: #356035; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-gsk-isc-lama-laba {
  color: #481b42;
  background-color: #dec6db; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-gsk-isc-lama-laba.list-group-item-action:hover, .list-group-item-gsk-isc-lama-laba.list-group-item-action:focus {
    color: #481b42;
    background-color: #d5b6d1; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-gsk-isc-lama-laba.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #481b42;
    border-color: #481b42; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-aah-red {
  color: #742208;
  background-color: #f6cabc; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-aah-red.list-group-item-action:hover, .list-group-item-aah-red.list-group-item-action:focus {
    color: #742208;
    background-color: #f3b8a6; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-aah-red.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #742208;
    border-color: #742208; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-landing-1 {
  color: #4b250c;
  background-color: #e0cbbe; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-landing-1.list-group-item-action:hover, .list-group-item-landing-1.list-group-item-action:focus {
    color: #4b250c;
    background-color: #d8bdad; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-landing-1.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #4b250c;
    border-color: #4b250c; }

/* line 4, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-landing-2 {
  color: #853503;
  background-color: #ffd4b9; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-landing-2.list-group-item-action:hover, .list-group-item-landing-2.list-group-item-action:focus {
    color: #853503;
    background-color: #ffc4a0; }
  /* line 14, ../node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-landing-2.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #853503;
    border-color: #853503; }

/* line 1, ../node_modules/bootstrap/scss/_close.scss */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  /* line 13, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:hover {
    color: #000000;
    text-decoration: none; }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

/* line 29, ../node_modules/bootstrap/scss/_close.scss */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

/* line 38, ../node_modules/bootstrap/scss/_close.scss */
a.close.disabled {
  pointer-events: none; }

/* line 1, ../node_modules/bootstrap/scss/_toasts.scss */
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  /* line 15, ../node_modules/bootstrap/scss/_toasts.scss */
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  /* line 19, ../node_modules/bootstrap/scss/_toasts.scss */
  .toast.showing {
    opacity: 1; }
  /* line 23, ../node_modules/bootstrap/scss/_toasts.scss */
  .toast.show {
    display: block;
    opacity: 1; }
  /* line 28, ../node_modules/bootstrap/scss/_toasts.scss */
  .toast.hide {
    display: none; }

/* line 33, ../node_modules/bootstrap/scss/_toasts.scss */
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

/* line 44, ../node_modules/bootstrap/scss/_toasts.scss */
.toast-body {
  padding: 0.75rem; }

/* line 7, ../node_modules/bootstrap/scss/_modal.scss */
.modal-open {
  overflow: hidden; }
  /* line 11, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

/* line 18, ../node_modules/bootstrap/scss/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

/* line 36, ../node_modules/bootstrap/scss/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  /* line 44, ../node_modules/bootstrap/scss/_modal.scss */
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      /* line 44, ../node_modules/bootstrap/scss/_modal.scss */
      .modal.fade .modal-dialog {
        transition: none; } }
  /* line 48, ../node_modules/bootstrap/scss/_modal.scss */
  .modal.show .modal-dialog {
    transform: none; }
  /* line 53, ../node_modules/bootstrap/scss/_modal.scss */
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

/* line 58, ../node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  /* line 62, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  /* line 67, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  /* line 72, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

/* line 77, ../node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  /* line 83, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  /* line 91, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    /* line 96, ../node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    /* line 100, ../node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

/* line 107, ../node_modules/bootstrap/scss/_modal.scss */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

/* line 125, ../node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  /* line 135, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.fade {
    opacity: 0; }
  /* line 136, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.show {
    opacity: 0.5; }

/* line 141, ../node_modules/bootstrap/scss/_modal.scss */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 149, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

/* line 157, ../node_modules/bootstrap/scss/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

/* line 164, ../node_modules/bootstrap/scss/_modal.scss */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

/* line 173, ../node_modules/bootstrap/scss/_modal.scss */
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  /* line 185, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-footer > * {
    margin: 0.25rem; }

/* line 191, ../node_modules/bootstrap/scss/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  /* line 202, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  /* line 207, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    /* line 210, ../node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  /* line 215, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    /* line 218, ../node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  /* line 228, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  /* line 232, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  /* line 239, ../node_modules/bootstrap/scss/_modal.scss */
  .modal-xl {
    max-width: 1140px; } }

/* line 2, ../node_modules/bootstrap/scss/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  /* line 15, ../node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip.show {
    opacity: 0.9; }
  /* line 17, ../node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    /* line 23, ../node_modules/bootstrap/scss/_tooltip.scss */
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 32, ../node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  /* line 35, ../node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    /* line 38, ../node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #544F40; }

/* line 46, ../node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  /* line 49, ../node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 54, ../node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #544F40; }

/* line 62, ../node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  /* line 65, ../node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    /* line 68, ../node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #544F40; }

/* line 76, ../node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  /* line 79, ../node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 84, ../node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #544F40; }

/* line 108, ../node_modules/bootstrap/scss/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #544F40;
  border-radius: 0.25rem; }

/* line 1, ../node_modules/bootstrap/scss/_popover.scss */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  /* line 20, ../node_modules/bootstrap/scss/_popover.scss */
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    /* line 27, ../node_modules/bootstrap/scss/_popover.scss */
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 38, ../node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  /* line 41, ../node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    /* line 44, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    /* line 50, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFFFFF; }

/* line 58, ../node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  /* line 61, ../node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 67, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    /* line 73, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFFFFF; }

/* line 81, ../node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  /* line 84, ../node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    /* line 87, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    /* line 93, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFFFFF; }
  /* line 101, ../node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

/* line 113, ../node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  /* line 116, ../node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 122, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    /* line 128, ../node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFFFFF; }

/* line 153, ../node_modules/bootstrap/scss/_popover.scss */
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 162, ../node_modules/bootstrap/scss/_popover.scss */
  .popover-header:empty {
    display: none; }

/* line 167, ../node_modules/bootstrap/scss/_popover.scss */
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

/* line 14, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel {
  position: relative; }

/* line 18, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel.pointer-event {
  touch-action: pan-y; }

/* line 22, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  /* line 2, ../node_modules/bootstrap/scss/mixins/_clearfix.scss */
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

/* line 29, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 29, ../node_modules/bootstrap/scss/_carousel.scss */
    .carousel-item {
      transition: none; } }

/* line 39, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* line 45, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

/* line 50, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

/* line 61, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

/* line 67, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

/* line 74, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    /* line 74, ../node_modules/bootstrap/scss/_carousel.scss */
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

/* line 87, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 87, ../node_modules/bootstrap/scss/_carousel.scss */
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  /* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

/* line 114, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev {
  left: 0; }

/* line 120, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next {
  right: 0; }

/* line 128, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

/* line 135, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

/* line 138, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

/* line 148, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  /* line 162, ../node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      /* line 162, ../node_modules/bootstrap/scss/_carousel.scss */
      .carousel-indicators li {
        transition: none; } }
  /* line 180, ../node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators .active {
    opacity: 1; }

/* line 190, ../node_modules/bootstrap/scss/_carousel.scss */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

/* line 9, ../node_modules/bootstrap/scss/_spinners.scss */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

/* line 21, ../node_modules/bootstrap/scss/_spinners.scss */
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

/* line 41, ../node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

/* line 53, ../node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  /* line 60, ../node_modules/bootstrap/scss/_spinners.scss */
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

/* line 3, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important; }

/* line 6, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important; }

/* line 8, ../node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #F36633 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #e6470e !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #544F40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #37342a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #008A00 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005700 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #15717D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0e4a51 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #E49B13 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b57b0f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #ED003C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ba002f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #f8f9fa !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #343a40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-primary {
  background-color: #F36633 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-primary:hover, a.bg-gsk-primary:focus,
button.bg-gsk-primary:hover,
button.bg-gsk-primary:focus {
  background-color: #e6470e !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-dark-grey {
  background-color: #544F40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-dark-grey:hover, a.bg-gsk-dark-grey:focus,
button.bg-gsk-dark-grey:hover,
button.bg-gsk-dark-grey:focus {
  background-color: #37342a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-medium {
  background-color: #D5D1CE !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-medium:hover, a.bg-gsk-medium:focus,
button.bg-gsk-medium:hover,
button.bg-gsk-medium:focus {
  background-color: #bdb7b3 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-purple {
  background-color: #BC1077 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-purple:hover, a.bg-gsk-purple:focus,
button.bg-gsk-purple:hover,
button.bg-gsk-purple:focus {
  background-color: #8d0c59 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-red {
  background-color: #ED003C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-red:hover, a.bg-gsk-red:focus,
button.bg-gsk-red:hover,
button.bg-gsk-red:focus {
  background-color: #ba002f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-indigo-blue {
  background-color: #40488D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-indigo-blue:hover, a.bg-gsk-indigo-blue:focus,
button.bg-gsk-indigo-blue:hover,
button.bg-gsk-indigo-blue:focus {
  background-color: #30366a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-green {
  background-color: #008A00 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-green:hover, a.bg-gsk-green:focus,
button.bg-gsk-green:hover,
button.bg-gsk-green:focus {
  background-color: #005700 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-teal {
  background-color: #15717D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-teal:hover, a.bg-gsk-teal:focus,
button.bg-gsk-teal:hover,
button.bg-gsk-teal:focus {
  background-color: #0e4a51 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-yellow {
  background-color: #E49B13 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-yellow:hover, a.bg-gsk-yellow:focus,
button.bg-gsk-yellow:hover,
button.bg-gsk-yellow:focus {
  background-color: #b57b0f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-white {
  background-color: #FFFFFF !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-white-85 {
  background-color: rgba(255, 255, 255, 0.85) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-white-85:hover, a.bg-white-85:focus,
button.bg-white-85:hover,
button.bg-white-85:focus {
  background-color: rgba(230, 230, 230, 0.85) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-white-90:hover, a.bg-white-90:focus,
button.bg-white-90:hover,
button.bg-white-90:focus {
  background-color: rgba(230, 230, 230, 0.9) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-white-95 {
  background-color: rgba(255, 255, 255, 0.95) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-white-95:hover, a.bg-white-95:focus,
button.bg-white-95:hover,
button.bg-white-95:focus {
  background-color: rgba(230, 230, 230, 0.95) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-grey {
  background-color: #BEBEBD !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #a5a5a3 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-black {
  background-color: #000000 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-purple-60 {
  background-color: rgba(188, 16, 119, 0.6) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-purple-60:hover, a.bg-gsk-purple-60:focus,
button.bg-gsk-purple-60:hover,
button.bg-gsk-purple-60:focus {
  background-color: rgba(141, 12, 89, 0.6) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-teal-60 {
  background-color: rgba(21, 113, 125, 0.6) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-teal-60:hover, a.bg-gsk-teal-60:focus,
button.bg-gsk-teal-60:hover,
button.bg-gsk-teal-60:focus {
  background-color: rgba(14, 74, 81, 0.6) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-teal-30 {
  background-color: rgba(21, 113, 125, 0.3) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-teal-30:hover, a.bg-gsk-teal-30:focus,
button.bg-gsk-teal-30:hover,
button.bg-gsk-teal-30:focus {
  background-color: rgba(14, 74, 81, 0.3) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-green-60 {
  background-color: #66B966 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-green-60:hover, a.bg-gsk-green-60:focus,
button.bg-gsk-green-60:hover,
button.bg-gsk-green-60:focus {
  background-color: #4aa24a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-link-blue {
  background-color: #0D76D1 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-link-blue:hover, a.bg-link-blue:focus,
button.bg-link-blue:hover,
button.bg-link-blue:focus {
  background-color: #0a5ba1 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-text-black {
  background-color: #2E2E3C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-text-black:hover, a.bg-text-black:focus,
button.bg-text-black:hover,
button.bg-text-black:focus {
  background-color: #18181f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-text-secondary {
  background-color: #777369 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-text-secondary:hover, a.bg-text-secondary:focus,
button.bg-text-secondary:hover,
button.bg-text-secondary:focus {
  background-color: #5c5951 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-text-medium {
  background-color: #878379 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-text-medium:hover, a.bg-text-medium:focus,
button.bg-text-medium:hover,
button.bg-text-medium:focus {
  background-color: #6c6961 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-text-light {
  background-color: #BBB9B3 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-text-light:hover, a.bg-text-light:focus,
button.bg-text-light:hover,
button.bg-text-light:focus {
  background-color: #a3a098 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-surface-dark {
  background-color: #BEBEBD !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-surface-dark:hover, a.bg-surface-dark:focus,
button.bg-surface-dark:hover,
button.bg-surface-dark:focus {
  background-color: #a5a5a3 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-surface-medium {
  background-color: #EFEFED !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-surface-medium:hover, a.bg-surface-medium:focus,
button.bg-surface-medium:hover,
button.bg-surface-medium:focus {
  background-color: #d7d7d2 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-surface-light {
  background-color: #FAFAF8 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-surface-light:hover, a.bg-surface-light:focus,
button.bg-surface-light:hover,
button.bg-surface-light:focus {
  background-color: #e5e5da !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-lama {
  background-color: #E9A93D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-lama:hover, a.bg-gsk-lama:focus,
button.bg-gsk-lama:hover,
button.bg-gsk-lama:focus {
  background-color: #da9219 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-laba {
  background-color: #F4668A !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-laba:hover, a.bg-gsk-laba:focus,
button.bg-gsk-laba:hover,
button.bg-gsk-laba:focus {
  background-color: #f13666 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-ics {
  background-color: #0D76D1 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-ics:hover, a.bg-gsk-ics:focus,
button.bg-gsk-ics:hover,
button.bg-gsk-ics:focus {
  background-color: #0a5ba1 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-dpi {
  background-color: #C5D34E !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-dpi:hover, a.bg-gsk-dpi:focus,
button.bg-gsk-dpi:hover,
button.bg-gsk-dpi:focus {
  background-color: #b0bf2f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-fmi {
  background-color: #60B0C9 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-fmi:hover, a.bg-gsk-fmi:focus,
button.bg-gsk-fmi:hover,
button.bg-gsk-fmi:focus {
  background-color: #3e9bb8 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-mdi {
  background-color: #747EB6 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-mdi:hover, a.bg-gsk-mdi:focus,
button.bg-gsk-mdi:hover,
button.bg-gsk-mdi:focus {
  background-color: #5561a2 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-lama-laba {
  background-color: #F5DB4A !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-lama-laba:hover, a.bg-gsk-lama-laba:focus,
button.bg-gsk-lama-laba:hover,
button.bg-gsk-lama-laba:focus {
  background-color: #f2d11a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-ics-laba {
  background-color: #66B966 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-ics-laba:hover, a.bg-gsk-ics-laba:focus,
button.bg-gsk-ics-laba:hover,
button.bg-gsk-ics-laba:focus {
  background-color: #4aa24a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-gsk-isc-lama-laba {
  background-color: #8A347F !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-gsk-isc-lama-laba:hover, a.bg-gsk-isc-lama-laba:focus,
button.bg-gsk-isc-lama-laba:hover,
button.bg-gsk-isc-lama-laba:focus {
  background-color: #65265d !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-aah-red {
  background-color: #DF410F !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-aah-red:hover, a.bg-aah-red:focus,
button.bg-aah-red:hover,
button.bg-aah-red:focus {
  background-color: #af330c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-landing-1 {
  background-color: #914718 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-landing-1:hover, a.bg-landing-1:focus,
button.bg-landing-1:hover,
button.bg-landing-1:focus {
  background-color: #653211 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-landing-2 {
  background-color: #FF6605 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-landing-2:hover, a.bg-landing-2:focus,
button.bg-landing-2:hover,
button.bg-landing-2:focus {
  background-color: #d15100 !important; }

/* line 13, ../node_modules/bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #FFFFFF !important; }

/* line 17, ../node_modules/bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid #dee2e6 !important; }

/* line 8, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #dee2e6 !important; }

/* line 9, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #dee2e6 !important; }

/* line 10, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #dee2e6 !important; }

/* line 13, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important; }

/* line 14, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 15, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 17, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #F36633 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #544F40 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #008A00 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #15717D !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #E49B13 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #ED003C !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #f8f9fa !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #343a40 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-primary {
  border-color: #F36633 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-dark-grey {
  border-color: #544F40 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-medium {
  border-color: #D5D1CE !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-purple {
  border-color: #BC1077 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-red {
  border-color: #ED003C !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-indigo-blue {
  border-color: #40488D !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-green {
  border-color: #008A00 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-teal {
  border-color: #15717D !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-yellow {
  border-color: #E49B13 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #FFFFFF !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white-85 {
  border-color: rgba(255, 255, 255, 0.85) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white-90 {
  border-color: rgba(255, 255, 255, 0.9) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white-95 {
  border-color: rgba(255, 255, 255, 0.95) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-grey {
  border-color: #BEBEBD !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-black {
  border-color: #000000 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-purple-60 {
  border-color: rgba(188, 16, 119, 0.6) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-teal-60 {
  border-color: rgba(21, 113, 125, 0.6) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-teal-30 {
  border-color: rgba(21, 113, 125, 0.3) !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-green-60 {
  border-color: #66B966 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-link-blue {
  border-color: #0D76D1 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-text-black {
  border-color: #2E2E3C !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-text-secondary {
  border-color: #777369 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-text-medium {
  border-color: #878379 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-text-light {
  border-color: #BBB9B3 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-surface-dark {
  border-color: #BEBEBD !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-surface-medium {
  border-color: #EFEFED !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-surface-light {
  border-color: #FAFAF8 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-lama {
  border-color: #E9A93D !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-laba {
  border-color: #F4668A !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-ics {
  border-color: #0D76D1 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-dpi {
  border-color: #C5D34E !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-fmi {
  border-color: #60B0C9 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-mdi {
  border-color: #747EB6 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-lama-laba {
  border-color: #F5DB4A !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-ics-laba {
  border-color: #66B966 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-gsk-isc-lama-laba {
  border-color: #8A347F !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-aah-red {
  border-color: #DF410F !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-landing-1 {
  border-color: #914718 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-landing-2 {
  border-color: #FF6605 !important; }

/* line 25, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #FFFFFF !important; }

/* line 33, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-sm {
  border-radius: 0.2rem !important; }

/* line 37, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important; }

/* line 41, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

/* line 46, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

/* line 51, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 56, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 61, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-lg {
  border-radius: 0.3rem !important; }

/* line 65, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important; }

/* line 69, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important; }

/* line 73, ../node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important; }

/* line 2, ../node_modules/bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 3, ../node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  /* line 10, ../node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive::before {
    display: block;
    content: ""; }
  /* line 15, ../node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 19, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-fill {
  flex: 1 1 auto !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-0 {
  flex-grow: 0 !important; }

/* line 21, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-1 {
  flex-grow: 1 !important; }

/* line 22, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-0 {
  flex-shrink: 0 !important; }

/* line 23, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-1 {
  flex-shrink: 1 !important; }

/* line 25, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 26, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 27, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 28, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 29, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 31, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 32, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 33, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 37, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 38, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 40, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 41, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 42, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 44, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 45, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 46, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 48, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 49, ../node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 26, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 27, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 28, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 29, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 31, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 32, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 33, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 37, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 38, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 40, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 41, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 42, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 44, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 45, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 46, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 48, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 49, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 26, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 27, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 28, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 29, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 31, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 32, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 33, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 37, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 38, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 40, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 41, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 42, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 44, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 45, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 46, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 48, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 49, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 26, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 27, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 28, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 29, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 31, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 32, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 33, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 37, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 38, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 40, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 41, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 42, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 44, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 45, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 46, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 48, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 49, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 26, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 27, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 28, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 29, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 31, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 32, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 33, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 37, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 38, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 40, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 41, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 42, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 44, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 45, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 46, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 48, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 49, ../node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* line 7, ../node_modules/bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important; }

/* line 8, ../node_modules/bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important; }

/* line 9, ../node_modules/bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important; }

@media (min-width: 576px) {
  /* line 7, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important; }
  /* line 8, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important; }
  /* line 9, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  /* line 7, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important; }
  /* line 8, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important; }
  /* line 9, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  /* line 7, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important; }
  /* line 8, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important; }
  /* line 9, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  /* line 7, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important; }
  /* line 8, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important; }
  /* line 9, ../node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important; } }

/* line 4, ../node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-all {
  user-select: all !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-auto {
  user-select: auto !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-none {
  user-select: none !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: sticky !important; }

/* line 10, ../node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

/* line 18, ../node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  /* line 26, ../node_modules/bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

/* line 5, ../node_modules/bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* line 25, ../node_modules/bootstrap/scss/mixins/_screen-reader.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* line 3, ../node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

/* line 4, ../node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

/* line 5, ../node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

/* line 6, ../node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-none {
  box-shadow: none !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-auto {
  width: auto !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-auto {
  height: auto !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important; }

/* line 17, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important; }

/* line 19, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 8px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 8px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 8px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 8px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 8px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 16px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 16px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 16px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 16px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 16px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 24px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 24px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 24px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 24px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 24px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 32px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 32px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 32px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 32px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 32px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 40px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 40px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 40px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 40px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 40px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0_5 {
  margin: 4px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0_5,
.my-0_5 {
  margin-top: 4px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0_5,
.mx-0_5 {
  margin-right: 4px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0_5,
.my-0_5 {
  margin-bottom: 4px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0_5,
.mx-0_5 {
  margin-left: 4px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1_5 {
  margin: 12px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1_5,
.my-1_5 {
  margin-top: 12px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1_5,
.mx-1_5 {
  margin-right: 12px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1_5,
.my-1_5 {
  margin-bottom: 12px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1_5,
.mx-1_5 {
  margin-left: 12px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-6 {
  margin: 48px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-6,
.my-6 {
  margin-top: 48px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-6,
.mx-6 {
  margin-right: 48px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-6,
.my-6 {
  margin-bottom: 48px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-6,
.mx-6 {
  margin-left: 48px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-7 {
  margin: 56px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-7,
.my-7 {
  margin-top: 56px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-7,
.mx-7 {
  margin-right: 56px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-7,
.my-7 {
  margin-bottom: 56px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-7,
.mx-7 {
  margin-left: 56px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-8 {
  margin: 64px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-8,
.my-8 {
  margin-top: 64px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-8,
.mx-8 {
  margin-right: 64px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-8,
.my-8 {
  margin-bottom: 64px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-8,
.mx-8 {
  margin-left: 64px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-9 {
  margin: 72px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-9,
.my-9 {
  margin-top: 72px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-9,
.mx-9 {
  margin-right: 72px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-9,
.my-9 {
  margin-bottom: 72px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-9,
.mx-9 {
  margin-left: 72px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-10 {
  margin: 80px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-10,
.my-10 {
  margin-top: 80px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-10,
.mx-10 {
  margin-right: 80px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-10,
.my-10 {
  margin-bottom: 80px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-10,
.mx-10 {
  margin-left: 80px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 8px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 8px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 8px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 8px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 8px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 16px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 16px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 16px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 16px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 16px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 24px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 24px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 24px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 24px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 24px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 32px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 32px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 32px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 32px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 32px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 40px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 40px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 40px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 40px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 40px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0_5 {
  padding: 4px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0_5,
.py-0_5 {
  padding-top: 4px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0_5,
.px-0_5 {
  padding-right: 4px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0_5,
.py-0_5 {
  padding-bottom: 4px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0_5,
.px-0_5 {
  padding-left: 4px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1_5 {
  padding: 12px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1_5,
.py-1_5 {
  padding-top: 12px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1_5,
.px-1_5 {
  padding-right: 12px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1_5,
.py-1_5 {
  padding-bottom: 12px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1_5,
.px-1_5 {
  padding-left: 12px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-6 {
  padding: 48px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-6,
.py-6 {
  padding-top: 48px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-6,
.px-6 {
  padding-right: 48px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-6,
.py-6 {
  padding-bottom: 48px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-6,
.px-6 {
  padding-left: 48px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-7 {
  padding: 56px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-7,
.py-7 {
  padding-top: 56px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-7,
.px-7 {
  padding-right: 56px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-7,
.py-7 {
  padding-bottom: 56px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-7,
.px-7 {
  padding-left: 56px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-8 {
  padding: 64px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-8,
.py-8 {
  padding-top: 64px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-8,
.px-8 {
  padding-right: 64px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-8,
.py-8 {
  padding-bottom: 64px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-8,
.px-8 {
  padding-left: 64px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-9 {
  padding: 72px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-9,
.py-9 {
  padding-top: 72px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-9,
.px-9 {
  padding-right: 72px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-9,
.py-9 {
  padding-bottom: 72px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-9,
.px-9 {
  padding-left: 72px !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-10 {
  padding: 80px !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-10,
.py-10 {
  padding-top: 80px !important; }

/* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-10,
.px-10 {
  padding-right: 80px !important; }

/* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-10,
.py-10 {
  padding-bottom: 80px !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-10,
.px-10 {
  padding-left: 80px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1 {
  margin: -8px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -8px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -8px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -8px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -8px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n2 {
  margin: -16px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -16px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -16px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -16px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -16px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n3 {
  margin: -24px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -24px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -24px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -24px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -24px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n4 {
  margin: -32px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -32px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -32px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -32px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -32px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n5 {
  margin: -40px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -40px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -40px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -40px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -40px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n0_5 {
  margin: -4px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n0_5,
.my-n0_5 {
  margin-top: -4px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n0_5,
.mx-n0_5 {
  margin-right: -4px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n0_5,
.my-n0_5 {
  margin-bottom: -4px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n0_5,
.mx-n0_5 {
  margin-left: -4px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1_5 {
  margin: -12px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1_5,
.my-n1_5 {
  margin-top: -12px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1_5,
.mx-n1_5 {
  margin-right: -12px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1_5,
.my-n1_5 {
  margin-bottom: -12px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1_5,
.mx-n1_5 {
  margin-left: -12px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n6 {
  margin: -48px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n6,
.my-n6 {
  margin-top: -48px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n6,
.mx-n6 {
  margin-right: -48px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n6,
.my-n6 {
  margin-bottom: -48px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n6,
.mx-n6 {
  margin-left: -48px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n7 {
  margin: -56px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n7,
.my-n7 {
  margin-top: -56px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n7,
.mx-n7 {
  margin-right: -56px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n7,
.my-n7 {
  margin-bottom: -56px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n7,
.mx-n7 {
  margin-left: -56px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n8 {
  margin: -64px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n8,
.my-n8 {
  margin-top: -64px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n8,
.mx-n8 {
  margin-right: -64px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n8,
.my-n8 {
  margin-bottom: -64px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n8,
.mx-n8 {
  margin-left: -64px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n9 {
  margin: -72px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n9,
.my-n9 {
  margin-top: -72px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n9,
.mx-n9 {
  margin-right: -72px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n9,
.my-n9 {
  margin-bottom: -72px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n9,
.mx-n9 {
  margin-left: -72px !important; }

/* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n10 {
  margin: -80px !important; }

/* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n10,
.my-n10 {
  margin-top: -80px !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n10,
.mx-n10 {
  margin-right: -80px !important; }

/* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n10,
.my-n10 {
  margin-bottom: -80px !important; }

/* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n10,
.mx-n10 {
  margin-left: -80px !important; }

/* line 55, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important; }

/* line 56, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important; }

/* line 60, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important; }

/* line 64, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

/* line 68, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0_5 {
    margin: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0_5,
  .my-sm-0_5 {
    margin-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0_5,
  .mx-sm-0_5 {
    margin-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0_5,
  .my-sm-0_5 {
    margin-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0_5,
  .mx-sm-0_5 {
    margin-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1_5 {
    margin: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1_5,
  .my-sm-1_5 {
    margin-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1_5,
  .mx-sm-1_5 {
    margin-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1_5,
  .my-sm-1_5 {
    margin-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1_5,
  .mx-sm-1_5 {
    margin-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-6 {
    margin: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-7 {
    margin: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-8 {
    margin: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-9 {
    margin: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-10 {
    margin: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 80px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0_5 {
    padding: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0_5,
  .py-sm-0_5 {
    padding-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0_5,
  .px-sm-0_5 {
    padding-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0_5,
  .py-sm-0_5 {
    padding-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0_5,
  .px-sm-0_5 {
    padding-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1_5 {
    padding: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1_5,
  .py-sm-1_5 {
    padding-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1_5,
  .px-sm-1_5 {
    padding-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1_5,
  .py-sm-1_5 {
    padding-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1_5,
  .px-sm-1_5 {
    padding-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-6 {
    padding: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-7 {
    padding: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-8 {
    padding: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-9 {
    padding: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-10 {
    padding: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 80px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -8px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -8px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -8px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -8px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -8px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -16px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -16px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -16px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -16px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -16px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -24px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -24px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -24px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -24px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -24px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -32px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -32px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -32px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -32px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -32px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -40px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -40px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -40px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -40px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -40px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n0_5 {
    margin: -4px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n0_5,
  .my-sm-n0_5 {
    margin-top: -4px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n0_5,
  .mx-sm-n0_5 {
    margin-right: -4px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n0_5,
  .my-sm-n0_5 {
    margin-bottom: -4px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n0_5,
  .mx-sm-n0_5 {
    margin-left: -4px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1_5 {
    margin: -12px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1_5,
  .my-sm-n1_5 {
    margin-top: -12px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1_5,
  .mx-sm-n1_5 {
    margin-right: -12px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1_5,
  .my-sm-n1_5 {
    margin-bottom: -12px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1_5,
  .mx-sm-n1_5 {
    margin-left: -12px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n6 {
    margin: -48px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -48px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -48px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -48px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -48px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n7 {
    margin: -56px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -56px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -56px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -56px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -56px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n8 {
    margin: -64px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -64px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -64px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -64px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -64px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n9 {
    margin: -72px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -72px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -72px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -72px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -72px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n10 {
    margin: -80px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -80px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -80px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -80px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -80px !important; }
  /* line 55, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important; }
  /* line 56, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  /* line 60, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  /* line 64, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  /* line 68, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0_5 {
    margin: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0_5,
  .my-md-0_5 {
    margin-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0_5,
  .mx-md-0_5 {
    margin-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0_5,
  .my-md-0_5 {
    margin-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0_5,
  .mx-md-0_5 {
    margin-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1_5 {
    margin: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1_5,
  .my-md-1_5 {
    margin-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1_5,
  .mx-md-1_5 {
    margin-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1_5,
  .my-md-1_5 {
    margin-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1_5,
  .mx-md-1_5 {
    margin-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-6 {
    margin: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-6,
  .my-md-6 {
    margin-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-6,
  .mx-md-6 {
    margin-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-6,
  .mx-md-6 {
    margin-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-7 {
    margin: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-7,
  .my-md-7 {
    margin-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-7,
  .mx-md-7 {
    margin-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-7,
  .mx-md-7 {
    margin-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-8 {
    margin: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-8,
  .my-md-8 {
    margin-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-8,
  .mx-md-8 {
    margin-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-8,
  .mx-md-8 {
    margin-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-9 {
    margin: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-9,
  .my-md-9 {
    margin-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-9,
  .mx-md-9 {
    margin-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-9,
  .mx-md-9 {
    margin-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-10 {
    margin: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-10,
  .my-md-10 {
    margin-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-10,
  .mx-md-10 {
    margin-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-10,
  .mx-md-10 {
    margin-left: 80px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0_5 {
    padding: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0_5,
  .py-md-0_5 {
    padding-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0_5,
  .px-md-0_5 {
    padding-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0_5,
  .py-md-0_5 {
    padding-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0_5,
  .px-md-0_5 {
    padding-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1_5 {
    padding: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1_5,
  .py-md-1_5 {
    padding-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1_5,
  .px-md-1_5 {
    padding-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1_5,
  .py-md-1_5 {
    padding-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1_5,
  .px-md-1_5 {
    padding-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-6 {
    padding: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-6,
  .py-md-6 {
    padding-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-6,
  .px-md-6 {
    padding-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-6,
  .px-md-6 {
    padding-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-7 {
    padding: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-7,
  .py-md-7 {
    padding-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-7,
  .px-md-7 {
    padding-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-7,
  .px-md-7 {
    padding-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-8 {
    padding: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-8,
  .py-md-8 {
    padding-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-8,
  .px-md-8 {
    padding-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-8,
  .px-md-8 {
    padding-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-9 {
    padding: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-9,
  .py-md-9 {
    padding-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-9,
  .px-md-9 {
    padding-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-9,
  .px-md-9 {
    padding-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-10 {
    padding: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-10,
  .py-md-10 {
    padding-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-10,
  .px-md-10 {
    padding-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-10,
  .px-md-10 {
    padding-left: 80px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -8px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -8px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -8px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -8px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -8px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -16px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -16px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -16px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -16px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -16px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -24px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -24px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -24px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -24px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -24px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -32px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -32px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -32px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -32px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -32px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -40px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -40px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -40px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -40px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -40px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n0_5 {
    margin: -4px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n0_5,
  .my-md-n0_5 {
    margin-top: -4px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n0_5,
  .mx-md-n0_5 {
    margin-right: -4px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n0_5,
  .my-md-n0_5 {
    margin-bottom: -4px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n0_5,
  .mx-md-n0_5 {
    margin-left: -4px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1_5 {
    margin: -12px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1_5,
  .my-md-n1_5 {
    margin-top: -12px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1_5,
  .mx-md-n1_5 {
    margin-right: -12px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1_5,
  .my-md-n1_5 {
    margin-bottom: -12px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1_5,
  .mx-md-n1_5 {
    margin-left: -12px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n6 {
    margin: -48px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -48px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -48px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -48px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -48px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n7 {
    margin: -56px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -56px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -56px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -56px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -56px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n8 {
    margin: -64px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -64px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -64px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -64px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -64px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n9 {
    margin: -72px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -72px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -72px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -72px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -72px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n10 {
    margin: -80px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -80px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -80px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -80px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -80px !important; }
  /* line 55, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important; }
  /* line 56, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  /* line 60, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  /* line 64, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  /* line 68, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0_5 {
    margin: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0_5,
  .my-lg-0_5 {
    margin-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0_5,
  .mx-lg-0_5 {
    margin-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0_5,
  .my-lg-0_5 {
    margin-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0_5,
  .mx-lg-0_5 {
    margin-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1_5 {
    margin: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1_5,
  .my-lg-1_5 {
    margin-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1_5,
  .mx-lg-1_5 {
    margin-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1_5,
  .my-lg-1_5 {
    margin-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1_5,
  .mx-lg-1_5 {
    margin-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-6 {
    margin: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-7 {
    margin: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-8 {
    margin: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-9 {
    margin: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-10 {
    margin: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 80px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0_5 {
    padding: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0_5,
  .py-lg-0_5 {
    padding-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0_5,
  .px-lg-0_5 {
    padding-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0_5,
  .py-lg-0_5 {
    padding-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0_5,
  .px-lg-0_5 {
    padding-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1_5 {
    padding: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1_5,
  .py-lg-1_5 {
    padding-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1_5,
  .px-lg-1_5 {
    padding-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1_5,
  .py-lg-1_5 {
    padding-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1_5,
  .px-lg-1_5 {
    padding-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-6 {
    padding: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-7 {
    padding: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-8 {
    padding: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-9 {
    padding: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-10 {
    padding: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 80px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -8px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -8px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -8px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -8px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -8px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -16px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -16px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -16px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -16px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -16px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -24px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -24px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -24px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -24px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -24px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -32px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -32px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -32px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -32px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -32px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -40px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -40px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -40px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -40px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -40px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n0_5 {
    margin: -4px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n0_5,
  .my-lg-n0_5 {
    margin-top: -4px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n0_5,
  .mx-lg-n0_5 {
    margin-right: -4px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n0_5,
  .my-lg-n0_5 {
    margin-bottom: -4px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n0_5,
  .mx-lg-n0_5 {
    margin-left: -4px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1_5 {
    margin: -12px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1_5,
  .my-lg-n1_5 {
    margin-top: -12px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1_5,
  .mx-lg-n1_5 {
    margin-right: -12px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1_5,
  .my-lg-n1_5 {
    margin-bottom: -12px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1_5,
  .mx-lg-n1_5 {
    margin-left: -12px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n6 {
    margin: -48px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -48px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -48px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -48px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -48px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n7 {
    margin: -56px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -56px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -56px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -56px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -56px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n8 {
    margin: -64px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -64px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -64px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -64px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -64px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n9 {
    margin: -72px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -72px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -72px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -72px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -72px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n10 {
    margin: -80px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -80px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -80px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -80px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -80px !important; }
  /* line 55, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important; }
  /* line 56, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  /* line 60, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  /* line 64, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  /* line 68, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0_5 {
    margin: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0_5,
  .my-xl-0_5 {
    margin-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0_5,
  .mx-xl-0_5 {
    margin-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0_5,
  .my-xl-0_5 {
    margin-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0_5,
  .mx-xl-0_5 {
    margin-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1_5 {
    margin: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1_5,
  .my-xl-1_5 {
    margin-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1_5,
  .mx-xl-1_5 {
    margin-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1_5,
  .my-xl-1_5 {
    margin-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1_5,
  .mx-xl-1_5 {
    margin-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-6 {
    margin: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-7 {
    margin: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-8 {
    margin: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-9 {
    margin: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-10 {
    margin: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 80px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 8px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 8px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 8px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 8px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 8px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 16px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 16px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 16px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 16px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 16px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 24px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 24px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 24px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 24px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 24px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 32px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 32px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 32px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 32px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 32px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 40px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 40px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 40px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 40px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 40px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0_5 {
    padding: 4px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0_5,
  .py-xl-0_5 {
    padding-top: 4px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0_5,
  .px-xl-0_5 {
    padding-right: 4px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0_5,
  .py-xl-0_5 {
    padding-bottom: 4px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0_5,
  .px-xl-0_5 {
    padding-left: 4px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1_5 {
    padding: 12px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1_5,
  .py-xl-1_5 {
    padding-top: 12px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1_5,
  .px-xl-1_5 {
    padding-right: 12px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1_5,
  .py-xl-1_5 {
    padding-bottom: 12px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1_5,
  .px-xl-1_5 {
    padding-left: 12px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-6 {
    padding: 48px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 48px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 48px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 48px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 48px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-7 {
    padding: 56px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 56px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 56px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 56px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 56px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-8 {
    padding: 64px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 64px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 64px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 64px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 64px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-9 {
    padding: 72px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 72px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 72px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 72px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 72px !important; }
  /* line 11, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-10 {
    padding: 80px !important; }
  /* line 12, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 80px !important; }
  /* line 16, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 80px !important; }
  /* line 20, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 80px !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 80px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -8px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -8px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -8px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -8px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -8px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -16px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -16px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -16px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -16px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -16px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -24px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -24px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -24px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -24px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -24px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -32px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -32px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -32px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -32px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -32px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -40px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -40px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -40px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -40px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -40px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n0_5 {
    margin: -4px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n0_5,
  .my-xl-n0_5 {
    margin-top: -4px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n0_5,
  .mx-xl-n0_5 {
    margin-right: -4px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n0_5,
  .my-xl-n0_5 {
    margin-bottom: -4px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n0_5,
  .mx-xl-n0_5 {
    margin-left: -4px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1_5 {
    margin: -12px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1_5,
  .my-xl-n1_5 {
    margin-top: -12px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1_5,
  .mx-xl-n1_5 {
    margin-right: -12px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1_5,
  .my-xl-n1_5 {
    margin-bottom: -12px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1_5,
  .mx-xl-n1_5 {
    margin-left: -12px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n6 {
    margin: -48px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -48px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -48px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -48px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -48px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n7 {
    margin: -56px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -56px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -56px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -56px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -56px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n8 {
    margin: -64px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -64px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -64px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -64px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -64px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n9 {
    margin: -72px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -72px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -72px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -72px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -72px !important; }
  /* line 34, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n10 {
    margin: -80px !important; }
  /* line 35, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -80px !important; }
  /* line 39, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -80px !important; }
  /* line 43, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -80px !important; }
  /* line 47, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -80px !important; }
  /* line 55, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important; }
  /* line 56, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  /* line 60, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  /* line 64, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  /* line 68, ../node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/* line 6, ../node_modules/bootstrap/scss/utilities/_stretched-link.scss */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

/* line 7, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important; }

/* line 12, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-wrap {
  white-space: normal !important; }

/* line 13, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important; }

/* line 14, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 22, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important; }

/* line 23, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important; }

/* line 24, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  /* line 22, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  /* line 22, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  /* line 22, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  /* line 22, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important; }
  /* line 23, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important; }
  /* line 24, ../node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important; } }

/* line 30, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important; }

/* line 31, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important; }

/* line 32, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important; }

/* line 36, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important; }

/* line 37, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important; }

/* line 38, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important; }

/* line 39, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important; }

/* line 40, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bolder {
  font-weight: bolder !important; }

/* line 41, ../node_modules/bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important; }

/* line 45, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #FFFFFF !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #F36633 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover, a.text-primary:focus {
  color: #cd3f0c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #544F40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover, a.text-secondary:focus {
  color: #29261f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #008A00 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-success:hover, a.text-success:focus {
  color: #003e00 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #15717D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-info:hover, a.text-info:focus {
  color: #0a363c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #E49B13 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover, a.text-warning:focus {
  color: #9d6b0d !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #ED003C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover, a.text-danger:focus {
  color: #a10029 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #f8f9fa !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #343a40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-primary {
  color: #F36633 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-primary:hover, a.text-gsk-primary:focus {
  color: #cd3f0c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-dark-grey {
  color: #544F40 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-dark-grey:hover, a.text-gsk-dark-grey:focus {
  color: #29261f !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-medium {
  color: #D5D1CE !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-medium:hover, a.text-gsk-medium:focus {
  color: #b2aaa5 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-purple {
  color: #BC1077 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-purple:hover, a.text-gsk-purple:focus {
  color: #760a4a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-red {
  color: #ED003C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-red:hover, a.text-gsk-red:focus {
  color: #a10029 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-indigo-blue {
  color: #40488D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-indigo-blue:hover, a.text-gsk-indigo-blue:focus {
  color: #282d58 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-green {
  color: #008A00 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-green:hover, a.text-gsk-green:focus {
  color: #003e00 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-teal {
  color: #15717D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-teal:hover, a.text-gsk-teal:focus {
  color: #0a363c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-yellow {
  color: #E49B13 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-yellow:hover, a.text-gsk-yellow:focus {
  color: #9d6b0d !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-white {
  color: #FFFFFF !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-white-85 {
  color: rgba(255, 255, 255, 0.85) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-white-85:hover, a.text-white-85:focus {
  color: rgba(217, 217, 217, 0.85) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-white-90 {
  color: rgba(255, 255, 255, 0.9) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-white-90:hover, a.text-white-90:focus {
  color: rgba(217, 217, 217, 0.9) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-white-95 {
  color: rgba(255, 255, 255, 0.95) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-white-95:hover, a.text-white-95:focus {
  color: rgba(217, 217, 217, 0.95) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-grey {
  color: #BEBEBD !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-grey:hover, a.text-grey:focus {
  color: #989896 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-black {
  color: #000000 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-black:hover, a.text-black:focus {
  color: black !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-purple-60 {
  color: rgba(188, 16, 119, 0.6) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-purple-60:hover, a.text-gsk-purple-60:focus {
  color: rgba(118, 10, 74, 0.6) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-teal-60 {
  color: rgba(21, 113, 125, 0.6) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-teal-60:hover, a.text-gsk-teal-60:focus {
  color: rgba(10, 54, 60, 0.6) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-teal-30 {
  color: rgba(21, 113, 125, 0.3) !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-teal-30:hover, a.text-gsk-teal-30:focus {
  color: rgba(10, 54, 60, 0.3) !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-green-60 {
  color: #66B966 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-green-60:hover, a.text-gsk-green-60:focus {
  color: #429042 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-link-blue {
  color: #0D76D1 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-link-blue:hover, a.text-link-blue:focus {
  color: #094d89 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-text-black {
  color: #2E2E3C !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-text-black:hover, a.text-text-black:focus {
  color: #0d0d11 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-text-secondary {
  color: #777369 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-text-secondary:hover, a.text-text-secondary:focus {
  color: #4e4c45 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-text-medium {
  color: #878379 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-text-medium:hover, a.text-text-medium:focus {
  color: #5f5c55 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-text-light {
  color: #BBB9B3 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-text-light:hover, a.text-text-light:focus {
  color: #97948b !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-surface-dark {
  color: #BEBEBD !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-surface-dark:hover, a.text-surface-dark:focus {
  color: #989896 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-surface-medium {
  color: #EFEFED !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-surface-medium:hover, a.text-surface-medium:focus {
  color: #cbcbc5 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-surface-light {
  color: #FAFAF8 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-surface-light:hover, a.text-surface-light:focus {
  color: #dadacb !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-lama {
  color: #E9A93D !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-lama:hover, a.text-gsk-lama:focus {
  color: #c38316 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-laba {
  color: #F4668A !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-laba:hover, a.text-gsk-laba:focus {
  color: #ef1f53 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-ics {
  color: #0D76D1 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-ics:hover, a.text-gsk-ics:focus {
  color: #094d89 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-dpi {
  color: #C5D34E !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-dpi:hover, a.text-gsk-dpi:focus {
  color: #9daa2a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-fmi {
  color: #60B0C9 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-fmi:hover, a.text-gsk-fmi:focus {
  color: #388ba5 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-mdi {
  color: #747EB6 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-mdi:hover, a.text-gsk-mdi:focus {
  color: #4c5791 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-lama-laba {
  color: #F5DB4A !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-lama-laba:hover, a.text-gsk-lama-laba:focus {
  color: #e6c50d !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-ics-laba {
  color: #66B966 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-ics-laba:hover, a.text-gsk-ics-laba:focus {
  color: #429042 !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-gsk-isc-lama-laba {
  color: #8A347F !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-gsk-isc-lama-laba:hover, a.text-gsk-isc-lama-laba:focus {
  color: #521f4c !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-aah-red {
  color: #DF410F !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-aah-red:hover, a.text-aah-red:focus {
  color: #972c0a !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-landing-1 {
  color: #914718 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-landing-1:hover, a.text-landing-1:focus {
  color: #4f270d !important; }

/* line 6, ../node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-landing-2 {
  color: #FF6605 !important; }

/* line 17, ../node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-landing-2:hover, a.text-landing-2:focus {
  color: #b84700 !important; }

/* line 51, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-body {
  color: #212529 !important; }

/* line 52, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important; }

/* line 54, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

/* line 55, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

/* line 59, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/* line 63, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important; }

/* line 65, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

/* line 72, ../node_modules/bootstrap/scss/utilities/_text.scss */
.text-reset {
  color: inherit !important; }

/* line 7, ../node_modules/bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important; }

/* line 11, ../node_modules/bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important; }

@media print {
  /* line 13, ../node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, ../node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, ../node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, ../node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, ../node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  /* line 58, ../node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 63, ../node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 70, ../node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 83, ../node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 86, ../node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 91, ../node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none; }
  /* line 94, ../node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000000; }
  /* line 98, ../node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 101, ../node_modules/bootstrap/scss/_print.scss */
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  /* line 108, ../node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  /* line 114, ../node_modules/bootstrap/scss/_print.scss */
  .table-dark {
    color: inherit; }
    /* line 117, ../node_modules/bootstrap/scss/_print.scss */
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  /* line 125, ../node_modules/bootstrap/scss/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* line 3, src/assets/scss/_global.scss */
html {
  position: relative;
  min-height: 100%;
  overflow-y: scroll; }

/* line 9, src/assets/scss/_global.scss */
body {
  height: 100%;
  color: #544F40;
  font-weight: 400;
  font-family: arial, sans-serif; }

/* line 16, src/assets/scss/_global.scss */
a {
  color: #15717D;
  transition: all 0.3s; }
  /* line 20, src/assets/scss/_global.scss */
  a:hover {
    color: #F36633; }
  /* line 24, src/assets/scss/_global.scss */
  a:focus {
    outline: none; }

/* line 31, src/assets/scss/_global.scss */
#wrapper #content-wrapper {
  min-height: 100vh; }
  /* line 34, src/assets/scss/_global.scss */
  #wrapper #content-wrapper #content {
    flex: 1 0 auto; }

@media (min-width: 768px) and (max-width: 991.98px) {
  /* line 40, src/assets/scss/_global.scss */
  .container {
    padding-left: 24px;
    padding-right: 24px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* line 40, src/assets/scss/_global.scss */
  .container {
    padding-left: 24px;
    padding-right: 24px; } }

/* line 52, src/assets/scss/_global.scss */
.scroll-to-top {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #FFFFFF;
  background: rgba(190, 190, 189, 0.5);
  line-height: 46px; }
  /* line 64, src/assets/scss/_global.scss */
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: #FFFFFF; }
  /* line 69, src/assets/scss/_global.scss */
  .scroll-to-top:hover {
    background: #BEBEBD; }
  /* line 73, src/assets/scss/_global.scss */
  .scroll-to-top i {
    font-weight: 800; }

/* line 78, src/assets/scss/_global.scss */
.bg-gradient-orange {
  background: linear-gradient(67.38deg, #E42313 18.76%, #FFDA00 87.91%); }

/* line 82, src/assets/scss/_global.scss */
.bg-gradient-grey {
  background: linear-gradient(67.38deg, #64564E 18.76%, #B9B3AE 87.91%); }

/* line 86, src/assets/scss/_global.scss */
.bg-gradient-purple {
  background: linear-gradient(67.38deg, #312779 18.76%, #E8308A 87.91%); }

/* line 90, src/assets/scss/_global.scss */
.bg-gradient-red {
  background: linear-gradient(67.38deg, #E4003B 18.76%, #EB5B8C 87.91%); }

/* line 94, src/assets/scss/_global.scss */
.bg-gradient-green {
  background: linear-gradient(67.38deg, #00893E 18.76%, #E4E023 87.91%); }

/* line 98, src/assets/scss/_global.scss */
.bg-gradient-teal {
  background: linear-gradient(67.38deg, #006B77 18.76%, #00B4CD 87.91%); }

/* line 102, src/assets/scss/_global.scss */
.bg-gradient-teal-30 {
  background: linear-gradient(67.38deg, rgba(0, 107, 119, 0.3) 18.76%, rgba(0, 180, 205, 0.3) 87.91%); }

/* Effect styles */
/* line 107, src/assets/scss/_global.scss */
.shadow-sm,
.shadow-small {
  box-shadow: 0 0 8px rgba(84, 79, 64, 0.06) !important; }

/* line 112, src/assets/scss/_global.scss */
.shadow,
.shadow-medium {
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15) !important; }

/* line 117, src/assets/scss/_global.scss */
.shadow-large,
.shadow-lg {
  box-shadow: 0 2px 75px rgba(0, 0, 0, 0.15) !important; }

/* line 122, src/assets/scss/_global.scss */
section.hero-header-1 {
  padding-top: 128px;
  padding-bottom: 0; }
  @media (min-width: 768px) {
    /* line 122, src/assets/scss/_global.scss */
    section.hero-header-1 {
      padding-top: 188px;
      padding-bottom: 0; } }
  @media (min-width: 992px) {
    /* line 122, src/assets/scss/_global.scss */
    section.hero-header-1 {
      padding-top: 192px;
      padding-bottom: 0; } }

/* line 135, src/assets/scss/_global.scss */
.hero-header-1-inner {
  background: rgba(255, 255, 255, 0.85);
  padding: 40px 24px; }
  @media (min-width: 768px) {
    /* line 135, src/assets/scss/_global.scss */
    .hero-header-1-inner {
      padding: 40px 24px; } }
  @media (min-width: 992px) {
    /* line 135, src/assets/scss/_global.scss */
    .hero-header-1-inner {
      padding: 48px 64px; } }

/* line 146, src/assets/scss/_global.scss */
.section-inner {
  padding: 40px 24px; }
  @media (min-width: 768px) {
    /* line 146, src/assets/scss/_global.scss */
    .section-inner {
      padding: 40px 24px; } }
  @media (min-width: 992px) {
    /* line 146, src/assets/scss/_global.scss */
    .section-inner {
      padding: 48px 64px; } }

/* line 156, src/assets/scss/_global.scss */
section.hero-header-2 {
  padding-top: 12px; }
  @media (min-width: 768px) {
    /* line 156, src/assets/scss/_global.scss */
    section.hero-header-2 {
      padding-top: 18px; } }
  @media (min-width: 992px) {
    /* line 156, src/assets/scss/_global.scss */
    section.hero-header-2 {
      padding-top: 24px; } }

/* line 166, src/assets/scss/_global.scss */
section.hero-header-2 h2 + p {
  margin-top: -16px; }

/* line 170, src/assets/scss/_global.scss */
section.padded-section {
  padding-top: 48px;
  padding-bottom: 60px; }
  @media (min-width: 768px) {
    /* line 170, src/assets/scss/_global.scss */
    section.padded-section {
      padding-top: 60px;
      padding-bottom: 72px; } }
  @media (min-width: 992px) {
    /* line 170, src/assets/scss/_global.scss */
    section.padded-section {
      padding-top: 72px;
      padding-bottom: 96px; } }

/* line 183, src/assets/scss/_global.scss */
section.next-steps {
  text-align: center; }
  /* line 186, src/assets/scss/_global.scss */
  section.next-steps .next-steps-inner {
    background: rgba(255, 255, 255, 0.85);
    padding: 40px 24px; }
    @media (min-width: 576px) {
      /* line 186, src/assets/scss/_global.scss */
      section.next-steps .next-steps-inner {
        max-width: 472px;
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 992px) {
      /* line 186, src/assets/scss/_global.scss */
      section.next-steps .next-steps-inner {
        max-width: 976px; } }
    @media (min-width: 768px) {
      /* line 186, src/assets/scss/_global.scss */
      section.next-steps .next-steps-inner {
        padding: 40px 24px; } }
    @media (min-width: 992px) {
      /* line 186, src/assets/scss/_global.scss */
      section.next-steps .next-steps-inner {
        padding: 48px 64px; } }

/* line 206, src/assets/scss/_global.scss */
.number-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  font-size: 32px;
  text-align: center;
  color: #F36633;
  line-height: 32px;
  font-family: arial, sans-serif;
  font-weight: 700; }
  @media (min-width: 768px) {
    /* line 206, src/assets/scss/_global.scss */
    .number-circle {
      width: 60px;
      height: 60px;
      border-width: 5px;
      font-size: 42px;
      line-height: 42px; } }

/* line 229, src/assets/scss/_global.scss */
.stat-title {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 50%; }
  @media (min-width: 576px) {
    /* line 229, src/assets/scss/_global.scss */
    .stat-title {
      position: relative;
      top: 0;
      right: 0;
      width: auto; } }
  /* line 241, src/assets/scss/_global.scss */
  .stat-title h3 {
    margin: 0;
    display: inline-block;
    vertical-align: middle; }
    @media (min-width: 576px) {
      /* line 241, src/assets/scss/_global.scss */
      .stat-title h3 {
        display: block;
        font-size: 26px; } }
  /* line 251, src/assets/scss/_global.scss */
  .stat-title .icon {
    display: inline-block;
    float: right;
    width: 30px;
    vertical-align: middle; }
    @media (min-width: 576px) {
      /* line 251, src/assets/scss/_global.scss */
      .stat-title .icon {
        display: block;
        float: none; } }

/* line 263, src/assets/scss/_global.scss */
.stat-box {
  display: block;
  margin-top: 2rem;
  border-left: 3px solid #F36633; }
  @media (min-width: 576px) {
    /* line 263, src/assets/scss/_global.scss */
    .stat-box {
      width: 85%; } }
  /* line 271, src/assets/scss/_global.scss */
  .stat-box .stat-value {
    font-family: arial, sans-serif;
    font-weight: 700;
    font-size: 44px;
    line-height: 44px; }

/* line 278, src/assets/scss/_global.scss */
.stacked-breadcrumbs {
  margin-top: -84px; }
  @media (min-width: 768px) {
    /* line 278, src/assets/scss/_global.scss */
    .stacked-breadcrumbs {
      margin-top: -92px; } }

/* line 285, src/assets/scss/_global.scss */
.stacked-breadcrumb-item {
  position: relative;
  display: flex; }

/* line 290, src/assets/scss/_global.scss */
.stacked-breadcrumb-item:last-child .stacked-breadcrumb-dot:after {
  display: none; }

/* line 294, src/assets/scss/_global.scss */
.stacked-breadcrumb-dot {
  position: relative;
  border-color: #544F40; }

/* line 299, src/assets/scss/_global.scss */
.stacked-breadcrumb-dot:before {
  content: '';
  background-image: url("/assets/img/icons/icon_breadcrumbtick.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%); }

/* line 312, src/assets/scss/_global.scss */
.stacked-breadcrumb-dot:after {
  content: '';
  position: absolute;
  border-color: inherit;
  border-width: 1px;
  border-style: dashed;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: auto;
  top: 13px;
  bottom: -8px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0; }

/* line 333, src/assets/scss/_global.scss */
.stacked-breadcrumb-item.active .stacked-breadcrumb-dot:after,
.stacked-breadcrumb-item.active .stacked-breadcrumb-dot:before {
  content: '';
  background-image: none;
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%); }

/* line 349, src/assets/scss/_global.scss */
.stacked-breadcrumb-item h4 {
  font-size: 22px;
  line-height: 130%; }

/* line 355, src/assets/scss/_global.scss */
.text-divider {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto; }

/* line 364, src/assets/scss/_global.scss */
.text-divider::after,
.text-divider::before {
  content: "";
  border: 1px solid #FFFFFF;
  flex: 1; }

/* line 371, src/assets/scss/_global.scss */
.text-divider:not(:empty)::before {
  margin-right: 0.5em; }

/* line 375, src/assets/scss/_global.scss */
.text-divider:not(:empty)::after {
  margin-left: 0.5em; }

/* line 380, src/assets/scss/_global.scss */
.arrow-container {
  position: relative; }

/* line 383, src/assets/scss/_global.scss */
.arrow {
  position: relative;
  background-color: #F36633; }
  /* line 386, src/assets/scss/_global.scss */
  .arrow.horizontal {
    height: 1px; }
  /* line 389, src/assets/scss/_global.scss */
  .arrow.vertical {
    width: 1px;
    height: 100%; }

/* line 396, src/assets/scss/_global.scss */
.vertical-legend {
  position: absolute;
  top: 45%;
  transform: translateX(0.75rem) rotate(90deg);
  width: 100%;
  display: inline-block;
  white-space: nowrap; }

/* line 405, src/assets/scss/_global.scss */
.arrow::after {
  position: absolute;
  content: "";
  border: solid #F36633;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px; }

/* line 414, src/assets/scss/_global.scss */
.right.arrow::after {
  right: 0;
  top: -2px;
  transform: rotate(-45deg); }

/* line 420, src/assets/scss/_global.scss */
.left.arrow::after {
  left: 0;
  top: -2px;
  transform: rotate(135deg); }

/* line 426, src/assets/scss/_global.scss */
.up.arrow::after {
  top: 0;
  left: -2px;
  transform: rotate(-135deg); }

/* line 432, src/assets/scss/_global.scss */
.down.arrow::after {
  bottom: 0;
  left: -2px;
  transform: rotate(45deg); }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-primary {
  background-color: #F36633;
  background-image: linear-gradient(180deg, #F36633 10%, #cd3f0c 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-secondary {
  background-color: #544F40;
  background-image: linear-gradient(180deg, #544F40 10%, #29261f 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-success {
  background-color: #008A00;
  background-image: linear-gradient(180deg, #008A00 10%, #003e00 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-info {
  background-color: #15717D;
  background-image: linear-gradient(180deg, #15717D 10%, #0a363c 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-warning {
  background-color: #E49B13;
  background-image: linear-gradient(180deg, #E49B13 10%, #9d6b0d 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-danger {
  background-color: #ED003C;
  background-image: linear-gradient(180deg, #ED003C 10%, #a10029 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-light {
  background-color: #f8f9fa;
  background-image: linear-gradient(180deg, #f8f9fa 10%, #cbd3da 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-dark {
  background-color: #343a40;
  background-image: linear-gradient(180deg, #343a40 10%, #121416 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-primary {
  background-color: #F36633;
  background-image: linear-gradient(180deg, #F36633 10%, #cd3f0c 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-dark-grey {
  background-color: #544F40;
  background-image: linear-gradient(180deg, #544F40 10%, #29261f 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-medium {
  background-color: #D5D1CE;
  background-image: linear-gradient(180deg, #D5D1CE 10%, #b2aaa5 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-purple {
  background-color: #BC1077;
  background-image: linear-gradient(180deg, #BC1077 10%, #760a4a 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-red {
  background-color: #ED003C;
  background-image: linear-gradient(180deg, #ED003C 10%, #a10029 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-indigo-blue {
  background-color: #40488D;
  background-image: linear-gradient(180deg, #40488D 10%, #282d58 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-green {
  background-color: #008A00;
  background-image: linear-gradient(180deg, #008A00 10%, #003e00 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-teal {
  background-color: #15717D;
  background-image: linear-gradient(180deg, #15717D 10%, #0a363c 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-yellow {
  background-color: #E49B13;
  background-image: linear-gradient(180deg, #E49B13 10%, #9d6b0d 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-white {
  background-color: #FFFFFF;
  background-image: linear-gradient(180deg, #FFFFFF 10%, #d9d9d9 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-white-85 {
  background-color: rgba(255, 255, 255, 0.85);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.85) 10%, rgba(217, 217, 217, 0.85) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-white-90 {
  background-color: rgba(255, 255, 255, 0.9);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 10%, rgba(217, 217, 217, 0.9) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-white-95 {
  background-color: rgba(255, 255, 255, 0.95);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 10%, rgba(217, 217, 217, 0.95) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-grey {
  background-color: #BEBEBD;
  background-image: linear-gradient(180deg, #BEBEBD 10%, #989896 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-black {
  background-color: #000000;
  background-image: linear-gradient(180deg, #000000 10%, black 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-purple-60 {
  background-color: rgba(188, 16, 119, 0.6);
  background-image: linear-gradient(180deg, rgba(188, 16, 119, 0.6) 10%, rgba(118, 10, 74, 0.6) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-teal-60 {
  background-color: rgba(21, 113, 125, 0.6);
  background-image: linear-gradient(180deg, rgba(21, 113, 125, 0.6) 10%, rgba(10, 54, 60, 0.6) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-teal-30 {
  background-color: rgba(21, 113, 125, 0.3);
  background-image: linear-gradient(180deg, rgba(21, 113, 125, 0.3) 10%, rgba(10, 54, 60, 0.3) 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-green-60 {
  background-color: #66B966;
  background-image: linear-gradient(180deg, #66B966 10%, #429042 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-link-blue {
  background-color: #0D76D1;
  background-image: linear-gradient(180deg, #0D76D1 10%, #094d89 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-text-black {
  background-color: #2E2E3C;
  background-image: linear-gradient(180deg, #2E2E3C 10%, #0d0d11 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-text-secondary {
  background-color: #777369;
  background-image: linear-gradient(180deg, #777369 10%, #4e4c45 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-text-medium {
  background-color: #878379;
  background-image: linear-gradient(180deg, #878379 10%, #5f5c55 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-text-light {
  background-color: #BBB9B3;
  background-image: linear-gradient(180deg, #BBB9B3 10%, #97948b 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-surface-dark {
  background-color: #BEBEBD;
  background-image: linear-gradient(180deg, #BEBEBD 10%, #989896 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-surface-medium {
  background-color: #EFEFED;
  background-image: linear-gradient(180deg, #EFEFED 10%, #cbcbc5 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-surface-light {
  background-color: #FAFAF8;
  background-image: linear-gradient(180deg, #FAFAF8 10%, #dadacb 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-lama {
  background-color: #E9A93D;
  background-image: linear-gradient(180deg, #E9A93D 10%, #c38316 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-laba {
  background-color: #F4668A;
  background-image: linear-gradient(180deg, #F4668A 10%, #ef1f53 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-ics {
  background-color: #0D76D1;
  background-image: linear-gradient(180deg, #0D76D1 10%, #094d89 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-dpi {
  background-color: #C5D34E;
  background-image: linear-gradient(180deg, #C5D34E 10%, #9daa2a 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-fmi {
  background-color: #60B0C9;
  background-image: linear-gradient(180deg, #60B0C9 10%, #388ba5 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-mdi {
  background-color: #747EB6;
  background-image: linear-gradient(180deg, #747EB6 10%, #4c5791 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-lama-laba {
  background-color: #F5DB4A;
  background-image: linear-gradient(180deg, #F5DB4A 10%, #e6c50d 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-ics-laba {
  background-color: #66B966;
  background-image: linear-gradient(180deg, #66B966 10%, #429042 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-gsk-isc-lama-laba {
  background-color: #8A347F;
  background-image: linear-gradient(180deg, #8A347F 10%, #521f4c 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-aah-red {
  background-color: #DF410F;
  background-image: linear-gradient(180deg, #DF410F 10%, #972c0a 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-landing-1 {
  background-color: #914718;
  background-image: linear-gradient(180deg, #914718 10%, #4f270d 100%);
  background-size: cover; }

/* line 4, src/assets/scss/utilities/_background.scss */
.bg-gradient-landing-2 {
  background-color: #FF6605;
  background-image: linear-gradient(180deg, #FF6605 10%, #b84700 100%);
  background-size: cover; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-100 {
  background-color: #f8f9fa !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-200 {
  background-color: #e9ecef !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-300 {
  background-color: #dee2e6 !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-400 {
  background-color: #ced4da !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-500 {
  background-color: #adb5bd !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-600 {
  background-color: #6c757d !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-700 {
  background-color: #495057 !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-800 {
  background-color: #343a40 !important; }

/* line 14, src/assets/scss/utilities/_background.scss */
.bg-grey-900 {
  background-color: #212529 !important; }

/* line 5, src/assets/scss/utilities/_border.scss */
.border-top {
  border-top: 1px solid; }

/* line 6, src/assets/scss/utilities/_border.scss */
.border-right {
  border-right: 1px solid; }

/* line 7, src/assets/scss/utilities/_border.scss */
.border-bottom {
  border-bottom: 1px solid; }

/* line 8, src/assets/scss/utilities/_border.scss */
.border-left {
  border-left: 1px solid; }

/* line 10, src/assets/scss/utilities/_border.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 11, src/assets/scss/utilities/_border.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 12, src/assets/scss/utilities/_border.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 13, src/assets/scss/utilities/_border.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 15, src/assets/scss/utilities/_border.scss */
.border-x {
  border-left: 1px solid;
  border-right: 1px solid; }

/* line 20, src/assets/scss/utilities/_border.scss */
.border-y {
  border-top: 1px solid;
  border-bottom: 1px solid; }

@media (min-width: 576px) {
  /* line 5, src/assets/scss/utilities/_border.scss */
  .border-sm-top {
    border-top: 1px solid; }
  /* line 6, src/assets/scss/utilities/_border.scss */
  .border-sm-right {
    border-right: 1px solid; }
  /* line 7, src/assets/scss/utilities/_border.scss */
  .border-sm-bottom {
    border-bottom: 1px solid; }
  /* line 8, src/assets/scss/utilities/_border.scss */
  .border-sm-left {
    border-left: 1px solid; }
  /* line 10, src/assets/scss/utilities/_border.scss */
  .border-sm-top-0 {
    border-top: 0 !important; }
  /* line 11, src/assets/scss/utilities/_border.scss */
  .border-sm-right-0 {
    border-right: 0 !important; }
  /* line 12, src/assets/scss/utilities/_border.scss */
  .border-sm-bottom-0 {
    border-bottom: 0 !important; }
  /* line 13, src/assets/scss/utilities/_border.scss */
  .border-sm-left-0 {
    border-left: 0 !important; }
  /* line 15, src/assets/scss/utilities/_border.scss */
  .border-sm-x {
    border-left: 1px solid;
    border-right: 1px solid; }
  /* line 20, src/assets/scss/utilities/_border.scss */
  .border-sm-y {
    border-top: 1px solid;
    border-bottom: 1px solid; } }

@media (min-width: 768px) {
  /* line 5, src/assets/scss/utilities/_border.scss */
  .border-md-top {
    border-top: 1px solid; }
  /* line 6, src/assets/scss/utilities/_border.scss */
  .border-md-right {
    border-right: 1px solid; }
  /* line 7, src/assets/scss/utilities/_border.scss */
  .border-md-bottom {
    border-bottom: 1px solid; }
  /* line 8, src/assets/scss/utilities/_border.scss */
  .border-md-left {
    border-left: 1px solid; }
  /* line 10, src/assets/scss/utilities/_border.scss */
  .border-md-top-0 {
    border-top: 0 !important; }
  /* line 11, src/assets/scss/utilities/_border.scss */
  .border-md-right-0 {
    border-right: 0 !important; }
  /* line 12, src/assets/scss/utilities/_border.scss */
  .border-md-bottom-0 {
    border-bottom: 0 !important; }
  /* line 13, src/assets/scss/utilities/_border.scss */
  .border-md-left-0 {
    border-left: 0 !important; }
  /* line 15, src/assets/scss/utilities/_border.scss */
  .border-md-x {
    border-left: 1px solid;
    border-right: 1px solid; }
  /* line 20, src/assets/scss/utilities/_border.scss */
  .border-md-y {
    border-top: 1px solid;
    border-bottom: 1px solid; } }

@media (min-width: 992px) {
  /* line 5, src/assets/scss/utilities/_border.scss */
  .border-lg-top {
    border-top: 1px solid; }
  /* line 6, src/assets/scss/utilities/_border.scss */
  .border-lg-right {
    border-right: 1px solid; }
  /* line 7, src/assets/scss/utilities/_border.scss */
  .border-lg-bottom {
    border-bottom: 1px solid; }
  /* line 8, src/assets/scss/utilities/_border.scss */
  .border-lg-left {
    border-left: 1px solid; }
  /* line 10, src/assets/scss/utilities/_border.scss */
  .border-lg-top-0 {
    border-top: 0 !important; }
  /* line 11, src/assets/scss/utilities/_border.scss */
  .border-lg-right-0 {
    border-right: 0 !important; }
  /* line 12, src/assets/scss/utilities/_border.scss */
  .border-lg-bottom-0 {
    border-bottom: 0 !important; }
  /* line 13, src/assets/scss/utilities/_border.scss */
  .border-lg-left-0 {
    border-left: 0 !important; }
  /* line 15, src/assets/scss/utilities/_border.scss */
  .border-lg-x {
    border-left: 1px solid;
    border-right: 1px solid; }
  /* line 20, src/assets/scss/utilities/_border.scss */
  .border-lg-y {
    border-top: 1px solid;
    border-bottom: 1px solid; } }

@media (min-width: 1200px) {
  /* line 5, src/assets/scss/utilities/_border.scss */
  .border-xl-top {
    border-top: 1px solid; }
  /* line 6, src/assets/scss/utilities/_border.scss */
  .border-xl-right {
    border-right: 1px solid; }
  /* line 7, src/assets/scss/utilities/_border.scss */
  .border-xl-bottom {
    border-bottom: 1px solid; }
  /* line 8, src/assets/scss/utilities/_border.scss */
  .border-xl-left {
    border-left: 1px solid; }
  /* line 10, src/assets/scss/utilities/_border.scss */
  .border-xl-top-0 {
    border-top: 0 !important; }
  /* line 11, src/assets/scss/utilities/_border.scss */
  .border-xl-right-0 {
    border-right: 0 !important; }
  /* line 12, src/assets/scss/utilities/_border.scss */
  .border-xl-bottom-0 {
    border-bottom: 0 !important; }
  /* line 13, src/assets/scss/utilities/_border.scss */
  .border-xl-left-0 {
    border-left: 0 !important; }
  /* line 15, src/assets/scss/utilities/_border.scss */
  .border-xl-x {
    border-left: 1px solid;
    border-right: 1px solid; }
  /* line 20, src/assets/scss/utilities/_border.scss */
  .border-xl-y {
    border-top: 1px solid;
    border-bottom: 1px solid; } }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--primary {
  border-color: #F36633 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--secondary {
  border-color: #544F40 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--success {
  border-color: #008A00 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--info {
  border-color: #15717D !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--warning {
  border-color: #E49B13 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--danger {
  border-color: #ED003C !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--light {
  border-color: #f8f9fa !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--dark {
  border-color: #343a40 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-primary {
  border-color: #F36633 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-dark-grey {
  border-color: #544F40 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-medium {
  border-color: #D5D1CE !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-purple {
  border-color: #BC1077 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-red {
  border-color: #ED003C !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-indigo-blue {
  border-color: #40488D !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-green {
  border-color: #008A00 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-teal {
  border-color: #15717D !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-yellow {
  border-color: #E49B13 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--white {
  border-color: #FFFFFF !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--white-85 {
  border-color: rgba(255, 255, 255, 0.85) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--white-90 {
  border-color: rgba(255, 255, 255, 0.9) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--white-95 {
  border-color: rgba(255, 255, 255, 0.95) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--grey {
  border-color: #BEBEBD !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--black {
  border-color: #000000 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-purple-60 {
  border-color: rgba(188, 16, 119, 0.6) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-teal-60 {
  border-color: rgba(21, 113, 125, 0.6) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-teal-30 {
  border-color: rgba(21, 113, 125, 0.3) !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-green-60 {
  border-color: #66B966 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--link-blue {
  border-color: #0D76D1 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--text-black {
  border-color: #2E2E3C !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--text-secondary {
  border-color: #777369 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--text-medium {
  border-color: #878379 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--text-light {
  border-color: #BBB9B3 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--surface-dark {
  border-color: #BEBEBD !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--surface-medium {
  border-color: #EFEFED !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--surface-light {
  border-color: #FAFAF8 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-lama {
  border-color: #E9A93D !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-laba {
  border-color: #F4668A !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-ics {
  border-color: #0D76D1 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-dpi {
  border-color: #C5D34E !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-fmi {
  border-color: #60B0C9 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-mdi {
  border-color: #747EB6 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-lama-laba {
  border-color: #F5DB4A !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-ics-laba {
  border-color: #66B966 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--gsk-isc-lama-laba {
  border-color: #8A347F !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--aah-red {
  border-color: #DF410F !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--landing-1 {
  border-color: #914718 !important; }

/* line 28, src/assets/scss/utilities/_border.scss */
.border--landing-2 {
  border-color: #FF6605 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-primary {
  fill: #F36633 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-secondary {
  fill: #544F40 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-success {
  fill: #008A00 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-info {
  fill: #15717D !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-warning {
  fill: #E49B13 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-danger {
  fill: #ED003C !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-light {
  fill: #f8f9fa !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-dark {
  fill: #343a40 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-primary {
  fill: #F36633 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-dark-grey {
  fill: #544F40 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-medium {
  fill: #D5D1CE !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-purple {
  fill: #BC1077 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-red {
  fill: #ED003C !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-indigo-blue {
  fill: #40488D !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-green {
  fill: #008A00 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-teal {
  fill: #15717D !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-yellow {
  fill: #E49B13 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-white {
  fill: #FFFFFF !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-white-85 {
  fill: rgba(255, 255, 255, 0.85) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-white-90 {
  fill: rgba(255, 255, 255, 0.9) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-white-95 {
  fill: rgba(255, 255, 255, 0.95) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-grey {
  fill: #BEBEBD !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-black {
  fill: #000000 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-purple-60 {
  fill: rgba(188, 16, 119, 0.6) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-teal-60 {
  fill: rgba(21, 113, 125, 0.6) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-teal-30 {
  fill: rgba(21, 113, 125, 0.3) !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-green-60 {
  fill: #66B966 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-link-blue {
  fill: #0D76D1 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-text-black {
  fill: #2E2E3C !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-text-secondary {
  fill: #777369 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-text-medium {
  fill: #878379 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-text-light {
  fill: #BBB9B3 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-surface-dark {
  fill: #BEBEBD !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-surface-medium {
  fill: #EFEFED !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-surface-light {
  fill: #FAFAF8 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-lama {
  fill: #E9A93D !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-laba {
  fill: #F4668A !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-ics {
  fill: #0D76D1 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-dpi {
  fill: #C5D34E !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-fmi {
  fill: #60B0C9 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-mdi {
  fill: #747EB6 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-lama-laba {
  fill: #F5DB4A !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-ics-laba {
  fill: #66B966 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-gsk-isc-lama-laba {
  fill: #8A347F !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-aah-red {
  fill: #DF410F !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-landing-1 {
  fill: #914718 !important; }

/* line 3, src/assets/scss/utilities/_fill.scss */
.fill-landing-2 {
  fill: #FF6605 !important; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-primary::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #F36633 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-secondary::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #544F40 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-success::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #008A00 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-info::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #15717D !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-warning::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #E49B13 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-danger::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #ED003C !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-light::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #f8f9fa !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-dark::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #343a40 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-primary::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #F36633 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-dark-grey::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #544F40 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-medium::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #D5D1CE !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-purple::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #BC1077 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-red::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #ED003C !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-indigo-blue::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #40488D !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-green::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #008A00 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-teal::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #15717D !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-yellow::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #E49B13 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-white::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #FFFFFF !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-white-85::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.85) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-white-90::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.9) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-white-95::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.95) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-grey::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #BEBEBD !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-black::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #000000 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-purple-60::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(188, 16, 119, 0.6) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-teal-60::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(21, 113, 125, 0.6) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-teal-30::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: rgba(21, 113, 125, 0.3) !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-green-60::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #66B966 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-link-blue::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #0D76D1 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-text-black::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #2E2E3C !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-text-secondary::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #777369 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-text-medium::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #878379 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-text-light::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #BBB9B3 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-surface-dark::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #BEBEBD !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-surface-medium::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #EFEFED !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-surface-light::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #FAFAF8 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-lama::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #E9A93D !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-laba::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #F4668A !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-ics::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #0D76D1 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-dpi::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #C5D34E !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-fmi::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #60B0C9 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-mdi::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #747EB6 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-lama-laba::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #F5DB4A !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-ics-laba::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #66B966 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-gsk-isc-lama-laba::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #8A347F !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-aah-red::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #DF410F !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-landing-1::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #914718 !important;
  margin-bottom: 12px; }

/* line 5, src/assets/scss/utilities/_pseudo.scss */
.before-line-landing-2::before {
  content: "";
  width: 50px;
  height: 3px;
  display: block;
  background-color: #FF6605 !important;
  margin-bottom: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-primary::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #F36633 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-secondary::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #544F40 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-success::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #008A00 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-info::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #15717D !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-warning::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #E49B13 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-danger::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #ED003C !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-light::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #f8f9fa !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-dark::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #343a40 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-primary::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #F36633 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-dark-grey::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #544F40 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-medium::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #D5D1CE !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-purple::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #BC1077 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-red::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #ED003C !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-indigo-blue::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #40488D !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-green::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #008A00 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-teal::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #15717D !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-yellow::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #E49B13 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-white::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #FFFFFF !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-white-85::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.85) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-white-90::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.9) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-white-95::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(255, 255, 255, 0.95) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-grey::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #BEBEBD !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-black::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #000000 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-purple-60::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(188, 16, 119, 0.6) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-teal-60::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(21, 113, 125, 0.6) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-teal-30::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: rgba(21, 113, 125, 0.3) !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-green-60::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #66B966 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-link-blue::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #0D76D1 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-text-black::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #2E2E3C !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-text-secondary::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #777369 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-text-medium::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #878379 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-text-light::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #BBB9B3 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-surface-dark::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #BEBEBD !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-surface-medium::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #EFEFED !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-surface-light::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #FAFAF8 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-lama::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #E9A93D !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-laba::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #F4668A !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-ics::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #0D76D1 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-dpi::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #C5D34E !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-fmi::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #60B0C9 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-mdi::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #747EB6 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-lama-laba::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #F5DB4A !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-ics-laba::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #66B966 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-gsk-isc-lama-laba::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #8A347F !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-aah-red::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #DF410F !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-landing-1::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #914718 !important;
  margin-top: 12px; }

/* line 18, src/assets/scss/utilities/_pseudo.scss */
.after-full-line-landing-2::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #FF6605 !important;
  margin-top: 12px; }

/* line 8, src/assets/scss/_header.scss */
header#header {
  border-bottom: 1px solid #EFEFED; }
  /* line 11, src/assets/scss/_header.scss */
  header#header .navbar {
    padding: 0; }
    /* line 14, src/assets/scss/_header.scss */
    header#header .navbar a.nav-brand {
      display: inline-block;
      height: 26px;
      width: auto;
      max-height: 26px;
      max-width: auto;
      margin: 0.25rem 0.5rem 0.5rem 1.25rem;
      transition: all 0.3s; }
      @media (min-width: 768px) {
        /* line 14, src/assets/scss/_header.scss */
        header#header .navbar a.nav-brand {
          height: 30px;
          max-height: 30px;
          margin: 0.5rem; } }
      @media (min-width: 992px) {
        /* line 14, src/assets/scss/_header.scss */
        header#header .navbar a.nav-brand {
          height: 49.0333px;
          max-height: 49.0333px;
          margin-right: 1rem; } }
      @media (min-width: 1200px) {
        /* line 14, src/assets/scss/_header.scss */
        header#header .navbar a.nav-brand {
          margin-left: 0; } }
      /* line 39, src/assets/scss/_header.scss */
      header#header .navbar a.nav-brand img.img-fluid {
        max-height: 26px; }
        @media (min-width: 768px) {
          /* line 39, src/assets/scss/_header.scss */
          header#header .navbar a.nav-brand img.img-fluid {
            max-height: 30px; } }
        @media (min-width: 992px) {
          /* line 39, src/assets/scss/_header.scss */
          header#header .navbar a.nav-brand img.img-fluid {
            margin-top: 8px;
            max-height: 35px; } }
    /* line 52, src/assets/scss/_header.scss */
    header#header .navbar .navbar-toggler {
      padding: 0.75rem;
      font-size: 1rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0;
      border-left: 1px solid #EFEFED; }
      /* line 61, src/assets/scss/_header.scss */
      header#header .navbar .navbar-toggler:focus, header#header .navbar .navbar-toggler:hover {
        background-color: #FAFAF8; }
      /* line 67, src/assets/scss/_header.scss */
      header#header .navbar .navbar-toggler[aria-expanded="false"] .icon-menu {
        display: block; }
      /* line 71, src/assets/scss/_header.scss */
      header#header .navbar .navbar-toggler[aria-expanded="false"] .icon-close {
        display: none; }
      /* line 77, src/assets/scss/_header.scss */
      header#header .navbar .navbar-toggler[aria-expanded="true"] .icon-menu {
        display: none; }
      /* line 81, src/assets/scss/_header.scss */
      header#header .navbar .navbar-toggler[aria-expanded="true"] .icon-close {
        display: block; }
    /* line 87, src/assets/scss/_header.scss */
    header#header .navbar ul.navbar-nav > .nav-item {
      border-left: 1px solid #EFEFED; }
      /* line 90, src/assets/scss/_header.scss */
      header#header .navbar ul.navbar-nav > .nav-item .nav-link {
        padding: 0 0.75rem;
        line-height: 40px;
        color: #544F40; }
        /* line 97, src/assets/scss/_header.scss */
        header#header .navbar ul.navbar-nav > .nav-item .nav-link:focus, header#header .navbar ul.navbar-nav > .nav-item .nav-link:hover {
          color: #544F40;
          background-color: #FAFAF8; }
      /* line 104, src/assets/scss/_header.scss */
      header#header .navbar ul.navbar-nav > .nav-item.active .nav-link {
        color: #544F40;
        background-color: #FAFAF8; }
    @media (min-width: 992px) {
      /* line 110, src/assets/scss/_header.scss */
      header#header .navbar #links.navbar ul.navbar-nav > .nav-item:last-child {
        border-right: 1px solid #EFEFED; } }
    @media (max-width: 767.98px) {
      /* line 116, src/assets/scss/_header.scss */
      header#header .navbar .navbar-collapse.show .nav-item {
        border-top: 1px solid #EFEFED;
        border-left: 0;
        border-right: 0; }
        /* line 121, src/assets/scss/_header.scss */
        header#header .navbar .navbar-collapse.show .nav-item .navbar-search input {
          width: 100%; } }
    /* line 128, src/assets/scss/_header.scss */
    header#header .navbar .navbar-search .input-group * {
      border-radius: 0; }
    /* line 132, src/assets/scss/_header.scss */
    header#header .navbar .navbar-search input.form-control {
      width: 80px;
      height: 40px;
      padding: 0.375rem 0.75rem;
      padding-right: 1.25rem;
      background-color: transparent;
      background-image: url("/assets/img/icons/icon_search.svg");
      background-repeat: no-repeat;
      background-position: center right 8px;
      background-size: 14px 14px; }
      /* line 146, src/assets/scss/_header.scss */
      header#header .navbar .navbar-search input.form-control:focus, header#header .navbar .navbar-search input.form-control:hover {
        color: #544F40;
        background-color: #FAFAF8;
        box-shadow: none; }
    /* line 155, src/assets/scss/_header.scss */
    header#header .navbar .dropdown-menu {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      color: #544F40;
      border: 1px solid #EFEFED;
      border-radius: 0; }
    /* line 164, src/assets/scss/_header.scss */
    header#header .navbar .dropdown-item {
      border-bottom: 1px solid #EFEFED;
      padding: 0.75rem; }
      /* line 168, src/assets/scss/_header.scss */
      header#header .navbar .dropdown-item:last-child {
        border-bottom: 0; }
      /* line 172, src/assets/scss/_header.scss */
      header#header .navbar .dropdown-item:focus, header#header .navbar .dropdown-item:hover {
        color: #544F40;
        background-color: #FAFAF8; }
    /* line 179, src/assets/scss/_header.scss */
    header#header .navbar #nav-loggedin ul.navbar-nav > .nav-item {
      border: 0;
      margin: 0;
      border: 0; }
      /* line 184, src/assets/scss/_header.scss */
      header#header .navbar #nav-loggedin ul.navbar-nav > .nav-item .nav-link {
        padding: 3px 1.0rem 0;
        line-height: 42px;
        color: #FFFFFF;
        border-bottom: 3px solid #F36633; }
        /* line 190, src/assets/scss/_header.scss */
        header#header .navbar #nav-loggedin ul.navbar-nav > .nav-item .nav-link:focus, header#header .navbar #nav-loggedin ul.navbar-nav > .nav-item .nav-link:hover {
          color: #FFFFFF;
          background-color: #F36633;
          border-bottom-color: #FFFFFF; }
      /* line 198, src/assets/scss/_header.scss */
      header#header .navbar #nav-loggedin ul.navbar-nav > .nav-item.active .nav-link {
        color: #FFFFFF;
        background-color: #F36633;
        border-bottom-color: #FFFFFF; }
    /* line 207, src/assets/scss/_header.scss */
    header#header .navbar #nav-admin ul.navbar-nav > .nav-item {
      border: 0;
      margin: 0;
      border: 0; }
      /* line 212, src/assets/scss/_header.scss */
      header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link {
        padding: 3px 1.0rem 0;
        line-height: 42px;
        color: #FFFFFF; }
        @media (min-width: 1200px) {
          /* line 212, src/assets/scss/_header.scss */
          header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link {
            border-bottom: 3px solid #000000; } }
        /* line 221, src/assets/scss/_header.scss */
        header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link:focus, header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link:hover {
          color: #000000;
          background-color: #FFFFFF; }
          @media (min-width: 1200px) {
            /* line 221, src/assets/scss/_header.scss */
            header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link:focus, header#header .navbar #nav-admin ul.navbar-nav > .nav-item .nav-link:hover {
              color: #FFFFFF;
              background-color: #000000;
              border-bottom-color: #FFFFFF; } }
      /* line 233, src/assets/scss/_header.scss */
      header#header .navbar #nav-admin ul.navbar-nav > .nav-item.active .nav-link {
        color: #000000;
        background-color: #FFFFFF; }
        @media (min-width: 1200px) {
          /* line 233, src/assets/scss/_header.scss */
          header#header .navbar #nav-admin ul.navbar-nav > .nav-item.active .nav-link {
            color: #FFFFFF;
            background-color: #000000;
            border-bottom-color: #FFFFFF; } }

@media (min-width: 768px) {
  /* line 247, src/assets/scss/_header.scss */
  #nav-loggedin-wrap, #nav-admin-wrap {
    margin-bottom: -1px !important; } }

/* line 253, src/assets/scss/_header.scss */
header#header #admin-toggler.navbar-toggler {
  border: 0; }
  /* line 256, src/assets/scss/_header.scss */
  header#header #admin-toggler.navbar-toggler:focus, header#header #admin-toggler.navbar-toggler:hover {
    background-color: transparent; }

/* line 263, src/assets/scss/_header.scss */
#header-info {
  z-index: 1029; }

/* line 1, src/assets/scss/_footer.scss */
footer#footer {
  display: block;
  width: 100%; }
  @media (min-width: 992px) {
    /* line 7, src/assets/scss/_footer.scss */
    footer#footer .container {
      max-width: 1008px;
      margin-left: auto;
      margin-right: auto; } }
  /* line 14, src/assets/scss/_footer.scss */
  footer#footer h4 {
    margin: 0; }
  /* line 18, src/assets/scss/_footer.scss */
  footer#footer li {
    margin: 0; }
  /* line 22, src/assets/scss/_footer.scss */
  footer#footer a.nav-link {
    padding: 0; }
  /* line 26, src/assets/scss/_footer.scss */
  footer#footer #footer-prescribing-info-links li {
    margin-bottom: 0.5rem; }
    @media (min-width: 768px) {
      /* line 26, src/assets/scss/_footer.scss */
      footer#footer #footer-prescribing-info-links li {
        padding-right: 3.0rem; } }
  /* line 34, src/assets/scss/_footer.scss */
  footer#footer #footer-legal-links a.nav-link {
    padding: 0.25rem 1.0rem; }

@media (min-width: 1200px) {
  /* line 41, src/assets/scss/_footer.scss */
  #footer-links .container {
    position: relative; }
    /* line 43, src/assets/scss/_footer.scss */
    #footer-links .container::before {
      content: "";
      display: block;
      width: 57px;
      height: 57px;
      background-image: url("../img/gsk-logo.png");
      background-repeat: no-repeat;
      background-size: 57px auto;
      background-position: top left;
      position: absolute;
      left: -68px;
      top: 78px; } }

/* line 63, src/assets/scss/_footer.scss */
#footer-adverse-events .medium {
  font-size: 18px; }

/* line 1, src/assets/scss/_typography.scss */
h1, .h1 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  margin: 0 0 40px 0; }
  @media (min-width: 768px) {
    /* line 1, src/assets/scss/_typography.scss */
    h1, .h1 {
      font-size: 40px;
      line-height: 100%;
      margin: 0 0 48px 0; } }
  @media (min-width: 992px) {
    /* line 1, src/assets/scss/_typography.scss */
    h1, .h1 {
      font-size: 42px;
      line-height: 100%;
      margin: 0 0 48px 0; } }

/* line 19, src/assets/scss/_typography.scss */
h2, .h2 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 135%;
  margin: 24px 0 24px 0; }
  @media (min-width: 768px) {
    /* line 19, src/assets/scss/_typography.scss */
    h2, .h2 {
      font-size: 32px;
      line-height: 110%;
      margin: 24px 0 32px 0; } }
  @media (min-width: 992px) {
    /* line 19, src/assets/scss/_typography.scss */
    h2, .h2 {
      font-size: 36px;
      line-height: 110%;
      margin: 24px 0 40px 0; } }

/* line 37, src/assets/scss/_typography.scss */
h3, .h3 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  margin: 24px 0 32px 0; }
  @media (min-width: 768px) {
    /* line 37, src/assets/scss/_typography.scss */
    h3, .h3 {
      font-size: 26px;
      line-height: 130%;
      margin: 24px 0 32px 0; } }
  @media (min-width: 992px) {
    /* line 37, src/assets/scss/_typography.scss */
    h3, .h3 {
      font-size: 28px;
      line-height: 130%;
      margin: 26px 0 36px 0; } }

/* line 55, src/assets/scss/_typography.scss */
h4, .h4 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 100%;
  margin: 16px 0 20px 0; }
  @media (min-width: 768px) {
    /* line 55, src/assets/scss/_typography.scss */
    h4, .h4 {
      font-size: 22px;
      line-height: 130%;
      margin: 16px 0 20px 0; } }
  @media (min-width: 992px) {
    /* line 55, src/assets/scss/_typography.scss */
    h4, .h4 {
      font-size: 22px;
      line-height: 130%;
      margin: 18px 0 24px 0; } }

/* line 73, src/assets/scss/_typography.scss */
h5, .h5 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  margin: 12px 0 16px 0; }
  @media (min-width: 768px) {
    /* line 73, src/assets/scss/_typography.scss */
    h5, .h5 {
      font-size: 22px;
      line-height: 140%;
      margin: 12px 0 16px 0; } }
  @media (min-width: 992px) {
    /* line 73, src/assets/scss/_typography.scss */
    h5, .h5 {
      font-size: 20px;
      line-height: 140%;
      margin: 14px 0 18px 0; } }

/* line 91, src/assets/scss/_typography.scss */
h6, .h6 {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin: 12px 0 16px 0; }
  @media (min-width: 768px) {
    /* line 91, src/assets/scss/_typography.scss */
    h6, .h6 {
      font-size: 16px;
      line-height: 138%;
      margin: 12px 0 16px 0; } }
  @media (min-width: 992px) {
    /* line 91, src/assets/scss/_typography.scss */
    h6, .h6 {
      font-size: 16px;
      line-height: 140%;
      margin: 14px 0 18px 0; } }

/* line 109, src/assets/scss/_typography.scss */
p {
  font-family: arial, sans-serif;
  font-size: 16px;
  line-height: 140%;
  margin: 20px 0 24px 0; }
  /* line 125, src/assets/scss/_typography.scss */
  p.intro {
    font-size: 24px;
    line-height: 120%;
    margin: 24px 0 32px 0; }
    @media (min-width: 768px) {
      /* line 125, src/assets/scss/_typography.scss */
      p.intro {
        font-size: 27px;
        line-height: 118%;
        margin: 24px 0 32px 0; } }
    @media (min-width: 992px) {
      /* line 125, src/assets/scss/_typography.scss */
      p.intro {
        font-size: 28px;
        line-height: 118%;
        margin: 28px 0 36px 0; } }
  /* line 141, src/assets/scss/_typography.scss */
  p.medium {
    font-size: 18px;
    line-height: 140%;
    margin: 16px 0 20px 0; }
    @media (min-width: 768px) {
      /* line 141, src/assets/scss/_typography.scss */
      p.medium {
        font-size: 20px;
        line-height: 140%;
        margin: 16px 0 21px 0; } }
    @media (min-width: 992px) {
      /* line 141, src/assets/scss/_typography.scss */
      p.medium {
        font-size: 20px;
        line-height: 140%;
        margin: 18px 0 24px 0; } }
  /* line 157, src/assets/scss/_typography.scss */
  p.small {
    font-size: 13px;
    line-height: 170%;
    margin: 12px 0 16px 0; }
    @media (min-width: 768px) {
      /* line 157, src/assets/scss/_typography.scss */
      p.small {
        font-size: 13px;
        line-height: 170%;
        margin: 12px 0 16px 0; } }
    @media (min-width: 992px) {
      /* line 157, src/assets/scss/_typography.scss */
      p.small {
        font-size: 13px;
        line-height: 170%;
        margin: 12px 0 16px 0; } }
  /* line 173, src/assets/scss/_typography.scss */
  p.x-small {
    font-size: 11px; }

/* line 179, src/assets/scss/_typography.scss */
.weight-medium {
  font-weight: 700; }

/* line 184, src/assets/scss/_typography.scss */
div > p:first-child {
  margin-top: 0; }

/* line 189, src/assets/scss/_typography.scss */
li {
  font-family: arial, sans-serif;
  font-size: 16px;
  line-height: 140%; }
  /* line 202, src/assets/scss/_typography.scss */
  li.intro {
    font-size: 24px;
    line-height: 120%; }
    @media (min-width: 768px) {
      /* line 202, src/assets/scss/_typography.scss */
      li.intro {
        font-size: 27px;
        line-height: 118%; } }
    @media (min-width: 992px) {
      /* line 202, src/assets/scss/_typography.scss */
      li.intro {
        font-size: 28px;
        line-height: 118%; } }
  /* line 215, src/assets/scss/_typography.scss */
  li.medium {
    font-size: 18px;
    line-height: 140%; }
    @media (min-width: 768px) {
      /* line 215, src/assets/scss/_typography.scss */
      li.medium {
        font-size: 20px;
        line-height: 140%; } }
    @media (min-width: 992px) {
      /* line 215, src/assets/scss/_typography.scss */
      li.medium {
        font-size: 20px;
        line-height: 140%; } }
  /* line 228, src/assets/scss/_typography.scss */
  li.small {
    font-size: 13px;
    line-height: 170%;
    margin: 12px 0 16px 0; }
    @media (min-width: 768px) {
      /* line 228, src/assets/scss/_typography.scss */
      li.small {
        font-size: 13px;
        line-height: 170%; } }
    @media (min-width: 992px) {
      /* line 228, src/assets/scss/_typography.scss */
      li.small {
        font-size: 13px;
        line-height: 170%; } }

/* line 253, src/assets/scss/_typography.scss */
.top-header {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #15717D; }
  /* line 259, src/assets/scss/_typography.scss */
  .top-header::after {
    content: "";
    width: 80px;
    height: 2px;
    display: block;
    background-color: #F36633;
    margin-top: 16px; }
  @media (min-width: 992px) {
    /* line 253, src/assets/scss/_typography.scss */
    .top-header {
      font-size: 18px;
      line-height: 20px; }
      /* line 270, src/assets/scss/_typography.scss */
      .top-header::after {
        width: 86px;
        margin-top: 21px; } }

/* line 277, src/assets/scss/_typography.scss */
.top-header + h1 {
  margin-top: 48px; }

/* line 281, src/assets/scss/_typography.scss */
.title-header {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 123%;
  color: #15717D; }
  /* line 286, src/assets/scss/_typography.scss */
  .title-header::after {
    content: "";
    width: 80px;
    height: 4px;
    display: block;
    background-color: #F36633;
    margin-top: 12px; }
  @media (min-width: 768px) {
    /* line 296, src/assets/scss/_typography.scss */
    .title-header::after {
      width: 96px;
      margin-top: 12px; } }
  @media (min-width: 992px) {
    /* line 303, src/assets/scss/_typography.scss */
    .title-header::after {
      width: 128px;
      margin-top: 12px; } }

/* line 310, src/assets/scss/_typography.scss */
.title-header + h2 {
  margin-top: 20px; }
  @media (min-width: 768px) {
    /* line 310, src/assets/scss/_typography.scss */
    .title-header + h2 {
      margin-top: 24px;
      max-width: 80%; } }

/* line 319, src/assets/scss/_typography.scss */
.top-nav {
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 140%; }
  @media (min-width: 768px) {
    /* line 319, src/assets/scss/_typography.scss */
    .top-nav {
      font-size: 13px;
      line-height: 100%; } }

/* line 330, src/assets/scss/_typography.scss */
.medium-bold {
  font-size: 20px; }

/* line 335, src/assets/scss/_typography.scss */
.line-height-100 {
  line-height: 100% !important; }

/* line 340, src/assets/scss/_typography.scss */
.after-line::after {
  content: "";
  width: 80px;
  height: 4px;
  display: block;
  background-color: #F36633;
  margin-top: 12px; }

/* line 351, src/assets/scss/_typography.scss */
.before-line::before {
  content: "";
  width: 80px;
  height: 2px;
  display: block;
  background-color: #F36633;
  margin-bottom: 12px; }

/* line 1, src/assets/scss/_forms.scss */
button,
input,
optgroup,
option,
select,
textarea {
  font-family: arial, sans-serif;
  color: #544F40; }

/* line 28, src/assets/scss/_forms.scss */
.form-group:not(.form-group-sm) {
  margin-bottom: 22px; }
  /* line 31, src/assets/scss/_forms.scss */
  .form-group:not(.form-group-sm) .form-control {
    border-radius: 0;
    min-height: 56px;
    font-size: 20px;
    line-height: 140%;
    border-color: #FAFAF8;
    background-color: #FAFAF8; }
  /* line 40, src/assets/scss/_forms.scss */
  .form-group:not(.form-group-sm) .form-control.form-style-2 {
    border-radius: 4px;
    min-height: 56px;
    font-size: 20px;
    line-height: 140%;
    border-color: #15717D;
    background-color: #FFFFFF; }
  /* line 49, src/assets/scss/_forms.scss */
  .form-group:not(.form-group-sm) label:not(.custom-control-label) {
    margin-bottom: 0;
    font-family: arial, sans-serif;
    font-weight: 700; }
  /* line 54, src/assets/scss/_forms.scss */
  .form-group:not(.form-group-sm) select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("/assets/img/icons/chevron_down.svg");
    background-repeat: no-repeat;
    background-position: center right 1rem;
    padding-right: 2rem; }
    /* line 64, src/assets/scss/_forms.scss */
    .form-group:not(.form-group-sm) select:focus {
      background-image: url("/assets/img/icons/chevron_up.svg"); }
    /* line 68, src/assets/scss/_forms.scss */
    .form-group:not(.form-group-sm) select option {
      font-size: 18px; }
  /* line 73, src/assets/scss/_forms.scss */
  .form-group:not(.form-group-sm) .bootstrap-datetimepicker-widget table td,
  .form-group:not(.form-group-sm) .bootstrap-datetimepicker-widget table th {
    font-size: 70%; }

/* line 79, src/assets/scss/_forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

/* line 90, src/assets/scss/_forms.scss */
.rangeslider-wrap {
  padding-top: 50px;
  padding-bottom: 30px; }
  /* line 94, src/assets/scss/_forms.scss */
  .rangeslider-wrap .rangeslider {
    position: relative;
    height: 4px;
    border-radius: 5px;
    width: 100%;
    background-color: #EFEFED; }
    /* line 101, src/assets/scss/_forms.scss */
    .rangeslider-wrap .rangeslider__handle {
      transition: background-color 0.2s;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #15717D;
      border: 2px solid #FFFFFF;
      touch-action: pan-y;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      z-index: 3;
      top: -8px; }
      /* line 116, src/assets/scss/_forms.scss */
      .rangeslider-wrap .rangeslider__handle__value {
        transition: background-color 0.2s, box-shadow 0.1s, transform 0.1s;
        box-sizing: border-box;
        width: 50px;
        text-align: center;
        padding: 10px;
        color: #15717D;
        left: -15px;
        top: -40px;
        position: absolute;
        white-space: nowrap; }
        /* line 128, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__handle__value:after {
          content: ""; }
    /* line 134, src/assets/scss/_forms.scss */
    .rangeslider-wrap .rangeslider__fill {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 100%;
      background-color: #15717D;
      border-radius: 5px; }
    /* line 143, src/assets/scss/_forms.scss */
    .rangeslider-wrap .rangeslider__labels {
      position: absolute;
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: space-between; }
      /* line 150, src/assets/scss/_forms.scss */
      .rangeslider-wrap .rangeslider__labels__label {
        font-size: 0.75em;
        position: relative;
        padding-top: 15px;
        color: #544F40; }
        /* line 156, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:before {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 1px;
          height: 9px;
          border-radius: 1px;
          background-color: rgba(239, 239, 237, 0.5); }
        /* line 168, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:first-child:before, .rangeslider-wrap .rangeslider__labels__label:last-child:before {
          height: 12px;
          width: 0; }
        /* line 174, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:first-child:before {
          background-color: #EFEFED; }
        /* line 178, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:last-child:before {
          background-color: #EFEFED; }
        /* line 182, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:first-child {
          transform: translateX(0); }
        /* line 187, src/assets/scss/_forms.scss */
        .rangeslider-wrap .rangeslider__labels__label:last-child {
          transform: translateX(0); }

/* line 205, src/assets/scss/_forms.scss */
.rangeslider-wrap.percentageslider .rangeslider__handle__value {
  left: -17px; }
  /* line 208, src/assets/scss/_forms.scss */
  .rangeslider-wrap.percentageslider .rangeslider__handle__value:after {
    content: "%"; }

/* line 215, src/assets/scss/_forms.scss */
.rangeslider-wrap.percentageslider .rangeslider__labels__label:after {
  content: "%"; }

/* line 222, src/assets/scss/_forms.scss */
.rangeslider-wrap.greenslider .rangeslider__handle {
  background-color: #008A00; }
  /* line 225, src/assets/scss/_forms.scss */
  .rangeslider-wrap.greenslider .rangeslider__handle__value {
    color: #008A00; }

/* line 230, src/assets/scss/_forms.scss */
.rangeslider-wrap.greenslider .rangeslider__fill {
  background-color: #008A00; }

/* line 236, src/assets/scss/_forms.scss */
.rangeslider-wrap.dualslider {
  padding-top: 0; }
  /* line 241, src/assets/scss/_forms.scss */
  .rangeslider-wrap.dualslider .rangeslider__handle__value {
    top: 0 !important;
    display: none !important; }

/* line 249, src/assets/scss/_forms.scss */
.select2 {
  width: 100% !important; }

/* line 253, src/assets/scss/_forms.scss */
.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
  border-radius: 0;
  min-height: 56px;
  font-size: 20px;
  line-height: 140%;
  border-color: #FAFAF8;
  background-color: #FAFAF8;
  background-image: url("/assets/img/icons/chevron_down.svg");
  background-repeat: no-repeat;
  background-position: center right 1rem;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  color: #544F40; }

/* line 270, src/assets/scss/_forms.scss */
.select2-container--default .select2-selection--multiple li.select2-selection__choice {
  background-color: #15717D;
  border: 1px solid #15717D;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 5px 5px 0;
  padding-left: 2.5rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  color: #FFFFFF;
  position: relative;
  font-size: 1em; }

/* line 289, src/assets/scss/_forms.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border-right: 1px solid #FFFFFF;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  display: block; }

/* line 306, src/assets/scss/_forms.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
  font-size: 16px; }

/* line 313, src/assets/scss/_forms.scss */
.select2-container .select2-search--inline .select2-search__field {
  height: 28px;
  margin-left: 0;
  color: #EFEFED;
  opacity: 1; }

/* line 321, src/assets/scss/_forms.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0; }

/* line 325, src/assets/scss/_forms.scss */
.select2-search.select2-search--inline {
  display: inline-block;
  width: 80%; }

/* line 330, src/assets/scss/_forms.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #FAFAF8;
  box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }

/* line 335, src/assets/scss/_forms.scss */
.select2-container--open {
  box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25); }

/* line 339, src/assets/scss/_forms.scss */
.select2-container.select2-container--open .select2-selection--multiple,
.select2-container.select2-container--open .select2-selection--single {
  background-image: url("/assets/img/icons/chevron_up.svg"); }

/* line 344, src/assets/scss/_forms.scss */
.select2-selection__arrow {
  display: none; }

/* line 348, src/assets/scss/_forms.scss */
.select2-results__option {
  font-size: 18px; }

/* line 352, src/assets/scss/_forms.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 56px;
  line-height: 42px;
  color: #544F40 !important; }

/* line 362, src/assets/scss/_forms.scss */
.select2-container .select2-selection--single.location-select {
  border-color: transparent;
  background-color: transparent; }

/* line 369, src/assets/scss/_forms.scss */
.select2-container--default .select2-selection--single.location-select .select2-selection__rendered {
  color: #F36633 !important; }

/* line 374, src/assets/scss/_forms.scss */
.l2 {
  padding-left: 1rem;
  display: inline-block; }

/* line 379, src/assets/scss/_forms.scss */
.l3 {
  padding-left: 2rem;
  display: inline-block; }

/* line 384, src/assets/scss/_forms.scss */
.dropdown-back {
  cursor: pointer;
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 2; }
  /* line 391, src/assets/scss/_forms.scss */
  .dropdown-back img {
    width: 32px;
    height: 32px; }

/* line 398, src/assets/scss/_forms.scss */
.large-date-input {
  font-size: 1.5rem;
  font-weight: bold;
  border: 0;
  padding: 0;
  height: auto;
  margin-top: 5px; }
  /* line 406, src/assets/scss/_forms.scss */
  .large-date-input:focus {
    padding: 0 5px; }

/* line 413, src/assets/scss/_forms.scss */
#select2-so_product-container.select2-selection__rendered {
  max-width: 318px; }

/* line 417, src/assets/scss/_forms.scss */
.select2-search.select2-search--dropdown input.select2-search__field {
  background-image: url("/assets/img/icons/icon_search.svg");
  background-repeat: no-repeat;
  background-position: center left 10px;
  background-size: auto;
  padding-left: 30px; }

/* line 1, src/assets/scss/_buttons.scss */
a.link {
  text-decoration: underline; }
  /* line 3, src/assets/scss/_buttons.scss */
  a.link:hover {
    text-decoration: underline;
    color: #F36633; }
  /* line 8, src/assets/scss/_buttons.scss */
  a.link.external-link::after {
    content: "";
    position: relative;
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
    background-image: url("/assets/img/icons/icon_new_window.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block; }

/* line 22, src/assets/scss/_buttons.scss */
.btn {
  min-height: 42px;
  border-radius: 25px;
  border-width: 2px;
  width: 215px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    /* line 22, src/assets/scss/_buttons.scss */
    .btn {
      width: 295px; } }
  @media (min-width: 992px) {
    /* line 22, src/assets/scss/_buttons.scss */
    .btn {
      width: 232px; } }
  /* line 37, src/assets/scss/_buttons.scss */
  .btn.btn-width-auto {
    width: 100%; }

/* line 42, src/assets/scss/_buttons.scss */
.input-group .btn {
  width: auto; }

/* line 46, src/assets/scss/_buttons.scss */
.btn.link {
  background: none;
  color: #15717D; }

/* line 64, src/assets/scss/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  min-height: 33.6px;
  border-radius: 24px;
  border-width: 1px;
  width: 120px;
  font-size: 10px;
  text-transform: uppercase; }

/* line 73, src/assets/scss/_buttons.scss */
.small-link {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%; }

/* line 80, src/assets/scss/_buttons.scss */
.btn-group.toggle-charts {
  background-color: #EFEFED;
  border-radius: 24px; }
  /* line 83, src/assets/scss/_buttons.scss */
  .btn-group.toggle-charts .btn-sm, .btn-group.toggle-charts .btn-group-sm > .btn {
    border-radius: 24px; }
    /* line 85, src/assets/scss/_buttons.scss */
    .btn-group.toggle-charts .btn-sm.active, .btn-group.toggle-charts .btn-group-sm > .active.btn {
      background-color: #15717D;
      color: #FFFFFF;
      border-radius: 24px;
      box-shadow: none; }
    /* line 91, src/assets/scss/_buttons.scss */
    .btn-group.toggle-charts .btn-sm:focus, .btn-group.toggle-charts .btn-group-sm > .btn:focus {
      box-shadow: none; }

/* line 98, src/assets/scss/_buttons.scss */
.btn span.download-icon {
  display: inline-block;
  background-image: url("/assets/img/icons/icon_download_button.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 1rem;
  margin-left: 1rem; }

/* line 108, src/assets/scss/_buttons.scss */
.btn:hover span.download-icon {
  background-image: url("/assets/img/icons/icon_download_button_white.svg"); }

/* line 1, src/assets/scss/_columns.scss */
.columns-md-2 {
  column-count: 1;
  column-gap: 1rem; }
  @media (min-width: 768px) {
    /* line 1, src/assets/scss/_columns.scss */
    .columns-md-2 {
      column-count: 2; } }

/* line 9, src/assets/scss/_columns.scss */
.footnotes.columns-md-2 {
  column-count: 1;
  column-gap: 2rem; }
  @media (min-width: 768px) {
    /* line 9, src/assets/scss/_columns.scss */
    .footnotes.columns-md-2 {
      column-count: 2; } }

/* line 17, src/assets/scss/_columns.scss */
.footnotes {
  margin: 0;
  padding: 0 1rem; }

/* line 22, src/assets/scss/_columns.scss */
.footnotes li {
  break-inside: avoid;
  margin-top: 0; }

/* line 1, src/assets/scss/_dropdowns.scss */
.dropdown.dropdown-1 {
  display: inline; }
  /* line 4, src/assets/scss/_dropdowns.scss */
  .dropdown.dropdown-1 > .dropdown-toggle {
    color: #15717D;
    font-family: arial, sans-serif;
    font-weight: 700;
    text-decoration: underline;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0; }
    /* line 12, src/assets/scss/_dropdowns.scss */
    .dropdown.dropdown-1 > .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.25em;
      vertical-align: 0;
      content: url("/assets/img/icons/chevron_down.svg");
      border: 0; }
    /* line 20, src/assets/scss/_dropdowns.scss */
    .dropdown.dropdown-1 > .dropdown-toggle[aria-expanded="true"]::after {
      content: url("/assets/img/icons/chevron_up.svg"); }
  /* line 25, src/assets/scss/_dropdowns.scss */
  .dropdown.dropdown-1 .dropdown-menu.dropdown-primary {
    min-width: 15rem;
    background-color: #F36633;
    padding: 0; }
    /* line 30, src/assets/scss/_dropdowns.scss */
    .dropdown.dropdown-1 .dropdown-menu.dropdown-primary a.dropdown-item {
      color: #FFFFFF;
      padding: 0.125rem 0.5rem;
      font-size: 19px; }
      /* line 35, src/assets/scss/_dropdowns.scss */
      .dropdown.dropdown-1 .dropdown-menu.dropdown-primary a.dropdown-item.active, .dropdown.dropdown-1 .dropdown-menu.dropdown-primary a.dropdown-item:focus, .dropdown.dropdown-1 .dropdown-menu.dropdown-primary a.dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }

/* line 56, src/assets/scss/_dropdowns.scss */
.dropdown-2-wrapper p {
  font-size: 20px; }

/* line 61, src/assets/scss/_dropdowns.scss */
.dropdown-2-wrapper .dropdown.dropdown-2 {
  display: inline-block;
  width: 100%; }
  /* line 65, src/assets/scss/_dropdowns.scss */
  .dropdown-2-wrapper .dropdown.dropdown-2 > .dropdown-toggle {
    color: #F36633;
    text-decoration: none;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 20px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: left; }
    @media (min-width: 576px) {
      /* line 65, src/assets/scss/_dropdowns.scss */
      .dropdown-2-wrapper .dropdown.dropdown-2 > .dropdown-toggle {
        margin-top: 2px;
        width: auto; } }
    /* line 81, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 > .dropdown-toggle::after {
      content: url("/assets/img/icons/chevron_down.svg");
      border: 0;
      vertical-align: 0;
      position: absolute;
      right: 0; }
      @media (min-width: 576px) {
        /* line 81, src/assets/scss/_dropdowns.scss */
        .dropdown-2-wrapper .dropdown.dropdown-2 > .dropdown-toggle::after {
          position: relative;
          display: inline-block;
          margin-left: 0.25em; } }
    /* line 94, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 > .dropdown-toggle[aria-expanded="true"]::after {
      content: url("/assets/img/icons/chevron_up.svg"); }
  /* line 101, src/assets/scss/_dropdowns.scss */
  .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu .dropdown-toggle {
    width: 40px; }
    /* line 104, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu .dropdown-toggle::after {
      content: url("/assets/img/icons/chevron_down_white.svg");
      border: 0;
      vertical-align: 0;
      position: absolute;
      right: 0; }
      @media (min-width: 576px) {
        /* line 104, src/assets/scss/_dropdowns.scss */
        .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu .dropdown-toggle::after {
          position: relative;
          display: inline-block; } }
    /* line 116, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu .dropdown-toggle.active::after {
      content: url("/assets/img/icons/chevron_up_white.svg"); }
  /* line 122, src/assets/scss/_dropdowns.scss */
  .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary {
    min-width: 15rem;
    background-color: #F36633;
    padding: 0; }
    /* line 127, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary h6.dropdown-header {
      color: #FFFFFF;
      padding: 0.125rem 0.5rem;
      margin: 0;
      font-size: 20px;
      background-color: rgba(255, 255, 255, 0.2); }
    /* line 135, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary a.dropdown-item {
      color: #FFFFFF;
      padding: 0.125rem 0.5rem;
      font-size: 20px; }
      /* line 140, src/assets/scss/_dropdowns.scss */
      .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary a.dropdown-item.active, .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary a.dropdown-item:focus, .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-menu.dropdown-primary a.dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
  /* line 148, src/assets/scss/_dropdowns.scss */
  .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu {
    position: relative; }
    /* line 151, src/assets/scss/_dropdowns.scss */
    .dropdown-2-wrapper .dropdown.dropdown-2 .dropdown-submenu .dropdown-menu {
      top: 36px;
      left: 0.5rem; }

/* line 1, src/assets/scss/_accordions.scss */
.card.accordion-card-1 {
  border-radius: 0;
  border: 1px dashed #544F40;
  border-left: 3px solid #F36633;
  background-color: transparent; }
  /* line 7, src/assets/scss/_accordions.scss */
  .card.accordion-card-1 .card-header {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    background-image: url("/assets/img/icons/chevron_up.svg");
    background-repeat: no-repeat;
    background-position: center right 1rem;
    background-size: auto; }
    /* line 16, src/assets/scss/_accordions.scss */
    .card.accordion-card-1 .card-header.collapsed {
      background-image: url("/assets/img/icons/chevron_down.svg"); }
    /* line 20, src/assets/scss/_accordions.scss */
    .card.accordion-card-1 .card-header h5 {
      text-transform: uppercase;
      color: #15717D;
      font-family: arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0.08em; }

/* line 29, src/assets/scss/_accordions.scss */
.card.accordion-card-2 {
  border-radius: 0;
  border: 0;
  background-color: transparent; }
  /* line 34, src/assets/scss/_accordions.scss */
  .card.accordion-card-2 .card-header {
    border: 0;
    background-color: #EFEFED;
    cursor: pointer;
    background-image: url("/assets/img/icons/chevron_up_teal.svg");
    background-repeat: no-repeat;
    background-position: center right 1rem;
    background-size: auto; }
    /* line 43, src/assets/scss/_accordions.scss */
    .card.accordion-card-2 .card-header.collapsed {
      background-image: url("/assets/img/icons/chevron_down_teal.svg"); }
    /* line 47, src/assets/scss/_accordions.scss */
    .card.accordion-card-2 .card-header h4 {
      color: #15717D;
      font-family: arial, sans-serif;
      font-weight: 700; }

/* line 55, src/assets/scss/_accordions.scss */
.toggle-more {
  padding-bottom: 2rem;
  background-image: url("/assets/img/icons/chevron_up.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto; }
  /* line 62, src/assets/scss/_accordions.scss */
  .toggle-more.collapsed {
    background-image: url("/assets/img/icons/chevron_down.svg"); }

/* line 1, src/assets/scss/_cards.scss */
.card-header, .card-body, .card-footer {
  border: 0; }

/* line 4, src/assets/scss/_cards.scss */
.card-footer {
  background: transparent; }

/* line 8, src/assets/scss/_cards.scss */
a.card {
  text-decoration: none;
  color: inherit; }

/* line 14, src/assets/scss/_cards.scss */
a.card-download-pdf p.card-title {
  font-size: 16px; }

/* line 18, src/assets/scss/_cards.scss */
a.card-download-pdf .icon-folder {
  height: 66.66px;
  width: auto; }
  @media (min-width: 1200px) {
    /* line 18, src/assets/scss/_cards.scss */
    a.card-download-pdf .icon-folder {
      height: 134px;
      width: auto;
      max-width: 168px; } }

/* line 31, src/assets/scss/_cards.scss */
.card-1 .card-body p {
  font-size: 16px;
  line-height: 170%; }

/* line 43, src/assets/scss/_cards.scss */
.card-2 .card-body h6 {
  font-size: 16px; }

/* line 1, src/assets/scss/_charts.scss */
.chart-title {
  font-size: 13px;
  text-transform: uppercase;
  text-align: center; }
  @media (min-width: 768px) {
    /* line 1, src/assets/scss/_charts.scss */
    .chart-title {
      font-size: 18px; } }
  @media (min-width: 992px) {
    /* line 1, src/assets/scss/_charts.scss */
    .chart-title {
      font-size: 24px; } }

/* line 13, src/assets/scss/_charts.scss */
.chart-copy {
  font-size: 16px; }

/* line 17, src/assets/scss/_charts.scss */
.chart-desc {
  font-size: 16px; }

/* line 21, src/assets/scss/_charts.scss */
.chart-label {
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    /* line 21, src/assets/scss/_charts.scss */
    .chart-label {
      font-size: 21px;
      line-height: 24px; } }

/* line 31, src/assets/scss/_charts.scss */
.chart-perc {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    /* line 31, src/assets/scss/_charts.scss */
    .chart-perc {
      font-size: 38px;
      line-height: 42px; } }

/* line 44, src/assets/scss/_charts.scss */
.chart-legend-button {
  cursor: pointer;
  white-space: nowrap;
  padding-right: 1.0rem; }
  /* line 49, src/assets/scss/_charts.scss */
  .chart-legend-button .chart-legend-dot {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 5px; }
  /* line 58, src/assets/scss/_charts.scss */
  .chart-legend-button .chart-legend-dash {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 12px;
    margin-right: 5px;
    background-image: linear-gradient(to right, #544F40 0%, #544F40 50%, #fff 50%, #fff 100%);
    background-position: center;
    background-size: 8px 2px;
    background-repeat: repeat-x; }
  /* line 70, src/assets/scss/_charts.scss */
  .chart-legend-button span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 700;
    color: #333333; }
  /* line 78, src/assets/scss/_charts.scss */
  .chart-legend-button.hide {
    color: #ccc !important; }
    /* line 80, src/assets/scss/_charts.scss */
    .chart-legend-button.hide .chart-legend-dot {
      background-color: #ccc !important; }
    /* line 83, src/assets/scss/_charts.scss */
    .chart-legend-button.hide .chart-legend-dash {
      background-image: linear-gradient(to right, #CCC 0%, #CCC 50%, #fff 50%, #fff 100%); }
    /* line 86, src/assets/scss/_charts.scss */
    .chart-legend-button.hide span {
      color: #ccc !important; }

/* line 1, src/assets/scss/_tabs.scss */
.nav-tabs {
  border: none;
  margin-bottom: 1rem; }
  /* line 5, src/assets/scss/_tabs.scss */
  .nav-tabs .nav-link {
    margin-bottom: -2px;
    border: none;
    border-left: 0.5px solid #EFEFED;
    border-bottom: 2px solid #EFEFED;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #878379;
    background-color: #FFFFFF; }
  /* line 16, src/assets/scss/_tabs.scss */
  .nav-tabs .nav-item:first-child .nav-link {
    border-left: none; }
  /* line 20, src/assets/scss/_tabs.scss */
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-bottom: 2px solid #15717D;
    color: #15717D; }

/* line 30, src/assets/scss/_tabs.scss */
#purchasing-trends-tabs .nav-tabs {
  border: none;
  margin-bottom: 2px; }
  /* line 34, src/assets/scss/_tabs.scss */
  #purchasing-trends-tabs .nav-tabs .nav-link {
    margin-bottom: -2px;
    border: none;
    border-bottom: 2px solid #EFEFED;
    border-radius: 0;
    color: #EFEFED;
    background-color: #FFFFFF; }
  /* line 43, src/assets/scss/_tabs.scss */
  #purchasing-trends-tabs .nav-tabs .nav-item:first-child .nav-link {
    border-left: none; }
  /* line 47, src/assets/scss/_tabs.scss */
  #purchasing-trends-tabs .nav-tabs .nav-item.show .nav-link,
  #purchasing-trends-tabs .nav-tabs .nav-link.active,
  #purchasing-trends-tabs .nav-tabs .nav-link:focus,
  #purchasing-trends-tabs .nav-tabs .nav-link:hover {
    border-bottom: 2px solid #15717D;
    color: #15717D; }

/* line 2, src/assets/scss/_team.scss */
.team-member img.team-member-photo {
  max-width: 120px; }
  @media (min-width: 992px) {
    /* line 2, src/assets/scss/_team.scss */
    .team-member img.team-member-photo {
      max-width: 138px; } }

/* line 9, src/assets/scss/_team.scss */
.team-member p.team-member-name {
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 20px; }
  /* line 12, src/assets/scss/_team.scss */
  .team-member p.team-member-name::before {
    content: "";
    width: 120px;
    height: 4px;
    display: block;
    background-color: #F36633;
    margin-bottom: 8px; }

/* line 21, src/assets/scss/_team.scss */
.team-member p.team-member-role {
  font-size: 20px; }

/* line 25, src/assets/scss/_team.scss */
.team-member a.team-member-email {
  position: relative;
  padding-left: 1.5rem; }
  /* line 28, src/assets/scss/_team.scss */
  .team-member a.team-member-email::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2.5px;
    width: 1.5rem;
    height: 10px;
    background-image: url("/assets/img/icons/icon_envelope.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 10px;
    display: block; }

/* line 42, src/assets/scss/_team.scss */
.team-member a.team-member-phone {
  position: relative;
  padding-left: 1.5rem; }
  /* line 45, src/assets/scss/_team.scss */
  .team-member a.team-member-phone::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1.5px;
    width: 1.5rem;
    height: 12.48px;
    background-image: url("/assets/img/icons/icon_mobile.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 12.48px;
    display: block; }

/* line 1, src/assets/scss/_modals.scss */
.modal-lg {
  max-width: 720px; }

/* line 5, src/assets/scss/_modals.scss */
.modal-xl {
  max-width: 970px; }

/* line 9, src/assets/scss/_modals.scss */
.modal-content, .modal-header, .modal-footer {
  border-style: solid;
  border-color: transparent; }

/* line 14, src/assets/scss/_modals.scss */
.modal-header {
  padding-bottom: 0;
  border-bottom: 0;
  display: block !important; }

/* line 20, src/assets/scss/_modals.scss */
.modal-header .modal-title {
  margin-left: auto;
  margin-right: auto; }

/* line 25, src/assets/scss/_modals.scss */
h2.modal-title {
  text-align: center;
  display: block; }

/* line 32, src/assets/scss/_modals.scss */
.modal-body {
  border-left-style: solid;
  border-left-color: transparent;
  border-right-style: solid;
  border-right-color: transparent; }

/* line 40, src/assets/scss/_modals.scss */
.modal-footer {
  padding: 1rem;
  border-top: 0;
  display: block;
  align-items: center;
  justify-content: center; }

/* line 48, src/assets/scss/_modals.scss */
.modal-footer p {
  display: inline-block;
  clear: both; }

/* line 54, src/assets/scss/_modals.scss */
.modal-footer > :not(:first-child) {
  margin-left: auto; }

/* line 58, src/assets/scss/_modals.scss */
.modal-footer > :not(:last-child) {
  margin-right: auto; }

/* line 63, src/assets/scss/_modals.scss */
.modal-backdrop.show {
  opacity: 1.0; }

/* line 67, src/assets/scss/_modals.scss */
.modal-backdrop.modal-backdrop-black.show {
  opacity: 1.0; }

/* line 72, src/assets/scss/_modals.scss */
.modal-backdrop {
  background-color: #000; }

@media (min-width: 768px) {
  /* line 77, src/assets/scss/_modals.scss */
  .modal-contact-details {
    min-height: 220px; } }

/* line 1, src/assets/scss/_tooltip.scss */
.tooltip-trigger {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-image: url("/assets/img/icons/icon_info.svg");
  background-repeat: no-repeat;
  background-position: center; }

/* line 11, src/assets/scss/_tooltip.scss */
.tooltip {
  z-index: 1080;
  font-size: 0.75rem; }

/* line 16, src/assets/scss/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  /* line 29, src/assets/scss/_tooltip.scss */
  .tooltip.show {
    opacity: 0.9; }
  /* line 31, src/assets/scss/_tooltip.scss */
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    /* line 37, src/assets/scss/_tooltip.scss */
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 46, src/assets/scss/_tooltip.scss */
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  /* line 49, src/assets/scss/_tooltip.scss */
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    /* line 52, src/assets/scss/_tooltip.scss */
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #544F40; }

/* line 60, src/assets/scss/_tooltip.scss */
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  /* line 63, src/assets/scss/_tooltip.scss */
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 68, src/assets/scss/_tooltip.scss */
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #544F40; }

/* line 76, src/assets/scss/_tooltip.scss */
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  /* line 79, src/assets/scss/_tooltip.scss */
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    /* line 82, src/assets/scss/_tooltip.scss */
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #544F40; }

/* line 90, src/assets/scss/_tooltip.scss */
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  /* line 93, src/assets/scss/_tooltip.scss */
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 98, src/assets/scss/_tooltip.scss */
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #544F40; }

/* line 122, src/assets/scss/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #544F40;
  border-radius: 0.25rem; }

/* line 1, src/assets/scss/_backgrounds.scss */
.bg-gradient {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

/* line 7, src/assets/scss/_backgrounds.scss */
.bg-gradient-1 {
  background-image: url("/assets/img/backgrounds/gradient1.jpg"); }

/* line 11, src/assets/scss/_backgrounds.scss */
.bg-gradient-2 {
  background-image: url("/assets/img/backgrounds/gradient2.jpg"); }

/* line 15, src/assets/scss/_backgrounds.scss */
.bg-gradient-3 {
  background-image: url("/assets/img/backgrounds/gradient3.jpg"); }

/* line 19, src/assets/scss/_backgrounds.scss */
.bg-gradient-4 {
  background-image: url("/assets/img/backgrounds/gradient4.jpg"); }

/* line 23, src/assets/scss/_backgrounds.scss */
.bg-gradient-5 {
  background-image: url("/assets/img/backgrounds/gradient5.jpg"); }

/* line 27, src/assets/scss/_backgrounds.scss */
.bg-gradient-6 {
  background-image: url("/assets/img/backgrounds/gradient6.jpg"); }

/* line 31, src/assets/scss/_backgrounds.scss */
.bg-gradient-orange {
  background: #F36633;
  background: linear-gradient(90deg, #ea5f1e 0%, #f39517 100%); }

/* line 3, src/assets/scss/_tables.scss */
.table {
  background-color: #FFFFFF; }

/* line 6, src/assets/scss/_tables.scss */
.table .thead-light th {
  color: #544F40;
  background-color: #EFEFED;
  border-color: transparent;
  border-bottom: 2px solid #F36633; }

/* line 17, src/assets/scss/_tables.scss */
.tablesorter-default .header, .tablesorter-default .tablesorter-header {
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  background-position: center right;
  background-repeat: no-repeat;
  cursor: pointer;
  white-space: normal; }

/* line 19, src/assets/scss/_tables.scss */
.tablesorter-default thead .headerSortUp, .tablesorter-default thead .tablesorter-headerAsc, .tablesorter-default thead .tablesorter-headerSortUp {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7); }

/* line 21, src/assets/scss/_tables.scss */
.tablesorter-default thead .headerSortDown, .tablesorter-default thead .tablesorter-headerDesc, .tablesorter-default thead .tablesorter-headerSortDown {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7); }

/* line 23, src/assets/scss/_tables.scss */
.tablesorter-default thead .sorter-false {
  background-image: none;
  cursor: default; }

/* line 25, src/assets/scss/_tables.scss */
.tablesorter-hidden {
  display: none; }

/* line 27, src/assets/scss/_tables.scss */
table.table-style-4 {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 15px; }

/* line 32, src/assets/scss/_tables.scss */
table.tablesorter.table-style-4 thead tr .header {
  background-position: left center; }

/* line 37, src/assets/scss/_tables.scss */
table.tablesorter.table-style-4 thead tr .header {
  background-position: left center;
  background-image: url(../img/bg-white.gif); }

/* line 41, src/assets/scss/_tables.scss */
table.tablesorter.table-style-4 thead tr .headerSortDown {
  background-image: url(../img/desc-white.gif); }

/* line 44, src/assets/scss/_tables.scss */
table.tablesorter.table-style-4 thead tr .headerSortUp {
  background-image: url(../img/asc-white.gif); }

/* line 49, src/assets/scss/_tables.scss */
table.table-style-4 th {
  background-color: #f36633;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 1.2em;
  text-align: center;
  border-left: 1px solid #F0EFED; }

/* line 62, src/assets/scss/_tables.scss */
table.table-style-4 th:first-of-type {
  border-left: 0; }

/* line 65, src/assets/scss/_tables.scss */
table.table-style-4 td {
  padding: 15px 20px;
  position: relative;
  /*border-left: 1px solid #E0DFDD;*/ }

/* line 70, src/assets/scss/_tables.scss */
table.table-style-4 td:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 25%;
  height: 50%;
  border-left: 1px solid #E0DFDD; }

/* line 80, src/assets/scss/_tables.scss */
table.table-style-4 td:first-of-type:before {
  border-left: 0; }

/* line 83, src/assets/scss/_tables.scss */
table.table-style-4 tbody tr {
  background-color: #F0EFED;
  border-bottom: 1px solid #E0DFDD; }

/* line 87, src/assets/scss/_tables.scss */
table.table-style-4 {
  border-bottom: 3px solid #f36633; }

/* line 90, src/assets/scss/_tables.scss */
table.table-style-4 td input[type="checkbox"] {
  margin-left: 0;
  margin-right: 8px; }

/* line 96, src/assets/scss/_tables.scss */
table.table-style-4 tbody tr.pcn-row {
  background-color: #F0EFED88; }

/* line 101, src/assets/scss/_tables.scss */
tr.pcn-row td:first-of-type {
  padding-left: 40px; }

/* CHANGES FROM HERE */
/* line 108, src/assets/scss/_tables.scss */
table.table-style-4 .select2-container--default .select2-selection--multiple {
  height: auto;
  min-height: 47px; }

/* line 113, src/assets/scss/_tables.scss */
table.table-style-4 .select2-container--default .select2-selection--multiple .select2-search__field {
  line-height: 50px; }

/* line 117, src/assets/scss/_tables.scss */
table.table-style-4 .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 10px 5px 0 0; }

/* line 123, src/assets/scss/_tables.scss */
.discount-table caption {
  color: #F36633;
  font-size: 1.0rem;
  font-weight: bold;
  caption-side: top; }

/* line 130, src/assets/scss/_tables.scss */
.discount-table {
  font-size: 0.875rem; }

/* line 1, src/assets/scss/_alerts.scss */
.alert {
  border-radius: 0; }

/* line 15, src/assets/scss/_alerts.scss */
.alert.alert-1.text-white p a {
  color: #FFFFFF;
  text-decoration: underline; }

@font-face {
  font-family: 'gsk-icons';
  src: url("../webfonts/gsk-icons.eot?p0wel0");
  src: url("../webfonts/gsk-icons.eot?p0wel0#iefix") format("embedded-opentype"), url("../webfonts/gsk-icons.ttf?p0wel0") format("truetype"), url("../webfonts/gsk-icons.woff?p0wel0") format("woff"), url("../webfonts/gsk-icons.svg?p0wel0#gsk-icons") format("svg");
  font-weight: 400;
  font-style: normal; }

/* line 9, src/assets/scss/_icons.scss */
.icon {
  font-family: "gsk-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 21, src/assets/scss/_icons.scss */
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 24px;
  vertical-align: inherit; }

/* line 28, src/assets/scss/_icons.scss */
.icon--orange {
  color: #F36633; }

/* line 31, src/assets/scss/_icons.scss */
.icon--grey {
  color: #72635D; }

/* line 34, src/assets/scss/_icons.scss */
.icon--green {
  color: #54A41C; }

/* line 37, src/assets/scss/_icons.scss */
.icon--green-light {
  color: #b8cf29; }

/* line 40, src/assets/scss/_icons.scss */
.icon--blue {
  color: #0065B2; }

/* line 43, src/assets/scss/_icons.scss */
.icon--white {
  color: #fff; }

/* line 63, src/assets/scss/_icons.scss */
.icon--xs {
  width: 12px;
  height: 12px;
  font-size: 12px; }

/* line 68, src/assets/scss/_icons.scss */
.icon--sm {
  width: 18px;
  height: 18px;
  font-size: 18px; }

/* line 73, src/assets/scss/_icons.scss */
.icon--md {
  width: 36px;
  height: 36px;
  font-size: 36px; }

/* line 78, src/assets/scss/_icons.scss */
.icon--lg {
  width: 48px;
  height: 48px;
  font-size: 48px; }

/* line 83, src/assets/scss/_icons.scss */
.icon--xl {
  width: 72px;
  height: 72px;
  font-size: 72px; }

/* line 88, src/assets/scss/_icons.scss */
.icon--delivery-so.icon--sm {
  width: 24px;
  height: 24px;
  font-size: 24px; }

/* line 94, src/assets/scss/_icons.scss */
.icon--cancel:before {
  content: "\e900"; }

/* line 97, src/assets/scss/_icons.scss */
.icon--chevron-down:before {
  content: "\e901"; }

/* line 100, src/assets/scss/_icons.scss */
.icon--clock:before {
  content: "\e902"; }

/* line 103, src/assets/scss/_icons.scss */
.icon--confirm-thin:before {
  content: "\e903"; }

/* line 106, src/assets/scss/_icons.scss */
.icon--dashboard:before {
  content: "\e904"; }

/* line 109, src/assets/scss/_icons.scss */
.icon--delivery-so:before {
  content: "\e905"; }

/* line 112, src/assets/scss/_icons.scss */
.icon--delivery-thin:before {
  content: "\e906"; }

/* line 115, src/assets/scss/_icons.scss */
.icon--delivery:before {
  content: "\e907"; }

/* line 118, src/assets/scss/_icons.scss */
.icon--document:before {
  content: "\e908"; }

/* line 121, src/assets/scss/_icons.scss */
.icon--download-thin:before {
  content: "\e909"; }

/* line 124, src/assets/scss/_icons.scss */
.icon--download:before {
  content: "\e90a"; }

/* line 127, src/assets/scss/_icons.scss */
.icon--envelope:before {
  content: "\e90b"; }

/* line 130, src/assets/scss/_icons.scss */
.icon--eye:before {
  content: "\e90c"; }

/* line 133, src/assets/scss/_icons.scss */
.icon--live-chat:before {
  content: "\e90d"; }

/* line 136, src/assets/scss/_icons.scss */
.icon--logout:before {
  content: "\e90e"; }

/* line 139, src/assets/scss/_icons.scss */
.icon--magnifier:before {
  content: "\e90f"; }

/* line 142, src/assets/scss/_icons.scss */
.icon--man:before {
  content: "\e910"; }

/* line 145, src/assets/scss/_icons.scss */
.icon--manage-so:before {
  content: "\e911"; }

/* line 148, src/assets/scss/_icons.scss */
.icon--map-marker:before {
  content: "\e912"; }

/* line 151, src/assets/scss/_icons.scss */
.icon--minus:before {
  content: "\e913"; }

/* line 154, src/assets/scss/_icons.scss */
.icon--money-thin:before {
  content: "\e914"; }

/* line 157, src/assets/scss/_icons.scss */
.icon--needle:before {
  content: "\e915"; }

/* line 160, src/assets/scss/_icons.scss */
.icon--next-stroke:before {
  content: "\e916"; }

/* line 163, src/assets/scss/_icons.scss */
.icon--next:before {
  content: "\e917"; }

/* line 166, src/assets/scss/_icons.scss */
.icon--nude-tick:before {
  content: "\e918"; }

/* line 169, src/assets/scss/_icons.scss */
.icon--online-orders:before {
  content: "\e919"; }

/* line 172, src/assets/scss/_icons.scss */
.icon--online-thin:before {
  content: "\e91a"; }

/* line 175, src/assets/scss/_icons.scss */
.icon--order-filled:before {
  content: "\e91b"; }

/* line 178, src/assets/scss/_icons.scss */
.icon--padlock:before {
  content: "\e91c"; }

/* line 181, src/assets/scss/_icons.scss */
.icon--percentage-screen:before {
  content: "\e91d"; }

/* line 184, src/assets/scss/_icons.scss */
.icon--percentage-star:before {
  content: "\e91e"; }

/* line 187, src/assets/scss/_icons.scss */
.icon--percentage-thin:before {
  content: "\e91f"; }

/* line 190, src/assets/scss/_icons.scss */
.icon--phone-thin:before {
  content: "\e920"; }

/* line 193, src/assets/scss/_icons.scss */
.icon--phone:before {
  content: "\e921"; }

/* line 196, src/assets/scss/_icons.scss */
.icon--play-filled:before {
  content: "\e922"; }

/* line 199, src/assets/scss/_icons.scss */
.icon--plus:before {
  content: "\e923"; }

/* line 202, src/assets/scss/_icons.scss */
.icon--previous:before {
  content: "\e924"; }

/* line 205, src/assets/scss/_icons.scss */
.icon--printer:before {
  content: "\e925"; }

/* line 208, src/assets/scss/_icons.scss */
.icon--products-thin:before {
  content: "\e926"; }

/* line 211, src/assets/scss/_icons.scss */
.icon--profile-thin:before {
  content: "\e927"; }

/* line 214, src/assets/scss/_icons.scss */
.icon--profile:before {
  content: "\e928"; }

/* line 217, src/assets/scss/_icons.scss */
.icon--profit-filled:before {
  content: "\e929"; }

/* line 220, src/assets/scss/_icons.scss */
.icon--question:before {
  content: "\e92a"; }

/* line 223, src/assets/scss/_icons.scss */
.icon--split:before {
  content: "\e92b"; }

/* line 226, src/assets/scss/_icons.scss */
.icon--star:before {
  content: "\e92c"; }

/* line 229, src/assets/scss/_icons.scss */
.icon--tick-filled:before {
  content: "\e92d"; }

/* line 232, src/assets/scss/_icons.scss */
.icon--tick:before {
  content: "\e92e"; }

/* line 235, src/assets/scss/_icons.scss */
.icon--trends:before {
  content: "\e92f"; }

/* line 238, src/assets/scss/_icons.scss */
.icon--volume-discount-filled:before {
  content: "\e930"; }

/* line 241, src/assets/scss/_icons.scss */
.icon--volume-discount-thin:before {
  content: "\e931"; }

/* line 244, src/assets/scss/_icons.scss */
.icon--woman:before {
  content: "\e932"; }

/* line 2, src/assets/scss/_legacy.scss */
.progress {
  list-style-type: none;
  margin: 0;
  padding: 0;
  letter-spacing: -6px; }

/* line 8, src/assets/scss/_legacy.scss */
.progress li {
  letter-spacing: normal; }

/* line 11, src/assets/scss/_legacy.scss */
.progress > li {
  display: inline-block;
  vertical-align: top; }

/* line 15, src/assets/scss/_legacy.scss */
.progress__item {
  width: 50px; }

@media screen and (min-width: 576px) {
  /* line 19, src/assets/scss/_legacy.scss */
  .progress__item {
    width: 70px; } }

@media screen and (min-width: 992px) {
  /* line 24, src/assets/scss/_legacy.scss */
  .progress__item {
    width: 170px; } }

/* line 33, src/assets/scss/_legacy.scss */
.progress__link {
  display: block;
  position: relative;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  color: #F0EFED;
  cursor: default; }

@media screen and (min-width: 576px) {
  /* line 45, src/assets/scss/_legacy.scss */
  .progress__link {
    font-size: 1.0rem;
    margin: 10px; } }

@media screen and (min-width: 992px) {
  /* line 53, src/assets/scss/_legacy.scss */
  .progress__link {
    margin: 0;
    font-size: 1.5rem; } }

/* line 59, src/assets/scss/_legacy.scss */
.progress__link:hover {
  text-decoration: none;
  color: #D5D1CE; }

/* line 63, src/assets/scss/_legacy.scss */
.progress__link:hover .progress__icon {
  color: #D5D1CE; }

/* line 66, src/assets/scss/_legacy.scss */
.progress__icon {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 36px;
  margin-right: 15px;
  margin-bottom: 0.5rem;
  color: #F0EFED; }

/* line 75, src/assets/scss/_legacy.scss */
.progress__link--active {
  color: #F36633; }

/* line 78, src/assets/scss/_legacy.scss */
.main-wrapper--flu .progress__link--active {
  color: #1DAABA; }

/* line 81, src/assets/scss/_legacy.scss */
.progress__link--active .progress__icon {
  color: #F36633; }

/* line 84, src/assets/scss/_legacy.scss */
.main-wrapper--flu .progress__link--active .progress__icon {
  color: #1DAABA; }

/* line 87, src/assets/scss/_legacy.scss */
.progress__link--active:hover {
  color: #F36633; }

/* line 90, src/assets/scss/_legacy.scss */
.main-wrapper--flu .progress__link--active:hover {
  color: #1DAABA; }

/* line 93, src/assets/scss/_legacy.scss */
.progress__link--active:hover .progress__icon {
  color: #F36633; }

/* line 96, src/assets/scss/_legacy.scss */
.main-wrapper--flu .progress__link--active:hover .progress__icon {
  color: #1DAABA; }

/* line 99, src/assets/scss/_legacy.scss */
.progress__ticked {
  position: absolute;
  left: -5px;
  bottom: -1px;
  background-color: #fff;
  border-radius: 100%; }

/* line 106, src/assets/scss/_legacy.scss */
.progress__link--active {
  cursor: default; }

/* line 109, src/assets/scss/_legacy.scss */
.progress__link--complete {
  color: #F36633;
  cursor: pointer; }

/* line 113, src/assets/scss/_legacy.scss */
.progress__link--complete .progress__icon {
  color: #F36633; }

/* line 116, src/assets/scss/_legacy.scss */
.progress__link--complete:hover, .progress__link--complete:hover .progress__icon {
  color: #F36633; }

/* line 119, src/assets/scss/_legacy.scss */
.btn__cart-value {
  display: block;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.1; }

/* line 125, src/assets/scss/_legacy.scss */
.btn__next-step {
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1; }

/* line 131, src/assets/scss/_legacy.scss */
.table .order__id--delivered {
  color: #15717D; }

/* line 136, src/assets/scss/_legacy.scss */
.product-amounts__callout-1 {
  display: block;
  background: none;
  color: #72635D;
  font-size: 0.8125em;
  font-weight: 400;
  height: 17px;
  line-height: 17px;
  margin-top: 17px;
  margin-bottom: -17px;
  padding: 0;
  width: 100%; }

/* line 150, src/assets/scss/_legacy.scss */
.table--product-amounts .text--product-amount-oldvalue {
  font-size: 0.9125em; }

/* line 154, src/assets/scss/_legacy.scss */
.product-amounts__callout-2 {
  display: block;
  background: none;
  color: #f36633;
  font-size: 1.25rem;
  font-weight: 400;
  height: 34px;
  line-height: 34px;
  margin-top: 17px;
  padding: 0;
  width: 100%; }

/* line 167, src/assets/scss/_legacy.scss */
.table--product-amounts .input--product-amount-oneoff {
  background-color: #f36633;
  border-color: #f36633;
  color: #fff; }

/* line 176, src/assets/scss/_legacy.scss */
.table tr.table--row-border-bottom {
  border-bottom: 1px solid #EDEBE8; }

/* line 180, src/assets/scss/_legacy.scss */
.table td.table-label-flag {
  font-size: 0.75em;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center; }

/* line 187, src/assets/scss/_legacy.scss */
.table-label-flag--next {
  border: 1px solid #f36633;
  background: #f36633;
  color: #fff; }

/* line 194, src/assets/scss/_legacy.scss */
.table td.table-delivery-date {
  text-align: center;
  font-size: 0.9125em; }

/* line 199, src/assets/scss/_legacy.scss */
.table-delivery-date--year {
  font-size: 0.75em;
  padding-top: 2px; }

/* line 204, src/assets/scss/_legacy.scss */
.table td.table-product-amount {
  font-size: 1.5rem;
  padding: 5px 2px;
  text-align: center;
  color: #EDEBE8; }

/* line 211, src/assets/scss/_legacy.scss */
.flag--next-border {
  border-left: 1px solid #f36633; }

/* line 215, src/assets/scss/_legacy.scss */
.table--my-orders td.table-month-col, .table--my-orders-key td.table-month-col {
  padding: 0 2px;
  text-align: center;
  width: 50px; }

/* line 221, src/assets/scss/_legacy.scss */
.table th.table-month-col {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }

/* line 228, src/assets/scss/_legacy.scss */
.table td.table-product-amount--recurring {
  color: #72635d; }

/* line 231, src/assets/scss/_legacy.scss */
.table td.table-product-amount--oneoff {
  background-color: #f36633;
  border-color: #f36633;
  color: #fff; }

/* line 236, src/assets/scss/_legacy.scss */
.table td.table-product-amount--outofstock {
  color: #72635d;
  /*text-decoration: line-through;*/ }

/* line 241, src/assets/scss/_legacy.scss */
.table td.table-product-amount--outofstock strike {
  position: relative;
  text-decoration: none; }

/* line 246, src/assets/scss/_legacy.scss */
.table td.table-product-amount--outofstock strike::after {
  content: '';
  border-bottom: 2px solid #72635d;
  position: absolute;
  left: 0;
  top: 49%;
  width: 140%;
  margin-left: -20%; }

/* line 256, src/assets/scss/_legacy.scss */
.table--my-orders-key {
  font-size: 0.875em; }

/* line 260, src/assets/scss/_legacy.scss */
.sheet--my-orders-key {
  padding-bottom: 20px;
  margin-top: 30px; }

/* line 266, src/assets/scss/_legacy.scss */
.tabbed__tabs {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }

/* line 271, src/assets/scss/_legacy.scss */
.tabbed__tabs > li, .tabbed__tabs > li > a {
  position: relative;
  display: block; }

/* line 275, src/assets/scss/_legacy.scss */
.tabbed__tabs > li > a {
  padding: 20px; }

/* line 278, src/assets/scss/_legacy.scss */
.tabbed__tabs > li > a:hover, .tabbed__tabs > li > a:focus {
  text-decoration: none; }

/* line 281, src/assets/scss/_legacy.scss */
.tabbed__tabs > li.disabled > a:hover, .tabbed__tabs > li.disabled > a:focus {
  text-decoration: none;
  background-color: transparent; }

/* line 285, src/assets/scss/_legacy.scss */
.tabbed__tabs > li {
  float: left;
  margin-bottom: -1px; }

/* line 289, src/assets/scss/_legacy.scss */
.tabbed__tabs > li > a {
  margin-right: 2px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #D5D1CE;
  text-decoration: none; }

/* line 296, src/assets/scss/_legacy.scss */
.tabbed__tabs > li.active > a, .tabbed__tabs > li.active > a:hover, .tabbed__tabs > li.active > a:focus {
  border-bottom-color: transparent;
  cursor: default;
  background-color: #fff;
  color: #F36633;
  border-left: 1px solid #D5D1CE;
  outline: none; }

/* line 304, src/assets/scss/_legacy.scss */
.tabbed__content > .tab-pane {
  display: none; }

/* line 307, src/assets/scss/_legacy.scss */
.tabbed__content > .active {
  display: block; }
