//legacy bits plus edits
.progress {
    list-style-type: none;
    margin: 0;
    padding: 0;
    letter-spacing: -6px
}
.progress li {
    letter-spacing: normal
}
.progress>li {
    display: inline-block;
    vertical-align: top
}
.progress__item {
  width: 50px
}
@media screen and (min-width: 576px) {
  .progress__item {
    width: 70px
}
}
@media screen and (min-width: 992px) {
  .progress__item {
    width: 170px
}
}
// @media screen and (min-width: 1200px) {
//   .progress__item {
//     width: auto
// }
// }
.progress__link {
    display: block;
    position: relative;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    color: #F0EFED;
    cursor: default
}

@media screen and (min-width: 576px) {
  .progress__link {
    font-size: 1.0rem;
    margin: 10px;
  }
}


@media screen and (min-width: 992px) {
  .progress__link {
      margin: 0;
      font-size: 1.5rem;
  }
}

.progress__link:hover {
    text-decoration: none;
    color: #D5D1CE
}
.progress__link:hover .progress__icon {
    color: #D5D1CE
}
.progress__icon {
    position: relative;
    width: 36px;
    height: 36px;
    font-size: 36px;
    margin-right: 15px;
    margin-bottom: 0.5rem;
    color: #F0EFED
}
.progress__link--active {
    color: #F36633
}
.main-wrapper--flu .progress__link--active {
    color: #1DAABA
}
.progress__link--active .progress__icon {
    color: #F36633
}
.main-wrapper--flu .progress__link--active .progress__icon {
    color: #1DAABA
}
.progress__link--active:hover {
    color: #F36633
}
.main-wrapper--flu .progress__link--active:hover {
    color: #1DAABA
}
.progress__link--active:hover .progress__icon {
    color: #F36633
}
.main-wrapper--flu .progress__link--active:hover .progress__icon {
    color: #1DAABA
}
.progress__ticked {
    position: absolute;
    left: -5px;
    bottom: -1px;
    background-color: #fff;
    border-radius: 100%
}
.progress__link--active {
    cursor: default
}
.progress__link--complete {
    color: $gsk-primary; //#008A00; //#54A41C;
    cursor: pointer
}
.progress__link--complete .progress__icon {
    color: $gsk-primary; //#008A00; //#54A41C;
}
.progress__link--complete:hover, .progress__link--complete:hover .progress__icon {
    color: $gsk-primary; //#008A00; //#54A41C;
}
.btn__cart-value {
    display: block;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.1
}
.btn__next-step {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1
}
.table .order__id--delivered {
  color: $gsk-teal;
}

//standing orders legacy bits
.product-amounts__callout-1 {
	display: block;
    background: none;
	color: #72635D;
    font-size: 0.8125em;
    font-weight: 400;
    height: 17px;
    line-height: 17px;
    margin-top: 17px;
	margin-bottom: -17px;
    padding: 0;
    width: 100%;
}

.table--product-amounts .text--product-amount-oldvalue {
	font-size: 0.9125em;
}

.product-amounts__callout-2 {
	display: block;
    background: none;
    color: #f36633;
    font-size: 1.25rem;
    font-weight: 400;
    height: 34px;
    line-height: 34px;
    margin-top: 17px;
    padding: 0;
    width: 100%;
}

.table--product-amounts .input--product-amount-oneoff {
	background-color: #f36633;
	border-color: #f36633;
	color: #fff;
}




.table tr.table--row-border-bottom {
 	border-bottom: 1px solid #EDEBE8;
}

.table td.table-label-flag {
	font-size: 0.75em;
	padding-top: 3px;
	padding-bottom: 3px;
	text-align: center;
}

.table-label-flag--next {
	border: 1px solid #f36633;
	background: #f36633;
	color: #fff;
}


.table td.table-delivery-date {
	text-align: center;
	font-size: 0.9125em;
}

.table-delivery-date--year {
	font-size: 0.75em;
	padding-top: 2px;
}

.table td.table-product-amount {
    font-size: 1.5rem;
    padding: 5px 2px;
    text-align: center;
	color: #EDEBE8;
}

.flag--next-border {
	border-left: 1px solid #f36633;
}

.table--my-orders td.table-month-col, .table--my-orders-key td.table-month-col {
    padding: 0 2px;
    text-align: center;
    width: 50px;
}

.table th.table-month-col {
	font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.table td.table-product-amount--recurring {
	color: #72635d;
}
.table td.table-product-amount--oneoff {
	background-color: #f36633;
	border-color: #f36633;
	color: #fff;
}
.table td.table-product-amount--outofstock {
	color: #72635d;
	/*text-decoration: line-through;*/
}

.table td.table-product-amount--outofstock strike {
    position: relative;
	text-decoration: none;
}

.table td.table-product-amount--outofstock strike::after{
    content: '';
    border-bottom: 2px solid #72635d;
    position: absolute;
    left: 0;
    top: 49%;
    width: 140%;
	margin-left: -20%;
}

.table--my-orders-key {
	font-size: 0.875em;
}

.sheet--my-orders-key {
	padding-bottom: 20px;
	margin-top: 30px;
}

// purchasing trends legacy bits
.tabbed__tabs {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}
.tabbed__tabs>li, .tabbed__tabs>li>a {
    position: relative;
    display: block
}
.tabbed__tabs>li>a {
    padding: 20px
}
.tabbed__tabs>li>a:hover, .tabbed__tabs>li>a:focus {
    text-decoration: none
}
.tabbed__tabs>li.disabled>a:hover, .tabbed__tabs>li.disabled>a:focus {
    text-decoration: none;
    background-color: transparent
}
.tabbed__tabs>li {
    float: left;
    margin-bottom: -1px
}
.tabbed__tabs>li>a {
    margin-right: 2px;
    font-size: 1.5rem;
    font-weight: 700;
    color: #D5D1CE;
    text-decoration: none
}
.tabbed__tabs>li.active>a, .tabbed__tabs>li.active>a:hover, .tabbed__tabs>li.active>a:focus {
    border-bottom-color: transparent;
    cursor: default;
    background-color: #fff;
    color: #F36633;
    border-left: 1px solid #D5D1CE;
    outline: none
}
.tabbed__content>.tab-pane {
    display: none
}
.tabbed__content>.active {
    display: block
}
