button,
input,
optgroup,
option,
select,
textarea {
	font-family: arial, sans-serif;
	color: $gsk-dark-grey;
}
// ::placeholder {
//   color: $surface-medium;
//   opacity: 1;
// }
//
// :-ms-input-placeholder {
//   color: $surface-medium;
// }
//
// ::-ms-input-placeholder {
//   color: $surface-medium;
// }
//
// .form-control::placeholder {
// 	color: $surface-medium;
// 	opacity: 1;
// }

.form-group:not(.form-group-sm) {
	margin-bottom: 22px;

	.form-control {
		border-radius: 0;
		min-height: 56px;
		font-size: 20px;
		line-height: 140%;
		border-color: $surface-light;
		background-color: $surface-light;
	}

	.form-control.form-style-2 {
		border-radius: 4px;
		min-height: 56px;
		font-size: 20px;
		line-height: 140%;
		border-color: $gsk-teal;
		background-color: $white;
	}

	label:not(.custom-control-label) {
		margin-bottom: 0;
		font-family: arial, sans-serif; font-weight: 700;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		background-image: url("/assets/img/icons/chevron_down.svg");
		background-repeat: no-repeat;
		background-position: center right 1rem;
		// border: 1px solid #dfdfdf;
		padding-right: 2rem;

		&:focus {
			background-image: url("/assets/img/icons/chevron_up.svg");
		}

		option {
			font-size: 18px;
		}
	}

	.bootstrap-datetimepicker-widget table td,
	.bootstrap-datetimepicker-widget table th {
		font-size: 70%;
	}
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0;
}

//Rangeslider
$roundness: 5px;
$sliderHeight: 4px;
$handleSize: 20px;
$valueWidth: 50px;
$labelMarkerHeight: 12px;

.rangeslider-wrap {
	padding-top: 50px;
	padding-bottom: 30px;

	.rangeslider {
		position: relative;
		height: $sliderHeight;
		border-radius: $roundness;
		width: 100%;
		background-color: $surface-medium;

		&__handle {
			transition: background-color 0.2s;
			box-sizing: border-box;
			width: $handleSize;
			height: $handleSize;
			border-radius: 100%;
			background-color: $gsk-teal;
			border: 2px solid $white;
			touch-action: pan-y;
			cursor: pointer;
			display: inline-block;
			position: absolute;
			z-index: 3;
			top: -($handleSize/2) + ($sliderHeight/2);

			&__value {
				transition: background-color 0.2s, box-shadow 0.1s, transform 0.1s;
				box-sizing: border-box;
				width: $valueWidth;
				text-align: center;
				padding: 10px;
				color: $gsk-teal;
				left: -($valueWidth/2 - $handleSize/2);
				top: -40px;
				position: absolute;
				white-space: nowrap;

				&:after {
					content: "";
				}
			}
		}

		&__fill {
			position: absolute;
			top: 0;
			z-index: 1;
			height: 100%;
			background-color: $gsk-teal;
			border-radius: $roundness;
		}

		&__labels {
			position: absolute;
			width: 100%;
			z-index: 2;
			display: flex;
			justify-content: space-between;

			&__label {
				font-size: 0.75em;
				position: relative;
				padding-top: 15px;
				color: $gsk-dark-grey;

				&:before {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					content: "";
					width: 1px;
					height: $labelMarkerHeight - 3px;
					border-radius: 1px;
					background-color: rgba($surface-medium, .5);
				}

				&:first-child:before,
				&:last-child:before {
					height: $labelMarkerHeight;
					width: 0;
				}

				&:first-child:before {
					background-color: $surface-medium;
				}

				&:last-child:before {
					background-color: $surface-medium;
				}

				&:first-child {
					// transform: translateX(-48%);
					transform: translateX(0);
				}

				&:last-child {
					// transform: translateX(48%);
					transform: translateX(0);
				}
			}
		}
		// active state
		&.rangeslider--active {
			.rangeslider__handle {}

			.rangeslider__handle__value {}
		}
	}
}

.rangeslider-wrap.percentageslider {
	.rangeslider {
		&__handle {
			&__value {
				left: -(($valueWidth/2 - $handleSize/2)+2);

				&:after {
					content: "%";
				}
			}
		}
	}

	.rangeslider__labels__label:after {
		content: "%";
	}
}

.rangeslider-wrap.greenslider {
	.rangeslider {
		&__handle {
			background-color: $gsk-green;

			&__value {
				color: $gsk-green;
			}
		}

		&__fill {
			background-color: $gsk-green;
		}
	}
}

.rangeslider-wrap.dualslider {
	padding-top: 0;

	.rangeslider {
		&__handle {
			&__value {
				top: 0 !important;
				display: none !important;
			}
		}
	}
}

.select2 {
	width: 100% !important;
}

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
	border-radius: 0;
	min-height: 56px;
	font-size: 20px;
	line-height: 140%;
	border-color: $surface-light;
	background-color: $surface-light;
	background-image: url("/assets/img/icons/chevron_down.svg");
	background-repeat: no-repeat;
	background-position: center right 1rem;
	padding: 0.375rem 2rem 0.375rem 0.75rem;
	color: $gsk-dark-grey;
}



.select2-container--default .select2-selection--multiple li.select2-selection__choice {
	background-color: $gsk-teal;
	border: 1px solid $gsk-teal;
	border-radius: 0;
	box-sizing: border-box;
	display: inline-block;
	margin: 0 5px 5px 0;
	padding-left: 2.5rem;
	position: relative;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: bottom;
	white-space: nowrap;
	color: $white;
	position: relative;
	font-size: 1em;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	background-color: transparent;
	border-right: 1px solid $white;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	color: $white;
	cursor: pointer;
	font-size: 1em;
	font-weight: bold;
	padding: 0 4px;
	position: absolute;
	left: 0;
	top: 0;
	width: 2rem;
	display: block;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	cursor: default;
	padding-left: 2px;
	padding-right: 5px;
	font-size: 16px;
}

.select2-container .select2-search--inline .select2-search__field {
	height: 28px;
	// margin-top: 5px;
	margin-left: 0;
	color: $surface-medium;
	opacity: 1;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 0;
}

.select2-search.select2-search--inline {
	display: inline-block;
	width: 80%;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: 1px solid $surface-light;
	box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25);
}

.select2-container--open {
	box-shadow: 0 0 0 0.2rem rgba(243, 102, 51, 0.25);
}

.select2-container.select2-container--open .select2-selection--multiple,
.select2-container.select2-container--open .select2-selection--single {
	background-image: url("/assets/img/icons/chevron_up.svg");
}

.select2-selection__arrow {
	display: none;
}

.select2-results__option {
	font-size: 18px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 56px;
	line-height: 42px;
	color: $gsk-dark-grey !important;
}
// .select2-container--default .select2-selection--single .select2-selection__placeholder {
//   color: #6c757d !important;
// }

.select2-container .select2-selection--single.location-select {
	// border-color: $surface-medium;
	// background-color: $surface-medium;
	border-color: transparent;
	background-color: transparent;
}

.select2-container--default .select2-selection--single.location-select .select2-selection__rendered {
	color: $gsk-primary !important;
}


.l2 {
	padding-left: 1rem;
	display: inline-block;
}

.l3 {
	padding-left: 2rem;
	display: inline-block;
}

.dropdown-back {
	cursor: pointer;
	position: absolute;
	left: 12px;
	top: 12px;
	z-index: 2;

	img {
		width: 32px;
		height: 32px;
	}
}


.large-date-input {
	font-size: 1.5rem;
	font-weight: bold;
	border: 0;
	padding: 0;
	height: auto;
	margin-top: 5px;

	&:focus {
		padding: 0 5px;
	}
}



#select2-so_product-container.select2-selection__rendered {
	max-width: 318px;
}

.select2-search.select2-search--dropdown input.select2-search__field {
	background-image: url("/assets/img/icons/icon_search.svg");
	background-repeat: no-repeat;
	background-position: center left 10px;
	background-size: auto;
	padding-left: 30px;
}
