.alert {
	border-radius: 0;
}
// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
$alert-bg-level: 1.0; // @each $color, $value in $theme-colors {
//   .alert-#{$color} {
//     @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
//   }
// }

.alert.alert-1.text-white {
	p {
		a {
			color: $white;
			text-decoration: underline;
		}
	}
}
