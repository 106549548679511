// Print pages

.table {
	background-color: $white;
}
.table .thead-light th {
  color: $gsk-dark-grey;
  background-color: $surface-medium;
  border-color: transparent;
  border-bottom: 2px solid $gsk-primary;
}



//START page

.tablesorter-default .header,.tablesorter-default .tablesorter-header{background-image:url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);background-position:center right;background-repeat:no-repeat;cursor:pointer;white-space:normal;}

.tablesorter-default thead .headerSortUp,.tablesorter-default thead .tablesorter-headerAsc,.tablesorter-default thead .tablesorter-headerSortUp{background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);}

.tablesorter-default thead .headerSortDown,.tablesorter-default thead .tablesorter-headerDesc,.tablesorter-default thead .tablesorter-headerSortDown{background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);}

.tablesorter-default thead .sorter-false{background-image:none;cursor:default;}

.tablesorter-hidden { display: none; }

table.table-style-4 {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 15px;
}
table.tablesorter.table-style-4 thead tr .header {
    background-position: left center;
}


table.tablesorter.table-style-4 thead tr .header {
    background-position: left center;
	background-image: url(../img/bg-white.gif);
}
table.tablesorter.table-style-4 thead tr .headerSortDown {
    background-image: url(../img/desc-white.gif);
}
table.tablesorter.table-style-4 thead tr .headerSortUp {
    background-image: url(../img/asc-white.gif);
}


table.table-style-4 th {
	background-color: #f36633;
	color: #fff;
	font-size: 1.3em;
	font-weight: bold;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	line-height: 1.2em;
	text-align:  center;
	border-left: 1px solid #F0EFED;
}
table.table-style-4 th:first-of-type {
	border-left: 0
}
table.table-style-4 td {
	padding: 15px 20px;
	position: relative;
	/*border-left: 1px solid #E0DFDD;*/
}
table.table-style-4 td:before {
    content: '';
    position: absolute;
    bottom: 0;
	left: 0;
    top: 25%;
    height: 50%;
    border-left: 1px solid #E0DFDD;
}

table.table-style-4 td:first-of-type:before {
	border-left: 0
}
table.table-style-4 tbody tr {
    background-color: #F0EFED;
	border-bottom: 1px solid #E0DFDD;
}
table.table-style-4 {
	border-bottom: 3px solid #f36633;
}
table.table-style-4 td input[type="checkbox"] {
    margin-left: 0;
	margin-right: 8px;
}


table.table-style-4 tbody tr.pcn-row {
    background-color: #F0EFED88;
}


tr.pcn-row td:first-of-type {
    padding-left: 40px;
}

/* CHANGES FROM HERE */


table.table-style-4 .select2-container--default .select2-selection--multiple {
    height: auto;
	min-height: 47px;
}

table.table-style-4 .select2-container--default .select2-selection--multiple .select2-search__field {
    line-height: 50px;
}

table.table-style-4 .select2-container--default .select2-selection--multiple .select2-selection__choice {
	margin: 10px 5px 0 0;
}



.discount-table caption {
  color: $gsk-primary;
	font-size: 1.0rem;
	font-weight: bold;
  caption-side: top;
}

.discount-table {
	font-size: 0.875rem;
}
