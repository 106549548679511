//Icons from old filters__item
@font-face {
    font-family: 'gsk-icons';
    src: url('../webfonts/gsk-icons.eot?p0wel0');
    src: url('../webfonts/gsk-icons.eot?p0wel0#iefix') format('embedded-opentype'), url('../webfonts/gsk-icons.ttf?p0wel0') format('truetype'), url('../webfonts/gsk-icons.woff?p0wel0') format('woff'), url('../webfonts/gsk-icons.svg?p0wel0#gsk-icons') format('svg');
    font-weight: 400;
    font-style: normal
}
.icon {
    font-family: 'gsk-icons'!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    vertical-align: inherit
}
.icon--orange {
    color: #F36633
}
.icon--grey {
    color: #72635D
}
.icon--green {
    color: #54A41C
}
.icon--green-light {
    color: #b8cf29
}
.icon--blue {
    color: #0065B2
}
.icon--white {
    color: #fff
}
// .icon--volume-discount-filled {
//     position: relative
// }
// .icon--volume-discount-filled:before {
//     position: relative;
//     z-index: 2
// }
// .icon--volume-discount-filled:after {
//     content: '';
//     position: absolute;
//     background: #fff;
//     width: 20px;
//     height: 20px;
//     top: 8px;
//     left: 8px;
//     z-index: 1
// }
.icon--xs {
    width: 12px;
    height: 12px;
    font-size: 12px
}
.icon--sm {
    width: 18px;
    height: 18px;
    font-size: 18px
}
.icon--md {
    width: 36px;
    height: 36px;
    font-size: 36px
}
.icon--lg {
    width: 48px;
    height: 48px;
    font-size: 48px
}
.icon--xl {
    width: 72px;
    height: 72px;
    font-size: 72px
}
.icon--delivery-so.icon--sm {
    width: 24px;
    height: 24px;
    font-size: 24px
}

.icon--cancel:before {
    content: "\e900"
}
.icon--chevron-down:before {
    content: "\e901"
}
.icon--clock:before {
    content: "\e902"
}
.icon--confirm-thin:before {
    content: "\e903"
}
.icon--dashboard:before {
    content: "\e904"
}
.icon--delivery-so:before {
    content: "\e905"
}
.icon--delivery-thin:before {
    content: "\e906"
}
.icon--delivery:before {
    content: "\e907"
}
.icon--document:before {
    content: "\e908"
}
.icon--download-thin:before {
    content: "\e909"
}
.icon--download:before {
    content: "\e90a"
}
.icon--envelope:before {
    content: "\e90b"
}
.icon--eye:before {
    content: "\e90c"
}
.icon--live-chat:before {
    content: "\e90d"
}
.icon--logout:before {
    content: "\e90e"
}
.icon--magnifier:before {
    content: "\e90f"
}
.icon--man:before {
    content: "\e910"
}
.icon--manage-so:before {
    content: "\e911"
}
.icon--map-marker:before {
    content: "\e912"
}
.icon--minus:before {
    content: "\e913"
}
.icon--money-thin:before {
    content: "\e914"
}
.icon--needle:before {
    content: "\e915"
}
.icon--next-stroke:before {
    content: "\e916"
}
.icon--next:before {
    content: "\e917"
}
.icon--nude-tick:before {
    content: "\e918"
}
.icon--online-orders:before {
    content: "\e919"
}
.icon--online-thin:before {
    content: "\e91a"
}
.icon--order-filled:before {
    content: "\e91b"
}
.icon--padlock:before {
    content: "\e91c"
}
.icon--percentage-screen:before {
    content: "\e91d"
}
.icon--percentage-star:before {
    content: "\e91e"
}
.icon--percentage-thin:before {
    content: "\e91f"
}
.icon--phone-thin:before {
    content: "\e920"
}
.icon--phone:before {
    content: "\e921"
}
.icon--play-filled:before {
    content: "\e922"
}
.icon--plus:before {
    content: "\e923"
}
.icon--previous:before {
    content: "\e924"
}
.icon--printer:before {
    content: "\e925"
}
.icon--products-thin:before {
    content: "\e926"
}
.icon--profile-thin:before {
    content: "\e927"
}
.icon--profile:before {
    content: "\e928"
}
.icon--profit-filled:before {
    content: "\e929"
}
.icon--question:before {
    content: "\e92a"
}
.icon--split:before {
    content: "\e92b"
}
.icon--star:before {
    content: "\e92c"
}
.icon--tick-filled:before {
    content: "\e92d"
}
.icon--tick:before {
    content: "\e92e"
}
.icon--trends:before {
    content: "\e92f"
}
.icon--volume-discount-filled:before {
    content: "\e930"
}
.icon--volume-discount-thin:before {
    content: "\e931"
}
.icon--woman:before {
    content: "\e932"
}
