.chart-title {
	font-size: 13px;
	text-transform: uppercase;
	text-align: center;
	@include media-breakpoint-up(md) {
		font-size: 18px;
	}
	@include media-breakpoint-up(lg) {
		font-size: 24px;
	}
}

.chart-copy {
	font-size: 16px;
}

.chart-desc {
	font-size: 16px;
}

.chart-label {
	font-size: 13px;
	line-height: 18px;
	text-transform: uppercase;
	@include media-breakpoint-up(lg) {
		font-size: 21px;
		line-height: 24px;
	}
}

.chart-perc {
	font-family: arial, sans-serif; font-weight: 700;
	font-size: 26px;
	line-height: 29px;
	text-transform: uppercase;
	@include media-breakpoint-up(lg) {
		font-size: 38px;
		line-height: 42px;
	}
}

.chart-legend {}

.chart-legend-button {
	cursor: pointer;
	white-space: nowrap;
	padding-right: 1.0rem;

	.chart-legend-dot {
		display: inline-block;
		vertical-align: middle;
		width: 12px;
		height: 12px;
		border-radius: 6px;
		margin-right: 5px;
	}

	.chart-legend-dash {
		display: inline-block;
		vertical-align: middle;
		width: 16px;
		height: 12px;
		margin-right: 5px;
		background-image: linear-gradient(to right, $gsk-dark-grey 0%, $gsk-dark-grey 50%, #fff 50%, #fff 100%);
		background-position: center;
		background-size: 8px 2px;
		background-repeat: repeat-x;
	}

	span {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		font-weight: 700;
		color: rgb(51,51,51);
	}

	&.hide {
		color: #ccc !important;
		.chart-legend-dot {
			background-color: #ccc !important;
		}
		.chart-legend-dash {
			background-image: linear-gradient(to right, #CCC 0%, #CCC 50%, #fff 50%, #fff 100%);
		}
		span {
			color: #ccc !important;
		}
	}


}
