.bg-gradient {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg-gradient-1 {
	background-image: url('/assets/img/backgrounds/gradient1.jpg');
}

.bg-gradient-2 {
	background-image: url('/assets/img/backgrounds/gradient2.jpg');
}

.bg-gradient-3 {
	background-image: url('/assets/img/backgrounds/gradient3.jpg');
}

.bg-gradient-4 {
	background-image: url('/assets/img/backgrounds/gradient4.jpg');
}

.bg-gradient-5 {
	background-image: url('/assets/img/backgrounds/gradient5.jpg');
}

.bg-gradient-6 {
	background-image: url('/assets/img/backgrounds/gradient6.jpg');
}

.bg-gradient-orange {
  background: #F36633;
  background: linear-gradient(90deg, rgba(234,95,30,1) 0%, rgba(243,149,23,1) 100%);
}
